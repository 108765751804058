import { FC } from 'react';

import { SERIAL_NUMBER_DETAILS_MOCK } from 'constants/mocks';
import CroppedTooltip from 'components/CroppedTooltip';
import { DeletedDealerBadge } from 'components/Badges';

import SocialLinks from './SocialLinks';
import {
  ACCREDITATION_OPTIONS,
  DEALER_DETAILS_TEXT,
  HEADQUARTERS_OPTIONS,
  RATING_OPTIONS,
  STRING_WITH_VERTICAL,
} from './constants';
import { DealerWrapper, FieldValueWrapper, StyledDealerLogo } from './styled';
import {
  TitleWithLabel,
  ColumnsWrapper,
  ColumnWrapper,
  SectionTitle,
  FieldTitle,
  FieldValue,
  TextWrapper,
} from '../styled';
import { ISerialNumberSection } from '../types';

const DealerDetails: FC<ISerialNumberSection> = ({ serialNumber }) => {
  const dealer = serialNumber.request.details?.dealer;
  const DEALER_WEBSITE = `https://${dealer?.website}`;

  const isDealerDeleted = serialNumber.dealer.isDeleted;

  return (
    <DealerWrapper>
      <TitleWithLabel>
        <SectionTitle>{DEALER_DETAILS_TEXT.title}</SectionTitle>
        {isDealerDeleted && <DeletedDealerBadge />}
      </TitleWithLabel>
      <ColumnsWrapper>
        <ColumnWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.dealer}</FieldTitle>
            <FieldValueWrapper>
              <StyledDealerLogo
                data-html2canvas-ignore
                logo={serialNumber.dealer?.logoFile}
              />
              <CroppedTooltip title={dealer?.companyName}>
                <FieldValue>{dealer?.companyName}</FieldValue>
              </CroppedTooltip>
            </FieldValueWrapper>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.USTreasury}</FieldTitle>
            <CroppedTooltip title={dealer?.finCENLicenseNumber}>
              <FieldValue>{dealer?.finCENLicenseNumber}</FieldValue>
            </CroppedTooltip>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.stateLicence}</FieldTitle>
            <CroppedTooltip title={dealer?.stateLicenseNumber}>
              <CroppedTooltip title={dealer?.stateLicenseNumber}>
                <FieldValue>{dealer?.stateLicenseNumber}</FieldValue>
              </CroppedTooltip>
            </CroppedTooltip>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.companyStatus}</FieldTitle>
            <FieldValue>{SERIAL_NUMBER_DETAILS_MOCK.companyStatus}</FieldValue>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.headquarters}</FieldTitle>
            <FieldValue>
              {HEADQUARTERS_OPTIONS[dealer?.headquaters as number]}
            </FieldValue>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.yearsInBusiness}</FieldTitle>
            <FieldValue>{dealer?.yearsInBusiness}</FieldValue>
          </TextWrapper>
        </ColumnWrapper>
        <ColumnWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.website}</FieldTitle>
            <CroppedTooltip title={dealer?.website}>
              <FieldValue>
                <a href={DEALER_WEBSITE} target='blank'>
                  {dealer?.website}
                </a>
              </FieldValue>
            </CroppedTooltip>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.phone}</FieldTitle>
            <CroppedTooltip title={dealer?.phoneNumber}>
              <FieldValue>{dealer?.phoneNumber}</FieldValue>
            </CroppedTooltip>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.email}</FieldTitle>
            <CroppedTooltip title={dealer?.email}>
              <FieldValue>{dealer?.email}</FieldValue>
            </CroppedTooltip>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.social}</FieldTitle>
            <SocialLinks dealer={dealer} />
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.betterBusinessBureau}</FieldTitle>
            <FieldValue>
              {RATING_OPTIONS[dealer?.rating as number]}&nbsp;
              {STRING_WITH_VERTICAL}
              {ACCREDITATION_OPTIONS[dealer?.accreditation as number]}
            </FieldValue>
          </TextWrapper>
          <TextWrapper>
            <FieldTitle>{DEALER_DETAILS_TEXT.address}</FieldTitle>
            <CroppedTooltip title={dealer?.address}>
              <FieldValue>{dealer?.address}</FieldValue>
            </CroppedTooltip>
          </TextWrapper>
        </ColumnWrapper>
      </ColumnsWrapper>
    </DealerWrapper>
  );
};

export default DealerDetails;
