import { FC } from 'react';

import { Loader } from 'components/Loader';
import { INITIAL_TIMER_VALUE } from 'constants/common';

import { RESEND_CODE_TEXT } from './constants';
import {
  StyledButton,
  StyledDescription,
  StyledWrapper,
  ResendNotAvailable,
} from './styled';
import { IResendCodeProps } from './types';

const ResendCode: FC<IResendCodeProps> = ({
  timer,
  isSendVerificationCodeLoading,
  handleResendCode,
}) => (
  <StyledWrapper>
    {isSendVerificationCodeLoading ? (
      <Loader />
    ) : timer !== INITIAL_TIMER_VALUE ? (
      <ResendNotAvailable>
        {RESEND_CODE_TEXT.resendButtonDescription}
      </ResendNotAvailable>
    ) : (
      <StyledDescription>
        {RESEND_CODE_TEXT.notReceiveEmail}&nbsp;
        <StyledButton type='link' onClick={handleResendCode}>
          {RESEND_CODE_TEXT.resendCodeLink}
        </StyledButton>
      </StyledDescription>
    )}
  </StyledWrapper>
);

export default ResendCode;
