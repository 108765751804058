import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { ShareIcon, SaveIcon } from 'assets/vectors';

import { THeaderType } from './types';
import { Section } from '../styled';

export const HeaderCard = styled(
  ({ isRestricted: _isRestricted, children, ...props }) => (
    <Section {...props}>{children}</Section>
  ),
)<THeaderType>(({ isRestricted }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto 40px',
  padding: '16px 20px 16px 60px',
  borderRadius: '4px',
  border: `1px solid ${isRestricted ? COLORS.red200 : COLORS.gray300}`,
  ...(isRestricted && { backgroundColor: COLORS.red50 }),
  boxShadow:
    '0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05)',
  '@media (max-width: 1024px)': {
    marginBottom: '16px',
    padding: '16px 20px',
    flexWrap: 'wrap',
  },
  '@media (max-width: 768px)': {
    padding: '8px 12px',
  },
}));

export const SerialNumberWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '300px',
  '@media (max-width: 1024px)': {
    display: 'block',
    width: '100%',
    marginBottom: '12px',
  },
});

export const SerialNumberText = styled('span')({
  fontWeight: 600,
  fontSize: '18px',
  color: COLORS.gray800,
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
});

export const SerialNumber = styled(SerialNumberText)({
  marginRight: '24px',
});

export const BadgeWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '24px',
  '@media (max-width: 768px)': {
    columnGap: '8px',
  },
});

export const ButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'calc(100% - 300px)',
  '@media (max-width: 1024px)': {
    width: '100%',
  },
});

export const StyledShareIcon = styled(ShareIcon)({});
export const StyledSaveIcon = styled(SaveIcon)({});

export const SaveAndShareWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});
