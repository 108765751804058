import {
  DashboardIcon,
  HoldingsIcon,
  SubscriptionsIcon,
} from 'assets/vectors/menu';
import { TMenuConfig, TMenuItem, TMenuItemsList } from 'types/menu';

import { APP_ROUTE } from './appRoutes';

export const EXPANDED_MENU_WIDTH = 245;
export const COLLAPSED_MENU_WIDTH = 80;
export const HEADER_HEIGHT = 80;
export const USER_AVATAR_SIZE = 32;

export const MENU_TITLE = {
  logo: 'Dinar Verify',
  dashboard: 'Dashboard',
  subscriptions: 'Subscriptions',
  profile: 'Your Profile',
  holdings: 'Holdings',
  currencyDetails: 'Currency Details',
  notFound: 'Not Found',
  noInternet: 'No Internet',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  signUpVerifySN: 'Sign Up Verify Serial Number',
  signUpVerificationFailed: 'Sign Up Verification Failed',
  signUpVerificationCompleted: 'Sign Up Verification Completed',
  signUpVerification: 'Sign Up Verification',
  signUpUserEmail: 'Sign Up User Email',
  signUpUserVerificationCode: 'Sign Up User Verification Code',
  signUpUserCreatePassword: 'Sign Up User Create Password',
  signUpUserFullName: 'Sign Up User Full Name',
  signUpUserPhoneNumber: 'Sign Up User Phone Number',
  signUpUserAddress: 'Sign Up User Address',
  signOut: 'Sign Out',
  forgotPassword: 'Forgot Password',
  resetPassword: 'Reset Password',
};

const MENU_ITEMS_LIST: TMenuItemsList = {
  [MENU_TITLE.dashboard]: {
    menuTitle: MENU_TITLE.dashboard,
    pathTo: APP_ROUTE.dashboard,
    icon: DashboardIcon,
  },
  [MENU_TITLE.subscriptions]: {
    menuTitle: MENU_TITLE.subscriptions,
    pathTo: APP_ROUTE.subscriptions,
    icon: SubscriptionsIcon,
  },
  [MENU_TITLE.holdings]: {
    menuTitle: MENU_TITLE.holdings,
    pathTo: APP_ROUTE.serialNumbers,
    icon: HoldingsIcon,
  },
};

export const MENU_CONFIG: TMenuConfig = {
  development: {
    customer: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.holdings],
    ] as TMenuItem[],
  },
  test: {
    customer: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.holdings],
    ] as TMenuItem[],
  },
  staging: {
    customer: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.holdings],
    ] as TMenuItem[],
  },
};
