import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

import Button from 'components/Button';
import { Link } from 'components/Link';
import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';

export const StyledForm = styled('form')({
  width: '100%',
});

export const FieldWrapper = styled('div')({
  marginBottom: '32px',
});

export const Checkbox = styled(AntdCheckbox)({
  fontWeight: 500,
  color: COLORS.gray900,
});

export const HelpLink = styled(Link)({
  display: 'block',
  width: 'fit-content',
  margin: '0 auto 20px',
  fontSize: '14px',
});

export const SignInButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
});

export const StyledAlert = styled(CustomAlert)({
  margin: '32px 0 40px',
});

export const StyledText = styled('p')({
  width: '100%',
  marginBottom: 0,
  fontSize: '16px',
  color: COLORS.gray900,
  textAlign: 'center',
  '@media (max-width: 768px)': {
    marginTop: '40px',
  },
});

export const StyledLink = styled(Link)({
  fontSize: '16px',
});
