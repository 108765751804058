import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

export const StyledDescription = styled('p')({
  width: '100%',
  color: COLORS.gray900,
  textAlign: 'center',
  margin: 0,
});

export const StyledWrapper = styled('p')({
  marginBottom: '12px',
});

export const StyledButton = styled(Button)({
  height: 'inherit',
  padding: 0,
});

export const ResendNotAvailable = styled('p')({
  fontSize: '18px',
  color: COLORS.gray600,
  textAlign: 'center',
  margin: 0,
});
