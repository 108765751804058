import html2canvas from 'html2canvas';

import { CANVAS_TRANSFORM_CONFIG } from './config';
import { configureStyles } from './dom';

export const transformToCanvas = async (
  element: Element | null,
): Promise<HTMLCanvasElement> => {
  const transformConfig = {
    ...CANVAS_TRANSFORM_CONFIG,
    onclone: configureStyles,
  };
  return html2canvas(element as HTMLElement, transformConfig);
};
