import { useCallback, useState } from 'react';

import { TUseToggleReturn } from './types';

export const useToggle = (initialState = false): TUseToggleReturn => {
  const [state, setState] = useState<boolean>(() => initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};
