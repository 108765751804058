export enum ProgressBarType {
  default = 'default',
  warning = 'warning',
}

export enum ProgressBarColor {
  blue = 'blue',
  orange = 'orange',
}

export interface IProgressBarConfig {
  progress: number;
  type?: ProgressBarType;
  text?: string;
}

export interface IProgressBarLayoutProps extends IProgressBarConfig {
  children: React.ReactNode;
}
