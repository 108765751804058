import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';
import { ICON_FONT_SIZE } from 'components/InformPopover/constants';
import { useGetSerialNumberStatus } from 'api/serialNumbers';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { Loader } from 'components/Loader';
import InformPopover from 'components/InformPopover';
import { renderSNCurrencyLockedError } from 'utils/serialNumbers';
import { ISerialNumberStatus } from 'types/serialNumber';

import { CURRENCY_DETAILS_TEXT, CURRENCIES_INFORM_CONTENT } from './constants';
import CurrencyTable from './CurrencyTable';
import { PageWrapper, CurrenciesTitleWrapper, CurrenciesTitle } from './styled';
import { TCurrencyDetailsLocation, TCurrencyDetailsParams } from './types';

const CurrencyDetails: FC = () => {
  const { serialNumberId } = useParams() as TCurrencyDetailsParams;
  const { state } = useLocation() as TCurrencyDetailsLocation;
  const requestId = state?.requestId;

  const navigateTo = useNavigate();

  const onSerialNumberStatusSuccess = (status: ISerialNumberStatus) => {
    if (status?.isLocked || !requestId) {
      navigateTo(APP_ROUTE.serialNumbers);
      status?.isLocked && renderSNCurrencyLockedError();
    }
  };

  const { isLoading: isSerialNumberStatusLoading } = useGetSerialNumberStatus(
    serialNumberId,
    onSerialNumberStatusSuccess,
  );

  return isSerialNumberStatusLoading ? (
    <Loader />
  ) : (
    <>
      {requestId && (
        <HelmetPageTitle
          title={CURRENCY_DETAILS_TEXT.pageTitle}
          headerTitle={CURRENCY_DETAILS_TEXT.headerTitle}
          backLinkPath={APP_ROUTE.serialNumbers}
        >
          <PageWrapper>
            <CurrenciesTitleWrapper>
              <CurrenciesTitle>
                {CURRENCY_DETAILS_TEXT.pageTitle}
              </CurrenciesTitle>
              <InformPopover
                iconSize={ICON_FONT_SIZE.large}
                informContent={CURRENCIES_INFORM_CONTENT}
              />
            </CurrenciesTitleWrapper>
            <CurrencyTable requestId={requestId} />
          </PageWrapper>
        </HelmetPageTitle>
      )}
    </>
  );
};

export default CurrencyDetails;
