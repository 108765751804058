import { FC } from 'react';

import {
  StyledHeader,
  StyledQuestionIcon,
  StyledText,
  TextWrapper,
  StyledLogo,
  StyledInnerHeader,
} from './styled';
import { UNAUTHORIZED_LAYOUT_TEXT } from '../constants';

const Header: FC<{ isDesktopVersion?: boolean }> = ({
  isDesktopVersion = true,
}) => (
  <StyledHeader isDesktopVersion={isDesktopVersion}>
    <StyledInnerHeader>
      <StyledLogo isDesktopVersion={isDesktopVersion} />
      <TextWrapper>
        <StyledQuestionIcon />
        <StyledText>{UNAUTHORIZED_LAYOUT_TEXT.HELP}</StyledText>
      </TextWrapper>
    </StyledInnerHeader>
  </StyledHeader>
);

export default Header;
