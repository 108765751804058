export const CONFIRM_NAVIGATE_TEXT = {
  title: 'Are you sure you want to leave this page?',
  content: 'All unsaved changes will be lost.',
  confirm: 'Leave',
  cancel: 'Cancel',
};

export const CONFIRM_NAVIGATE_MODAL_PROPS = {
  width: '420px',
  height: '190px',
};
