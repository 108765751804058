import { FC } from 'react';
import { isEmpty } from 'lodash';

import { ALERT_TYPE } from 'components/Alert';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { DefaultInput, MAX_EMAIL_LENGTH } from 'components/Inputs';
import UnauthorizedPageWrapper from 'components/Layouts/UnauthorizedLayout/UnauthorizedPageWrapper';
import { PageSubtitle, PageTitle } from 'components/UnauthorizedPages';
import { APP_ROUTE } from 'constants/appRoutes';
import { INITIAL_TIMER_VALUE } from 'constants/common';
import { MENU_TITLE } from 'constants/menu';
import { ALERT_SUCCESS_MESSAGE, SERVER_ERROR_STRING } from 'constants/messages';
import { UNAUTHORIZED_LAYOUT_TEXT } from 'components/Layouts/UnauthorizedLayout/constants';

import { FORGOT_PASSWORD_TEXT } from './constants';
import { ForgotPasswordField, IForgotPasswordProps } from './types';
import {
  StyledFormWrapper,
  StyledForm,
  ResendButton,
  ResendButtonWrapper,
  ResendTextWrapper,
  SendLinkButton,
  StyledAlert,
  StyledText,
  StyledBackToLink,
  StyledLink,
  StyledTextBig,
  ResendDescription,
  ResendWrapper,
  StyledLogo,
} from './styled';

const ForgotPassword: FC<IForgotPasswordProps> = ({
  formik,
  timer,
  errorMessage,
  isFormVisible,
  isSendLinkLoading,
  onBackLinkClick,
  onResendButtonClick,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isResetLinkErrorMessage =
    errorMessage && errorMessage.includes(SERVER_ERROR_STRING);
  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <HelmetPageTitle title={MENU_TITLE.forgotPassword}>
      <UnauthorizedPageWrapper>
        {isFormVisible ? (
          <StyledFormWrapper>
            <StyledLogo />
            <StyledBackToLink to={APP_ROUTE.signIn}>
              {FORGOT_PASSWORD_TEXT.linkToSignInText}
            </StyledBackToLink>
            <PageTitle>{FORGOT_PASSWORD_TEXT.forgotPageTitle}</PageTitle>
            <PageSubtitle>
              {FORGOT_PASSWORD_TEXT.forgotPageSubTitle}
            </PageSubtitle>
            {isErrorAlertShown && !isResetLinkErrorMessage && (
              <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
            )}
            <StyledForm onSubmit={handleSubmit}>
              <DefaultInput
                labelText={FORGOT_PASSWORD_TEXT.inputLabel}
                errorMessage={errors[ForgotPasswordField.email]}
                maxLength={MAX_EMAIL_LENGTH}
                {...getFieldProps(ForgotPasswordField.email)}
              />
              <SendLinkButton
                htmlType='submit'
                size='large'
                loading={isSendLinkLoading}
              >
                {FORGOT_PASSWORD_TEXT.sendLinkButtonText}
              </SendLinkButton>
            </StyledForm>
          </StyledFormWrapper>
        ) : (
          <ResendWrapper>
            <StyledBackToLink
              to={APP_ROUTE.forgotPassword}
              onClick={onBackLinkClick}
            >
              {FORGOT_PASSWORD_TEXT.linkToForgotText}
            </StyledBackToLink>
            <PageTitle>{FORGOT_PASSWORD_TEXT.verifyPageTitle}</PageTitle>
            <StyledAlert
              type={
                isResetLinkErrorMessage ? ALERT_TYPE.ERROR : ALERT_TYPE.SUCCESS
              }
              message={
                isResetLinkErrorMessage
                  ? errorMessage
                  : ALERT_SUCCESS_MESSAGE.successResetLinkMessage(
                      values[ForgotPasswordField.email],
                    )
              }
            />
            {timer !== INITIAL_TIMER_VALUE ? (
              <ResendTextWrapper>
                <ResendDescription>
                  {FORGOT_PASSWORD_TEXT.resendButtonDescription}
                </ResendDescription>
              </ResendTextWrapper>
            ) : (
              <ResendButtonWrapper>
                <StyledTextBig>
                  {FORGOT_PASSWORD_TEXT.didntReceiveEmail}
                </StyledTextBig>
                <ResendButton
                  type='default'
                  htmlType='submit'
                  loading={isSendLinkLoading}
                  onClick={onResendButtonClick}
                >
                  {FORGOT_PASSWORD_TEXT.resendButtonText}
                </ResendButton>
              </ResendButtonWrapper>
            )}
          </ResendWrapper>
        )}
        <StyledText>
          {UNAUTHORIZED_LAYOUT_TEXT.DONT_HAVE_ACCOUNT}&nbsp;
          <StyledLink to={APP_ROUTE.signUp}>
            {UNAUTHORIZED_LAYOUT_TEXT.SIGN_UP}
          </StyledLink>
        </StyledText>
      </UnauthorizedPageWrapper>
    </HelmetPageTitle>
  );
};

export default ForgotPassword;
