import styled from 'styled-components';

import Button from 'components/Button';
import CustomAlert from 'components/CustomAlert';

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '100%'
});

export const StyledButton = styled(Button)({
  width: '100%',
  marginTop: '12px',
  margin: '40px 0 0',
  '@media (max-width: 768px)': {
    margin: '40px 0',
  },
});

export const StyledCustomAlert = styled(CustomAlert)({
  marginBottom: '34px',
});
