import { FC } from 'react';

import { SERIAL_NUMBER_DETAILS_MOCK } from 'constants/mocks';
import CroppedTooltip from 'components/CroppedTooltip';
import { formatNumberWithSeparator } from 'utils/format';

import { ISerialNumberSection } from '../types';
import { CURRENCY_OPTIONS, SERIAL_NUMBER_CARDS_TITLE } from './constants';
import {
  CardData,
  CardTitle,
  SerialNumberCard,
  SerialNumberCardWrapper,
} from './styled';

const SerialNumberCards: FC<ISerialNumberSection> = ({ serialNumber }) => {
  const snCurrency =
    (serialNumber && CURRENCY_OPTIONS[serialNumber.currency]) ||
    SERIAL_NUMBER_DETAILS_MOCK.currency;

  return (
    <SerialNumberCardWrapper>
      <SerialNumberCard>
        <CroppedTooltip title={snCurrency}>
          <CardData>{snCurrency}</CardData>
        </CroppedTooltip>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.currency}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>{serialNumber.request.currency}</CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.currencyCode}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>
          {formatNumberWithSeparator(serialNumber.request.amount)}
        </CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.amount}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>{serialNumber.request.count}</CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.numberOfNotes}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>
          {formatNumberWithSeparator(serialNumber.request.denom)}
        </CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.note}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>{serialNumber.request.details?.condition}</CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.condition}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>{serialNumber?.request?.details?.series}</CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.series}</CardTitle>
      </SerialNumberCard>
      <SerialNumberCard>
        <CardData>{SERIAL_NUMBER_DETAILS_MOCK.identity}</CardData>
        <CardTitle>{SERIAL_NUMBER_CARDS_TITLE.authenticity}</CardTitle>
      </SerialNumberCard>
    </SerialNumberCardWrapper>
  );
};

export default SerialNumberCards;
