import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { STATUS_CODES } from 'constants/errorCodes';
import { MESSAGES } from 'constants/messages';
import { useTriggerNotFound } from 'hooks';
import { transformRoute } from 'utils/routing';
import { TAxiosRequestError } from 'api/types';

import {
  TGetCurrenciesForRequestParams,
  TGetCurrenciesForRequestResponse,
} from './types';

const useGetCurrenciesForRequest = (
  requestId: string,
  params: TGetCurrenciesForRequestParams,
): UseQueryResult<TGetCurrenciesForRequestResponse, AxiosError> => {
  const triggerNotFound = useTriggerNotFound();

  const handleGetCurrenciesDetailsError = (error: TAxiosRequestError) => {
    if (error?.code !== STATUS_CODES.ERR_NETWORK_CODE) {
      triggerNotFound(MESSAGES.serialNumberDeletedOrNotExist);
    }
  };

  return useQuery(
    [QUERY_KEYS.CURRENCIES_FOR_REQUEST, params],
    async () => {
      const { data: currenciesForRequestResponse } = await apiClient.get(
        transformRoute(ApiRoutes.CURRENCIES_FOR_REQUEST, requestId),
        { params },
      );

      return currenciesForRequestResponse;
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      onError: handleGetCurrenciesDetailsError,
    },
  );
};

export default useGetCurrenciesForRequest;
