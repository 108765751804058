import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const StyledSubTitle = styled('h2')({
  marginBottom: '36px',
  fontSize: '20px',
  lineHeight: '28px',
  color: COLORS.gray600,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  '@media (max-width: 768px)': {
    textAlign: 'inherit',
  },
});
