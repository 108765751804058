import { FC } from 'react';

import { PageSubtitle, PageTitle } from 'components/UnauthorizedPages';

import { StyledTitleWrapper } from '../styled';
import {
  VerificationStepWrapper,
  VerificationStepContent,
  VerificationStepWarning,
} from './styled';
import { VERIFICATION_BLOCKS, VERIFICATION_STEP_TEXT } from './constants';
import { IVerificationStepProps } from './types';
import VerificationBlock from './VerificationBlock';

const VerificationStep: FC<IVerificationStepProps> = ({ serialNumber }) => (
  <VerificationStepWrapper>
    <StyledTitleWrapper>
      <PageTitle>{VERIFICATION_STEP_TEXT.title}</PageTitle>
      <PageSubtitle>{VERIFICATION_STEP_TEXT.subtitle}</PageSubtitle>
    </StyledTitleWrapper>
    <VerificationStepContent>
      {VERIFICATION_BLOCKS.map((block) => (
        <VerificationBlock
          key={block.index}
          block={block}
          serialNumber={serialNumber}
        />
      ))}
    </VerificationStepContent>
    <VerificationStepWarning>
      {VERIFICATION_STEP_TEXT.warning}
    </VerificationStepWarning>
  </VerificationStepWrapper>
);

export default VerificationStep;
