export const TOOLTIP = {
  facebook: 'Facebook',
  twitter: 'Twitter',
  youtube: 'YouTube',
  instagram: 'Instagram',
};

export const URL = {
  facebook: 'https://www.facebook.com/',
  twitter: 'https://twitter.com/',
  youtube: 'https://www.youtube.com/',
  instagram: 'https://www.instagram.com/',
};
