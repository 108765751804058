import { TColorProp } from 'types/common';
import { COLORS } from 'constants/colorPalette';

import { ProgressBarColor, ProgressBarType } from './types';

export const getProgressColorName = (
  type: ProgressBarType,
): ProgressBarColor => {
  switch (type) {
    case ProgressBarType.default:
      return ProgressBarColor.blue;

    case ProgressBarType.warning:
      return ProgressBarColor.orange;

    default:
      return ProgressBarColor.blue;
  }
};

export const getGradient = (colorName: ProgressBarColor): string => {
  const color_0 = COLORS[`${colorName}300` as TColorProp];
  const color_50 = COLORS[`${colorName}500` as TColorProp];
  const color_100 = COLORS[`${colorName}600` as TColorProp];

  return `linear-gradient(90deg, ${color_0} 0%, ${color_50} 50%, ${color_100} 100%);`;
};
