import { FC } from 'react';

import { NoData } from 'assets/vectors';

import { NotFoundWrapper, StyledIcon, Text } from './styled';
import { INoDataMessage } from './types';

const NoDataMessage: FC<INoDataMessage> = ({ message, children }) => (
  <NotFoundWrapper>
    <StyledIcon component={NoData} />
    <Text>{message}</Text>
    {children}
  </NotFoundWrapper>
);

export default NoDataMessage;
