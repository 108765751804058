export const TABLE_HEAD_TITLES = {
  ORDER_NUMBER: '#',
  STATUS: 'Status',
  CURRENCY: 'Currency',
  NOTE: 'Note',
  SERIES: 'Series',
  CONDITION: 'Condition',
  SN: 'Serial #',
  CONVERTED_SN: 'Converted',
  BANKNOTES: 'Front&Back',
  ULTRAVIOLET: 'Ultraviolet',
  WATERMARK: 'Watermark',
  CBI: 'CB',
};

export const REQUEST_FIELDS = {
  CURRENCY: 'currency',
  CONDITION: 'condition',
  NOTE: 'denom',
  SERIES: 'series',
  SN: 'banknoteSN',
  CONVERTED_SN: 'convertedBanknoteSN',
};

export const CBI_TOOLTIP = 'Central Bank Verification';

export const DEFAULT_CURRENCIES_REQUEST_PER_PAGE = 9;
export const DEFAULT_CURRENCIES_REQUEST_PAGE = 1;
export const X_CONTENT_SCROLL = 'max-content';

export const NO_DATA_MESSAGE = 'No Currency Details Found';
