import { TableColumnsType } from 'antd';

import {
  renderMockBanknotes,
  renderCBI,
  renderPossibleUndefined,
  renderPossibleArabicSN,
  renderCurrencyStatus,
  renderCurrencyCondition,
  renderCurrencySeries,
  renderNumberWithThousandsCommas,
} from 'utils/renders';
import { ICurrenciesTable } from 'types/currencies';
import { renderCurrency } from 'utils/tables/renderCurrency';
import Tooltip from 'components/Tooltip';

import {
  DEFAULT_CURRENCIES_REQUEST_PAGE,
  TABLE_HEAD_TITLES,
  REQUEST_FIELDS,
  X_CONTENT_SCROLL,
  CBI_TOOLTIP,
} from './constants';
import { renderOrderNumber } from './renders';

export const getColumnsConfig = (
  page: number = DEFAULT_CURRENCIES_REQUEST_PAGE,
): TableColumnsType<ICurrenciesTable> => [
  {
    title: TABLE_HEAD_TITLES.ORDER_NUMBER,
    width: 60,
    render: renderOrderNumber(page),
  },
  {
    title: TABLE_HEAD_TITLES.STATUS,
    render: renderCurrencyStatus,
    width: 118,
  },
  {
    title: TABLE_HEAD_TITLES.CURRENCY,
    dataIndex: REQUEST_FIELDS.CURRENCY,
    render: renderCurrency,
    width: 124,
  },
  {
    title: TABLE_HEAD_TITLES.NOTE,
    dataIndex: REQUEST_FIELDS.NOTE,
    render: renderNumberWithThousandsCommas,
    width: 108,
  },
  {
    title: TABLE_HEAD_TITLES.SERIES,
    dataIndex: REQUEST_FIELDS.SERIES,
    render: renderCurrencySeries,
    width: 100,
  },
  {
    title: TABLE_HEAD_TITLES.CONDITION,
    dataIndex: REQUEST_FIELDS.CONDITION,
    render: renderCurrencyCondition,
    width: 136,
  },
  {
    title: TABLE_HEAD_TITLES.SN,
    dataIndex: REQUEST_FIELDS.SN,
    render: renderPossibleArabicSN,
    width: 140,
  },
  {
    title: TABLE_HEAD_TITLES.CONVERTED_SN,
    dataIndex: REQUEST_FIELDS.CONVERTED_SN,
    render: renderPossibleUndefined,
    width: 136,
  },
  {
    title: TABLE_HEAD_TITLES.BANKNOTES,
    render: renderMockBanknotes,
    width: 144,
  },
  {
    title: TABLE_HEAD_TITLES.ULTRAVIOLET,
    render: renderMockBanknotes,
    width: 140,
  },
  {
    title: TABLE_HEAD_TITLES.WATERMARK,
    render: renderMockBanknotes,
    width: 140,
  },
  {
    title: <Tooltip title={CBI_TOOLTIP}>{TABLE_HEAD_TITLES.CBI}</Tooltip>,
    render: renderCBI,
    width: 72,
  },
];

export const TABLE_SCROLL_CONFIG = { x: X_CONTENT_SCROLL };
