import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationIcon } from 'assets/vectors';
import { INotification } from 'types/notification';

import {
  NotificationsHeader,
  SectionTitle,
  NotificationsWrapper,
  BadgeWrapper,
  NotificationsInfo,
  ActionsWrapper,
  SwitchWrapper,
  HeaderTitleWrapper,
  MarkButton,
  NotificationGroupLabel,
  SubHeaderWrapper,
} from './styled';
import { BADGE_OVERFLOW_COUNT, NOTIFICATIONS_TEXT } from './constants';
import { INotificationsProps } from './types';
import { NoNotificationsMessage } from './components/NoNotificationsMessage';
import { Notification } from './components/Notification';

const Notifications: FC<INotificationsProps> = ({
  config,
  todayNotifications,
  olderNotifications,
  unreadAmount,
  totalAmount,
  isNotificationBarVisible,
  isNotificationsEmpty,
  isUnreadShown,
  hasMore,
  onHandleChangeVisibility,
  handleChangeQuery,
  handleClickPagination,
  handleReadNotification,
  handleReadOne,
  boxRef,
  notificationIconRef,
}) => (
  <NotificationsWrapper>
    <BadgeWrapper
      notificationIconRef={notificationIconRef}
      count={unreadAmount}
      overflowCount={BADGE_OVERFLOW_COUNT}
      onClick={onHandleChangeVisibility}
    >
      <NotificationIcon />
    </BadgeWrapper>
    {isNotificationBarVisible && (
      <InfiniteScroll
        dataLength={config.length}
        next={handleClickPagination}
        hasMore={hasMore}
        loader={<></>}
        scrollableTarget='scrollable'
      >
        <NotificationsInfo
          isVisible={isNotificationBarVisible}
          id='scrollable'
          boxRef={boxRef}
        >
          <NotificationsHeader>
            <HeaderTitleWrapper>
              <SectionTitle>{NOTIFICATIONS_TEXT.TITLE}</SectionTitle>
              <ActionsWrapper>
                {NOTIFICATIONS_TEXT.SHOW_UNREAD}
                <SwitchWrapper
                  checked={isUnreadShown}
                  onChange={handleChangeQuery}
                />
              </ActionsWrapper>
            </HeaderTitleWrapper>
            <SubHeaderWrapper>
              {!!todayNotifications?.length && (
                <NotificationGroupLabel>
                  {NOTIFICATIONS_TEXT.TODAY}
                </NotificationGroupLabel>
              )}
              {!!unreadAmount && (
                <MarkButton onClick={handleReadNotification}>
                  {NOTIFICATIONS_TEXT.MARK_AS_READ}
                </MarkButton>
              )}
            </SubHeaderWrapper>
          </NotificationsHeader>
          {!isNotificationsEmpty ? (
            <>
              {!!todayNotifications?.length &&
                todayNotifications?.map((todayNotification: INotification) => (
                  <Notification
                    key={todayNotification._id}
                    notification={todayNotification}
                    handleReadOne={handleReadOne}
                  />
                ))}
              {!!olderNotifications?.length && (
                <>
                  <NotificationGroupLabel>
                    {NOTIFICATIONS_TEXT.OLDER}
                  </NotificationGroupLabel>
                  {olderNotifications?.map(
                    (olderNotification: INotification) => (
                      <Notification
                        key={olderNotification._id}
                        notification={olderNotification}
                        handleReadOne={handleReadOne}
                      />
                    ),
                  )}
                </>
              )}
            </>
          ) : (
            <NoNotificationsMessage
              type={isUnreadShown && totalAmount ? 'no-unread' : 'no-any'}
            />
          )}
        </NotificationsInfo>
      </InfiniteScroll>
    )}
  </NotificationsWrapper>
);

export default Notifications;
