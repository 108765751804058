import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  useGetIsDesktopVersion,
  useGetUserFromQueryContext,
  useToggle,
} from 'hooks';
import { useSignOut } from 'api/auth';
import { showConfirmModal } from 'utils/showConfirmModal';
import { EMPTY_OBJECT } from 'constants/common';
import { SearchParams } from 'types/urlSearchParams';

import AuthorizedLayout from './AuthorizedLayout';
import { SIGN_OUT_CONFIRM_PROPS } from './constants';

const AuthorizedLayoutContainer: FC = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(EMPTY_OBJECT);

  const isSignOutPopConfirmShown = useRef<boolean>(true);

  const currentUser = useGetUserFromQueryContext();
  const { mutate: signOutMutate } = useSignOut();

  const [isMobileMenuOpened, toggleIsMobileMenuOpened] = useToggle(false);
  const [isCollapsed, toggleIsCollapsed] = useToggle(true);

  const userProfile = useGetUserFromQueryContext();

  const isDesktopVersion = useGetIsDesktopVersion();

  const ref = useRef<HTMLDivElement>(null);

  const handleSignOutOpenedTabs = (event: StorageEvent) => {
    if (event.key === 'signOut') {
      signOutMutate();
    }
  };

  const handleSignOut = () => {
    localStorage.setItem('signOut', Date.now().toString());

    signOutMutate();
  };

  useEffect(() => {
    const emailFromLink = searchParams.get(SearchParams.email) as string;

    if (emailFromLink) {
      if (
        isSignOutPopConfirmShown.current &&
        currentUser.email !== emailFromLink
      ) {
        isSignOutPopConfirmShown.current = false;
        showConfirmModal({
          ...SIGN_OUT_CONFIRM_PROPS,
          onOk: () => handleSignOut(),
        });
      }
      setSearchParams(EMPTY_OBJECT);
    }

    window.addEventListener('storage', handleSignOutOpenedTabs);

    return () => {
      window.removeEventListener('storage', handleSignOutOpenedTabs);
    };
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const handleClearHistoryState = () => {
      window.history.replaceState(EMPTY_OBJECT, document.title);
    };

    window.addEventListener('beforeunload', handleClearHistoryState);

    return () =>
      window.removeEventListener('beforeunload', handleClearHistoryState);
  }, []);

  return (
    <AuthorizedLayout
      ref={ref}
      userProfile={userProfile}
      isCollapsed={isCollapsed && isDesktopVersion}
      isMobileMenuOpened={isMobileMenuOpened}
      toggleIsCollapsed={toggleIsCollapsed}
      toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
    />
  );
};

export default AuthorizedLayoutContainer;
