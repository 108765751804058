import styled from 'styled-components';
import { Spin } from 'antd';

import { COLORS } from 'constants/colorPalette';

export const LayoutWrapper = styled('div')({
  height: '100vh',
  backgroundColor: COLORS.white,
});

export const Main = styled(
  ({ children, isDesktopVersion: _isDesktopVersion, ...props }) => (
    <main {...props}>{children}</main>
  ),
)(({ isDesktopVersion }) => ({
  width: '100%',
  height: 'calc(100% - 50px)',
  ...(isDesktopVersion && {
    height: 'calc(100% - 80px)',
  }),
}));

export const PageLoader = styled(Spin)({
  width: '100%',
  textAlign: 'center',
});
