import { FC } from 'react';

import { TButtonProps } from './types';
import { StyledButton } from './styled';

export const Button: FC<TButtonProps> = ({ children, loading, ...props }) => (
  <StyledButton type='primary' size='middle' loading={loading} {...props}>
    {!loading && children}
  </StyledButton>
);

export default Button;
