export const NOTIFICATIONS_PER_PAGE = 5;

export const DEFAULT_PAGE_VALUE = 1;

export const DEFAULT_UNREAD_AMOUNT = 0;

export const BADGE_OVERFLOW_COUNT = 99;

export const TEXT = {
  DELETE_LABEL: 'Delete',
};

export const NOTIFICATIONS_TEXT = {
  TITLE: 'Notifications',
  SHOW_UNREAD: 'Only show unread',
  TODAY: 'Today',
  MARK_AS_READ: ' Mark all as read',
  OLDER: 'Older',
};
