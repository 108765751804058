import { FC } from 'react';

import { NoAvatarWrapper, UserInitials } from './styled';
import { INoAvatarProps } from './types';
import { getUserInitialsFromName } from './utils';

const NoAvatar: FC<INoAvatarProps> = ({ name, avatarSize }) => {
  const initials = getUserInitialsFromName(name);

  return (
    <NoAvatarWrapper avatarSize={avatarSize}>
      <UserInitials avatarSize={avatarSize}>{initials}</UserInitials>
    </NoAvatarWrapper>
  );
};

export default NoAvatar;
