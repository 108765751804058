import { FC } from 'react';
import { Link } from 'react-router-dom';

import { formatDateString, formatNumberAsMoney } from 'utils/format';
import { DASH_STRING, EMPTY_STRING } from 'constants/common';
import CroppedTooltip from 'components/CroppedTooltip';

import CurrencyTable from './CurrencyTable';
import {
  OWNERSHIP_DETAILS_TEXT,
  ORDER_DETAILS_TEXT,
  CURRENCY_DETAILS_TEXT,
} from './constants';
import { IRequestCurrencyDetails } from './types';
import { CurrencyWrapper, InvoiceName } from './styled';
import {
  TitleWithLabel,
  ColumnsWrapper,
  ColumnWrapper,
  FieldTitle,
  FieldValue,
  SectionTitle,
  SectionWrapper,
  ArrowRight,
  StyledButton,
  TextWrapper,
} from '../styled';

const RequestCurrencyDetails: FC<IRequestCurrencyDetails> = ({
  serialNumber,
  invoiceResponse,
  navigateToCurrencyDetails,
}) => {
  const { request } = serialNumber;

  return (
    <>
      <SectionWrapper>
        <TitleWithLabel>
          <SectionTitle>{OWNERSHIP_DETAILS_TEXT.title}</SectionTitle>
        </TitleWithLabel>
        <ColumnsWrapper>
          <ColumnWrapper>
            <TextWrapper>
              <FieldTitle>{OWNERSHIP_DETAILS_TEXT.name}</FieldTitle>
              <CroppedTooltip
                title={`${request.details?.firstName} ${request.details?.lastName}`}
              >
                <FieldValue>
                  {request.details?.firstName} {request.details?.lastName}
                </FieldValue>
              </CroppedTooltip>
            </TextWrapper>
            <TextWrapper>
              <FieldTitle>{OWNERSHIP_DETAILS_TEXT.phone}</FieldTitle>
              <CroppedTooltip title={request.details?.phoneNumber}>
                <FieldValue>
                  {request.details?.phoneNumber || DASH_STRING}
                </FieldValue>
              </CroppedTooltip>
            </TextWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextWrapper>
              <FieldTitle>{OWNERSHIP_DETAILS_TEXT.address}</FieldTitle>
              <CroppedTooltip title={request.details?.address}>
                <FieldValue>{request.details?.address}</FieldValue>
              </CroppedTooltip>
            </TextWrapper>
            <TextWrapper>
              <FieldTitle>{OWNERSHIP_DETAILS_TEXT.email}</FieldTitle>
              <CroppedTooltip title={request.details?.email}>
                <FieldValue>{request.details?.email || DASH_STRING}</FieldValue>
              </CroppedTooltip>
            </TextWrapper>
          </ColumnWrapper>
        </ColumnsWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <TitleWithLabel>
          <SectionTitle>{ORDER_DETAILS_TEXT.title}</SectionTitle>
        </TitleWithLabel>
        <ColumnsWrapper>
          <ColumnWrapper>
            <TextWrapper>
              <FieldTitle>{ORDER_DETAILS_TEXT.dealer}</FieldTitle>
              <CroppedTooltip title={request.details?.dealer?.companyName}>
                <FieldValue>{request.details?.dealer?.companyName}</FieldValue>
              </CroppedTooltip>
            </TextWrapper>
            <TextWrapper>
              <FieldTitle>{ORDER_DETAILS_TEXT.orderNumber}</FieldTitle>
              <CroppedTooltip title={request.details?.orderNumber}>
                <FieldValue>{request.details?.orderNumber}</FieldValue>
              </CroppedTooltip>
            </TextWrapper>
            <TextWrapper>
              <FieldTitle>{ORDER_DETAILS_TEXT.orderDate}</FieldTitle>
              <FieldValue>
                {formatDateString(request.details?.orderDate)}
              </FieldValue>
            </TextWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextWrapper>
              <FieldTitle>{ORDER_DETAILS_TEXT.payment}</FieldTitle>
              <FieldValue>{ORDER_DETAILS_TEXT.paymentValue}</FieldValue>
            </TextWrapper>
            <TextWrapper>
              <FieldTitle>{ORDER_DETAILS_TEXT.price}</FieldTitle>
              <FieldValue>
                {formatNumberAsMoney(request.details?.paidCents)}
              </FieldValue>
            </TextWrapper>
            <TextWrapper data-html2canvas-ignore>
              <FieldTitle>{ORDER_DETAILS_TEXT.invoice}</FieldTitle>
              <FieldValue>
                {invoiceResponse ? (
                  <span data-html2canvas-ignore>
                    <Link
                      to={EMPTY_STRING}
                      onClick={() => window.open(invoiceResponse?.url)}
                    >
                      <InvoiceName title={invoiceResponse?.name}>
                        {invoiceResponse?.name}
                      </InvoiceName>
                    </Link>
                  </span>
                ) : (
                  DASH_STRING
                )}
              </FieldValue>
            </TextWrapper>
          </ColumnWrapper>
        </ColumnsWrapper>
      </SectionWrapper>
      <SectionWrapper data-html2canvas-ignore>
        <CurrencyWrapper>
          <SectionTitle>{CURRENCY_DETAILS_TEXT.title}</SectionTitle>
          <StyledButton
            data-html2canvas-ignore
            type='ghost'
            size='small'
            onClick={navigateToCurrencyDetails}
          >
            {CURRENCY_DETAILS_TEXT.buttonText}
            <ArrowRight />
          </StyledButton>
        </CurrencyWrapper>
        <CurrencyTable request={request} />
      </SectionWrapper>
    </>
  );
};

export default RequestCurrencyDetails;
