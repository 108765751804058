import { FC, useCallback, useState } from 'react';

import { IResponseMessage } from 'constants/messages';
import { useFormik } from 'hooks';

import { IUserFormValues, TUserFormContainerProps } from './types';
import { getUserNextStep, getUserPreviousStep } from './utils';
import { INITIAL_USER_FORM_VALUES } from './constants';
import { USER_VALIDATION_SCHEME } from './validation';
import UserForm from './UserForm';

const UserFormContainer: FC<TUserFormContainerProps> = ({
  currentStep,
  formValues,
  isSignUpLoading,
  handleFormValues,
  handleChangeStep,
}) => {
  const [responseMessage, setResponseMessage] =
    useState<IResponseMessage | null>(null);

  const handleUserFormSubmit = useCallback(
    (values: IUserFormValues) => {
      const nextStep = getUserNextStep(currentStep);
      handleFormValues(values);
      handleChangeStep(nextStep);
    },
    [currentStep],
  );

  const formik = useFormik({
    initialValues: formValues || INITIAL_USER_FORM_VALUES,
    validationSchema: USER_VALIDATION_SCHEME[currentStep],
    enableReinitialize: true,
    onSubmit: handleUserFormSubmit,
  });

  const handleChangeResponseMessage = (message: IResponseMessage | null) =>
    setResponseMessage(message);

  const handleBack = useCallback(() => {
    const previousStep = getUserPreviousStep(currentStep);
    handleChangeStep(previousStep);
    handleChangeResponseMessage(null);
  }, [currentStep]);

  return (
    <UserForm
      formik={formik}
      currentStep={currentStep}
      isSignUpLoading={isSignUpLoading}
      responseMessage={responseMessage}
      handleChangeResponseMessage={handleChangeResponseMessage}
      handleBack={handleBack}
    />
  );
};

export default UserFormContainer;
