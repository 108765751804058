import { FC, memo } from 'react';
import { Popover, Switch } from 'antd';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import {
  EmailCircleIcon,
  MessageCircleIcon,
  QrCodeCircleIcon,
} from 'assets/vectors';
import { Loader } from 'components/Loader';

import {
  ButtonIcon,
  CopyLinkButton,
  CopyLinkWrapper,
  EmbedCodeWrapper,
  ShareModalWrapper,
  SharePdfWrapper,
  StyledEmbedInfoNotice,
  StyledInfoCircleIcon,
} from './styled';
import { SHARE_MODAL_CONTENT } from './constants';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import { SharedSubtitle, SharePdfModal } from '../styled';

const ShareModal: FC = () => {
  const {
    uploadedPdf,
    isPdfUploading,
    pdfWithPassword,
    isShareModalOpen,
    handleCopyPdfLink,
    handleShareModalClose,
    handleShareQRModalOpen,
    handleShareSmsModalOpen,
    handleSetPdfWithPassword,
    handleShareEmailModalOpen,
  } = useSerialNumbersDetailsModalContext();

  return (
    <SharePdfModal
      title={SHARE_MODAL_CONTENT.TITLE}
      isModalOpened={isShareModalOpen}
      handleCloseModal={handleShareModalClose}
    >
      <ShareModalWrapper>
        {isPdfUploading ? (
          <Loader />
        ) : (
          <>
            <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
            <EmbedCodeWrapper>
              <span>
                {SHARE_MODAL_CONTENT.EMBED_CODE}
                <Popover
                  placement='right'
                  content={
                    <StyledEmbedInfoNotice>
                      {SHARE_MODAL_CONTENT.EMBED_CODE_INFO}
                    </StyledEmbedInfoNotice>
                  }
                >
                  <StyledInfoCircleIcon />
                </Popover>
              </span>
              <Switch
                checked={pdfWithPassword}
                onChange={handleSetPdfWithPassword}
              />
            </EmbedCodeWrapper>
            <CopyLinkWrapper>
              <p>{SHARE_MODAL_CONTENT.COPY_LINK_TITLE}</p>
              <div>
                <span>{uploadedPdf?.url}</span>
                <CopyLinkButton
                  type='link'
                  size='small'
                  onClick={() => handleCopyPdfLink()}
                >
                  {SHARE_MODAL_CONTENT.COPY_LINK_BUTTON}
                </CopyLinkButton>
              </div>
            </CopyLinkWrapper>
            <SharePdfWrapper>
              <p>{SHARE_MODAL_CONTENT.SHARE_PDF}</p>
              <div>
                <ButtonIcon
                  id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                  onClick={handleShareSmsModalOpen}
                >
                  <MessageCircleIcon />
                  {SHARE_MODAL_CONTENT.SHARE_BY_SMS}
                </ButtonIcon>
                <ButtonIcon
                  id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                  onClick={handleShareEmailModalOpen}
                >
                  <EmailCircleIcon />
                  {SHARE_MODAL_CONTENT.SHARE_BY_EMAIL}
                </ButtonIcon>
                <ButtonIcon
                  id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                  onClick={handleShareQRModalOpen}
                >
                  <QrCodeCircleIcon />
                  {SHARE_MODAL_CONTENT.SHARE_BY_QR}
                </ButtonIcon>
              </div>
            </SharePdfWrapper>
          </>
        )}
      </ShareModalWrapper>
    </SharePdfModal>
  );
};

export default memo(ShareModal);
