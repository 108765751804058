import { FC } from 'react';

import { useGetIsDesktopVersion } from 'hooks';

import UnauthorizedLayout from './UnauthorizedLayout';

const UnauthorizedLayoutContainer: FC = () => {
  const isDesktopVersion = useGetIsDesktopVersion();

  return <UnauthorizedLayout isDesktopVersion={isDesktopVersion} />;
};

export default UnauthorizedLayoutContainer;
