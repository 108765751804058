import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput, MAX_DEFAULT_LENGTH } from 'components/Inputs';
import { FormFieldWrapper, StyledButton } from 'pages/SignUp/styled';

import { UserFormField } from '../types';
import { USER_FORM_TEXT } from '../constants';
import { IAddressFieldsProps } from './types';

const AddressFields: FC<IAddressFieldsProps> = ({
  formik: { errors, getFieldProps },
  isSignUpLoading,
}) => (
  <>
    <FormFieldWrapper>
      <DefaultInput
        labelText={USER_FORM_TEXT.addressLabel}
        errorMessage={errors[UserFormField.ADDRESS]}
        maxLength={MAX_DEFAULT_LENGTH}
        {...getFieldProps(UserFormField.ADDRESS)}
      />
    </FormFieldWrapper>
    <StyledButton
      htmlType='submit'
      id={SIGN_UP_ACTIONS_BUTTONS_ID}
      loading={isSignUpLoading}
    >
      {USER_FORM_TEXT.button}
    </StyledButton>
  </>
);

export default AddressFields;
