import { ModalFuncProps as IModalFuncProps } from 'antd';

import { COLORS } from 'constants/colorPalette';
import { Size } from 'types/common';

export const DEFAULT_CONFIRM_OPTIONS = {
  maskClosable: true,
  centered: true,
  cancelButtonProps: {
    size: Size.small,
    style: { color: COLORS.gray700, border: `1px solid ${COLORS.gray400}` },
  },
  okButtonProps: {
    size: Size.small,
  },
} as IModalFuncProps;
