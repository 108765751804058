import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';

import { IRedirectFromBaseRouteProps } from './types';

const RedirectFromBaseRoute: FC<IRedirectFromBaseRouteProps> = ({
  isAuthorized,
}) => {
  const navigatePath = isAuthorized ? APP_ROUTE.dashboard : APP_ROUTE.signIn;

  return <Navigate replace to={navigatePath} />;
};

export default RedirectFromBaseRoute;
