import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const CurrencyWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  padding: '2px 8px',
  gap: '8px',
  background: COLORS.gray50,
  border: `1px solid ${COLORS.gray200}`,
  borderRadius: '12px',
});

export const CurrencyTitle = styled('p')({
  margin: 0,
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  color: COLORS.gray700,
});
