import { object, string } from 'yup';

import {
  EMAIL_PATTERN,
  VALIDATION_TEST_NAME,
  MIN_PHONE_NUMBER_LENGTH,
} from 'constants/validations';
import { MAX_EMAIL_LENGTH } from 'components/Inputs';
import { testRegexNegative } from 'utils/validation/testRegexNegative';
import { formatToNumbersOnly } from 'utils/format/numbers';

import { VALIDATION_ERROR_MESSAGES } from './constants';
import { UserFormField, UserStepperKeys } from './types';

const emailValidationSchema = object().shape({
  [UserFormField.EMAIL]: string()
    .required(VALIDATION_ERROR_MESSAGES.emailRequired)
    .matches(
      EMAIL_PATTERN.GENERAL_STRUCTURE,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .matches(
      EMAIL_PATTERN.LOCAL_PART_GENERAL,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .matches(
      EMAIL_PATTERN.LOCAL_PART_BORDERS,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .test(
      VALIDATION_TEST_NAME.GENERAL_TRAILINGS,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
      testRegexNegative(EMAIL_PATTERN.GENERAL_TRAILINGS),
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_GENERAL,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_BORDERS,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_TOTAL_LENGTH,
      VALIDATION_ERROR_MESSAGES.emailInvalid,
    )
    .max(MAX_EMAIL_LENGTH, VALIDATION_ERROR_MESSAGES.emailInvalid),
});

const verificationCodeValidationSchema = object().shape({
  [UserFormField.VERIFICATION_CODE]: string().trim(),
});

const passwordValidationSchema = object({
  [UserFormField.PASSWORD]: string()
    .required(VALIDATION_ERROR_MESSAGES.createPassword)
    .matches(
      /^[a-zA-Z0-9]*$/,
      VALIDATION_ERROR_MESSAGES.numbersAndLettersAllowedError,
    )
    .min(10, VALIDATION_ERROR_MESSAGES.passwordLengthError)
    .matches(
      /(?=.*?[a-zA-Z])/,
      VALIDATION_ERROR_MESSAGES.oneLatinLetterContainError,
    )
    .matches(/(?=.*?[0-9])/, VALIDATION_ERROR_MESSAGES.oneNumberContainError)
    .matches(
      /(?=.*?[A-Z])/,
      VALIDATION_ERROR_MESSAGES.oneUppercaseLetterContainError,
    )
    .matches(
      /(?=.*?[a-z])/,
      VALIDATION_ERROR_MESSAGES.oneLowerCaseLetterContainError,
    ),
});

const fullNameValidationSchema = object().shape({
  [UserFormField.FIRST_NAME]: string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.firstNameRequired),
  [UserFormField.LAST_NAME]: string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.lastNameRequired),
});

const phoneNumberValidationSchema = object().shape({
  [UserFormField.COUNTRY_CODE]: string()
    .nullable()
    .required(VALIDATION_ERROR_MESSAGES.countryCodeRequired),
  [UserFormField.PHONE_NUMBER]: string()
    .required(VALIDATION_ERROR_MESSAGES.phoneNumberRequired)
    .transform(formatToNumbersOnly)
    .min(MIN_PHONE_NUMBER_LENGTH, VALIDATION_ERROR_MESSAGES.phoneNumberInvalid),
});

const addressValidationSchema = object().shape({
  [UserFormField.ADDRESS]: string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.addressRequired),
});

export const USER_VALIDATION_SCHEME = {
  [UserStepperKeys.email]: emailValidationSchema,
  [UserStepperKeys.verificationCode]: verificationCodeValidationSchema,
  [UserStepperKeys.password]: passwordValidationSchema,
  [UserStepperKeys.fullName]: fullNameValidationSchema,
  [UserStepperKeys.phoneNumber]: phoneNumberValidationSchema,
  [UserStepperKeys.address]: addressValidationSchema,
};
