import { FC } from 'react';

import { useSetHeaderTitle } from 'hooks';

import Header from './Header';
import { IHeaderContainerProps } from './types';

const HeaderContainer: FC<IHeaderContainerProps> = (props) => {
  const { title, subTitle, backLinkPath } = useSetHeaderTitle();

  return (
    <Header
      headerTitle={title}
      headerSubTitle={subTitle}
      backLinkPath={backLinkPath}
      {...props}
    />
  );
};

export default HeaderContainer;
