import {
  ForwardRefRenderFunction,
  forwardRef,
  isValidElement,
  cloneElement,
} from 'react';

import Tooltip from 'components/Tooltip';

import { TCroppedTooltipProps } from './types';

const CroppedTooltip: ForwardRefRenderFunction<
  HTMLElement,
  TCroppedTooltipProps
> = ({ children, title, placement = 'right', isCropped, ...props }, ref) => {
  const reffedChildren = isValidElement(children)
    ? cloneElement(children as JSX.Element, { ref })
    : children;

  return (
    <Tooltip title={isCropped && title} placement={placement} {...props}>
      {reffedChildren}
    </Tooltip>
  );
};

export default forwardRef(CroppedTooltip);
