import { FC } from 'react';
import { Dropdown as AntdDropdown } from 'antd';

import { TDropdownProps } from './types';
import { StyledMenu } from './styled';

export const Dropdown: FC<TDropdownProps> = ({ children, items, ...props }) => (
  <AntdDropdown
    trigger={['click']}
    {...props}
    overlay={<StyledMenu items={items} />}
  >
    {children}
  </AntdDropdown>
);

export default Dropdown;
