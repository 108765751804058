import styled from 'styled-components';

import Button from 'components/Button';

export const SerialNumberWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledButton = styled(Button)({
  alignSelf: 'flex-end',
  minWidth: '76px',
  width: '76px',
  marginTop: '12px',
});
