import { TFormikConfig } from 'types/formik';
import { TPhoneNumberCountryCode } from 'types/phoneNumbers';
import { ISerialNumber } from 'types/serialNumber';

export interface IShareSmsModalContainerProps {
  serialNumber: ISerialNumber;
}

export interface IShareSmsModalProps {
  formik: TFormikConfig<IShareSmsFormValues>;
  isModalOpened: boolean;
  handleCloseModal: () => void;
  handleChangePhoneNumberValue: (string: string) => void;
  handleChangeCountryCodeValue: (value: unknown) => void;
}

export enum ShareSmsFormField {
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
}

export interface IShareSmsFormValues {
  [ShareSmsFormField.PHONE_NUMBER]: string;
  [ShareSmsFormField.COUNTRY_CODE]: TPhoneNumberCountryCode;
}
