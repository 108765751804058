import { FC } from 'react';

import { IProgressBarLayoutProps, ProgressBarType } from './types';
import {
  StyledProgressBarLayout,
  ProgressBar,
  ProgressBarText,
  ProgressBarContentWrapper,
} from './styled';

const ProgressBarLayout: FC<IProgressBarLayoutProps> = ({
  type = ProgressBarType.default,
  text,
  progress,
  children,
}) => (
  <StyledProgressBarLayout>
    <ProgressBar type={type} progress={progress}>
      {text && <ProgressBarText type={type}>{text}</ProgressBarText>}
    </ProgressBar>
    <ProgressBarContentWrapper>{children}</ProgressBarContentWrapper>
  </StyledProgressBarLayout>
);

export default ProgressBarLayout;
