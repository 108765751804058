import styled, { css, keyframes } from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { ANIMATION_VALUES, BLOCK_VALUES } from './constants';

const progressAnimationKeframes = keyframes`
  from {
    right: 100%;
  }
  to {
    right: 0;
  }
`;

const blockAnimationKeyframes = keyframes`
  from {
    opacity: 1;
    border: 1px solid ${COLORS.gray300};
  }
  ${ANIMATION_VALUES.blockCompleteAnimationKeyframe} {
    animation-timing-function: ease-out;
    opacity: 1;
    border: 2px solid ${COLORS.green600};
    transform: translateX(0);
  }
  to {
    opacity: 0;
    border: 2px solid ${COLORS.green600};
    transform: translateX(${ANIMATION_VALUES.translateX}px);
  }
`;

export const VerificationBlockWrapper = styled(
  ({ blockIndex: _blockIndex, children, blockRef, ...props }) => (
    <div {...props} ref={blockRef}>
      {children}
    </div>
  ),
)<{ blockIndex: number }>(({ blockIndex }) => ({
  height: `${BLOCK_VALUES.height}px`,
  width: '100%',
  position: 'absolute',
  top: `${BLOCK_VALUES.offsetHeight * blockIndex}px`,
  transition: `top ${ANIMATION_VALUES.moveYDuration}s`,
}));

const BlockWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '18px 32px',
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '4px',
  backgroundColor: COLORS.white,
  '&:before': {
    content: "''",
    background: `linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(226,226,226,1) 27%, rgba(250,250,250,1) 100%)`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
  },
});

export const AnimatedVerificationBlock = styled(BlockWrapper)<{
  blockIndex: number;
}>`
  animation: ${({ blockIndex }) => css`
    ${blockAnimationKeyframes}
    ${ANIMATION_VALUES.blockAnimationDuration}s 
    steps(1) 
    ${ANIMATION_VALUES.progressDuration * blockIndex}s 
    forwards;
  `};
  &:before {
    animation: ${({ blockIndex }) => css`
      ${progressAnimationKeframes}
      ${ANIMATION_VALUES.progressIterationDuration}s 
      ease-out 
      ${ANIMATION_VALUES.progressIterations +
      ANIMATION_VALUES.progressIterations * blockIndex};
    `};
  }
`;

export const VerificationBlockTitle = styled('p')({
  position: 'relative',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  marginBottom: 0,
  color: COLORS.gray600,
});

export const VerificationBlockFeature = styled('span')<{
  isCompleted: boolean;
}>(({ isCompleted }) => ({
  position: 'relative',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 800,
  color: isCompleted ? COLORS.green600 : COLORS.gray900,
}));

export const VerificationIconWrapper = styled('div')({
  position: 'relative',
  width: '32px',
  height: '32px',
});
