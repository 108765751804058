import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const StyledTitle = styled('h4')({
  maxWidth: '418px',
  marginBottom: 0,
  fontWeight: 800,
  color: COLORS.gray700,
});
