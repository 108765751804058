import { FC } from 'react';
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

import Tooltip from 'components/Tooltip';

import { TOOLTIP, URL } from './constants';
import { SocialLink } from '../styled';
import { ISocialLinks } from './types';

const SocialLinks: FC<ISocialLinks> = ({ dealer }) => {
  const FACEBOOK_LINK = `${URL.facebook}${dealer?.facebook}`;
  const TWITTER_LINK = `${URL.twitter}${dealer?.twitter}`;
  const YOUTUBE_LINK = `${URL.youtube}${dealer?.youtube}`;
  const INSTAGRAM_LINK = `${URL.instagram}${dealer?.instagram}`;

  return (
    <div>
      {dealer?.facebook && (
        <Tooltip title={TOOLTIP.facebook}>
          <SocialLink href={FACEBOOK_LINK} target='_blank' rel='noreferrer'>
            <FacebookOutlined />
          </SocialLink>
        </Tooltip>
      )}
      {dealer?.twitter && (
        <Tooltip title={TOOLTIP.twitter}>
          <SocialLink href={TWITTER_LINK} target='_blank' rel='noreferrer'>
            <TwitterOutlined />
          </SocialLink>
        </Tooltip>
      )}
      {dealer?.youtube && (
        <Tooltip title={TOOLTIP.youtube}>
          <SocialLink href={YOUTUBE_LINK} target='_blank' rel='noreferrer'>
            <YoutubeOutlined />
          </SocialLink>
        </Tooltip>
      )}
      {dealer?.instagram && (
        <Tooltip title={TOOLTIP.instagram}>
          <SocialLink href={INSTAGRAM_LINK} target='_blank' rel='noreferrer'>
            <InstagramOutlined />
          </SocialLink>
        </Tooltip>
      )}
    </div>
  );
};

export default SocialLinks;
