import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';

import { formatModalStepNumber } from './utils';
import {
  LearnMoreModalWrapper,
  LearnMoreModalTitleWrapper,
  LearnMoreModalTitle,
  LearnMoreModalBoldText,
  LearnMoreModalText,
  LearnMoreModalSubtitle,
  LearnMoreModalStepWrapper,
  LearnMoreModalStepNumber,
  LearnMoreModalStepTextWrapper,
  LearnMoreModalStepImg,
  LearnMoreModalFooterWrapper,
  LearnMoreModalButton,
} from './styled';
import { ILearnMoreModalProps } from './types';
import {
  SERIAL_NUMBER_MODAL_CONTENT,
  SERIAL_NUMBER_MODAL_WIDTH,
} from './constants';

const SerialNumberModal: FC<ILearnMoreModalProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <ModalDialog
    width={SERIAL_NUMBER_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <LearnMoreModalWrapper>
      <LearnMoreModalTitleWrapper>
        <LearnMoreModalTitle>
          {SERIAL_NUMBER_MODAL_CONTENT.title}
        </LearnMoreModalTitle>
        <LearnMoreModalText>
          {SERIAL_NUMBER_MODAL_CONTENT.text}
        </LearnMoreModalText>
      </LearnMoreModalTitleWrapper>
      {SERIAL_NUMBER_MODAL_CONTENT.steps.map((step, index) => (
        <LearnMoreModalStepWrapper key={index}>
          <LearnMoreModalStepNumber>
            {formatModalStepNumber(index + 1)}
          </LearnMoreModalStepNumber>
          <LearnMoreModalStepTextWrapper>
            <LearnMoreModalBoldText>{step.title}</LearnMoreModalBoldText>
            <LearnMoreModalText>{step.text}</LearnMoreModalText>
          </LearnMoreModalStepTextWrapper>
          <LearnMoreModalStepImg />
        </LearnMoreModalStepWrapper>
      ))}
      <LearnMoreModalFooterWrapper>
        <LearnMoreModalSubtitle>
          {SERIAL_NUMBER_MODAL_CONTENT.footerTitle}
        </LearnMoreModalSubtitle>
        <LearnMoreModalButton>
          {SERIAL_NUMBER_MODAL_CONTENT.footerButtonText}
        </LearnMoreModalButton>
      </LearnMoreModalFooterWrapper>
    </LearnMoreModalWrapper>
  </ModalDialog>
);

export default SerialNumberModal;
