import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { QUERY_KEYS } from 'constants/queryKeys';
import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';

import {
  IErrorPayload,
  IGetSerialNumbersParams,
  ISerialNumbersResponse,
} from './types';

const useGetSerialNumbers = (
  params: IGetSerialNumbersParams,
): UseQueryResult<ISerialNumbersResponse, AxiosError<IErrorPayload>> => {
  const { customerId, page, query, limit, filters, sortField, sortOrder } =
    params;

  return useQuery(
    [QUERY_KEYS.SERIAL_NUMBERS, filters, { page, query, sortField, sortOrder }],
    async () => {
      const { data: serialNumbersResponse } = await apiClient.get(
        ApiRoutes.SERIAL_NUMBERS,
        {
          params: {
            ...filters,
            customer: customerId,
            page,
            query,
            limit,
            sortField,
            sortOrder,
          },
        },
      );

      return serialNumbersResponse;
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(customerId),
    },
  );
};

export default useGetSerialNumbers;
