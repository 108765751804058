import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { Section } from '../styled';

export const SerialNumberCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 10px',
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '8px',
  boxShadow:
    '0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05)',
  '@media (max-width: 1024px)': {
    padding: '8px 10px',
  },
});

export const SerialNumberCardWrapper = styled(Section)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(20%, 1fr))',
  gap: '20px',
  margin: '0 auto 62px',
  paddingBottom: 0,
  '@media (max-width: 1440px)': {
    marginBottom: '42px',
    gap: '16px',
  },
  '@media (max-width: 768px)': {
    gridTemplateColumns: 'repeat(2, minmax(20%, 1fr))',
  },
});

export const CardTitle = styled('p')({
  margin: 0,
  fontWeight: 500,
  fontSize: '14px',
  letterSpacing: '0.2px',
  color: COLORS.gray700,
  textAlign: 'center',
});

export const CardData = styled(CardTitle)({
  fontWeight: 600,
  fontSize: '24px',
  textTransform: 'capitalize',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    fontSize: '18px',
  },
});
