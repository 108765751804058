import { FC } from 'react';

import { MarkAsRead, MarkAsReadDisabled } from 'assets/vectors';
import { Loader } from 'components/Loader';

import { IReadNotificationButtonProps } from './types';
import { ButtonContentWrapper, ClickableButtonWrapper } from './styled';
import { BUTTON_TEXT } from './constants';

const ReadNotificationButton: FC<IReadNotificationButtonProps> = ({
  notification,
  isNotificationReading,
  handleRead,
}) => {
  const getButtonContent = () => {
    if (isNotificationReading) {
      return <Loader size='small' />;
    }

    return notification.isRead ? (
      <MarkAsReadDisabled />
    ) : (
      <ClickableButtonWrapper placement='left' title={BUTTON_TEXT.tooltip}>
        <MarkAsRead onClick={handleRead} />
      </ClickableButtonWrapper>
    );
  };

  const content = getButtonContent();

  return <ButtonContentWrapper>{content}</ButtonContentWrapper>;
};

export default ReadNotificationButton;
