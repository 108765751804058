import styled from 'styled-components';

import Tooltip from 'components/Tooltip';

export const ButtonContentWrapper = styled('div')({
  minWidth: '22px',
  maxHeight: '22px ',
});

export const ClickableButtonWrapper = styled(Tooltip)({
  cursor: 'pointer',
});
