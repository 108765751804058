import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput, MAX_DEFAULT_LENGTH } from 'components/Inputs';
import { FormFieldWrapper, StyledButton } from 'pages/SignUp/styled';

import { USER_FORM_TEXT } from '../constants';
import { UserFormField } from '../types';
import { IEmailFieldsProps } from './types';

const EmailFields: FC<IEmailFieldsProps> = ({
  formik: { errors, getFieldProps, values },
  isCheckLoading,
  handleSubmit,
}) => (
  <>
    <FormFieldWrapper>
      <DefaultInput
        labelText={USER_FORM_TEXT.emailLabel}
        errorMessage={errors[UserFormField.EMAIL]}
        maxLength={MAX_DEFAULT_LENGTH}
        {...getFieldProps(UserFormField.EMAIL)}
      />
    </FormFieldWrapper>
    <StyledButton
      id={SIGN_UP_ACTIONS_BUTTONS_ID}
      loading={isCheckLoading}
      disabled={!values[UserFormField.EMAIL]}
      onClick={() => handleSubmit(values)}
    >
      {USER_FORM_TEXT.button}
    </StyledButton>
  </>
);
export default EmailFields;
