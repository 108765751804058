import styled from 'styled-components';

import { LogoLarge } from 'assets/vectors';
import { Link } from 'components/Link';
import BackToLink from 'components/BackToLink';
import { COLORS } from 'constants/colorPalette';

export const StyledLogo = styled(LogoLarge)({
  height: '35px',
  marginBottom: '32px',
});

export const HelpLink = styled(Link)({
  marginBottom: '40px',
});

export const StyledText = styled('p')({
  width: '100%',
  fontSize: '16px',
  color: COLORS.gray900,
  textAlign: 'center',
  marginTop: 'auto',
  marginBottom: 0,
});

export const StyledLink = styled(Link)({
  fontSize: '16px',
});

export const StyledBackToLink = styled(BackToLink)({
  margin: '0 auto 32px 0',
  fontSize: '16px',
  fontWeight: 600,
  color: COLORS.gray900,
  ':hover': {
    color: COLORS.gray600,
  },
});
