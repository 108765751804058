import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NOTIFICATION_ERROR_MESSAGE } from 'constants/messages';

export const renderSNRestrictedError = () =>
  openNotificationWithIcon(
    NotificationType.error,
    NOTIFICATION_ERROR_MESSAGE.serialNumberIsRestricted,
  );

export const renderSNCurrencyLockedError = () =>
  openNotificationWithIcon(
    NotificationType.error,
    NOTIFICATION_ERROR_MESSAGE.snCurrencyIsAlreadyLock,
  );
