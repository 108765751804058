import Dinero from 'dinero.js';

import { COMMA_STRING, EMPTY_STRING, NUMBERS_REG_EXP } from 'constants/common';

const REPLACE_PATTERN = /\B(?=(\d{3})+(?!\d))/g;
const DINERO_DOLLARS_MONEY_FORMAT = '$0,0.00';
const DINERO_COMMON_MONEY_FORMAT = '0,0';

type TMoneyFormatType = 'dollars' | 'common';

export const formatNumberWithSeparator = (
  value?: number | string | null,
  separator?: string,
): string =>
  value?.toString()?.replace(REPLACE_PATTERN, separator || COMMA_STRING) ||
  EMPTY_STRING;

export const formatNumberAsMoney = (
  cents = 0,
  formatType: TMoneyFormatType = 'dollars',
): string =>
  Dinero({
    amount: cents,
  }).toFormat(
    formatType == 'common'
      ? DINERO_COMMON_MONEY_FORMAT
      : DINERO_DOLLARS_MONEY_FORMAT,
  );

export const formatToNumbersOnly = (value: string) =>
  value.replace(NUMBERS_REG_EXP, EMPTY_STRING);
