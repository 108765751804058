import { ICurrenciesTable } from 'types/currencies';

import {
  DEFAULT_CURRENCIES_REQUEST_PAGE,
  DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
} from './constants';

export const renderOrderNumber =
  (page: number = DEFAULT_CURRENCIES_REQUEST_PAGE) =>
  (_id: string, _record: ICurrenciesTable, index: number) =>
    DEFAULT_CURRENCIES_REQUEST_PER_PAGE * (page - 1) + index + 1;
