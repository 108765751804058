import { FC } from 'react';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import {
  ActiveSubscriptionBadge,
  UnlockedSerialNumberBadge,
  LockedSerialNumberBadge,
} from 'components/Badges';
import Dropdown from 'components/Dropdown';
import { formatSerialNumber } from 'utils/format';
import { CurrencyType } from 'types/currencies';
import { SubscriptionStatus } from 'types/subscriptions';

import { SERIAL_NUMBER_HEADER } from './constants';
import ActionButton from './ActionButton';
import {
  BadgeWrapper,
  ButtonWrapper,
  HeaderCard,
  SerialNumber,
  SerialNumberText,
  SerialNumberWrapper,
  StyledShareIcon,
  StyledSaveIcon,
  SaveAndShareWrapper,
} from './styled';
import useSaveActions from './utils/useSaveActions';
import EnabledDate from '../EnabledDate';
import { ISerialNumberHeader } from '../types';

const SerialNumberHeader: FC<ISerialNumberHeader> = ({
  serialNumber,
  handleExportPdf,
  isPdfGenerating,
}) => {
  const {
    subscriptionStatus,
    serialNumber: snValue,
    isLocked: isRestricted,
    saveAndShareAccess: saveAndShareAccessByCurrency,
    currency,
    _id,
  } = serialNumber;

  const { actions, isLoading: isSaveFileProcessing } = useSaveActions({
    serialNumberId: _id,
    handleExportPdf,
  });
  const { handleShareModalOpen } = useSerialNumbersDetailsModalContext();

  const saveAndShareAccess =
    saveAndShareAccessByCurrency[
      currency as unknown as keyof typeof CurrencyType
    ];

  const isSaveAndShareEnabled = saveAndShareAccess.isActive;

  const saveAndShareEnabledTillDate = saveAndShareAccess?.enabledTill;

  return (
    <HeaderCard isRestricted={isRestricted}>
      <SerialNumberWrapper>
        <SerialNumberText>
          {SERIAL_NUMBER_HEADER.serialNumber}
          <SerialNumber>{formatSerialNumber(snValue)}</SerialNumber>
        </SerialNumberText>
      </SerialNumberWrapper>
      <ButtonWrapper>
        <BadgeWrapper>
          <ActiveSubscriptionBadge />
          {subscriptionStatus === SubscriptionStatus.active ? (
            <UnlockedSerialNumberBadge />
          ) : (
            <LockedSerialNumberBadge />
          )}
        </BadgeWrapper>
        {subscriptionStatus === SubscriptionStatus.active &&
          isSaveAndShareEnabled && (
            <SaveAndShareWrapper data-html2canvas-ignore>
              <EnabledDate date={saveAndShareEnabledTillDate} />
              <Dropdown
                disabled={isSaveFileProcessing || isPdfGenerating}
                items={actions}
                placement='bottomRight'
              >
                <ActionButton
                  id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                  loading={isSaveFileProcessing || isPdfGenerating}
                  width={75}
                >
                  {SERIAL_NUMBER_HEADER.saveButton}
                  <StyledSaveIcon />
                </ActionButton>
              </Dropdown>
              <ActionButton
                id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                width={82}
                disabled={isPdfGenerating}
                loading={isPdfGenerating}
                onClick={handleShareModalOpen}
              >
                {SERIAL_NUMBER_HEADER.shareButton}
                <StyledShareIcon />
              </ActionButton>
            </SaveAndShareWrapper>
          )}
      </ButtonWrapper>
    </HeaderCard>
  );
};

export default SerialNumberHeader;
