import { Menu as AntdMenu } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const StyledMenu = styled(AntdMenu)({
  'ant-dropdown-menu': {
    borderRadius: '8px',
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },
  '.ant-dropdown-menu-item': {
    padding: '10px 12px',
    color: COLORS.gray800,
  },
  '.ant-dropdown-menu-item:hover': {
    backgroundColor: COLORS.gray100,
  },
});
