import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';
import { TIsCollapsed } from 'components/Layouts/AuthorizedLayout/types';

export const List = styled('ul')({
  padding: 0,
  listStyleType: 'none',
});

export const StyledNavLink = styled(NavLink)({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '12px 16px',
  fontWeight: 500,
  color: COLORS.gray700,
  borderRadius: '8px',
  letterSpacing: '0.2px',
  transition: 'background .2s',
  '&:hover': {
    color: COLORS.gray700,
    background: COLORS.gray50,
  },
  '& svg': {
    width: '22px',
    height: '22px',
    stroke: COLORS.gray700,
    fill: 'transparent',
  },
  '&.active': {
    fontWeight: 800,
    background: COLORS.gray100,
    '& svg': {
      stroke: COLORS.gray900,
      fill: 'transparent',
    },
  },
});

export const StyledMenuIcon = styled(
  ({ isCollapsed: _isCollapsed, ...props }) => <Icon {...props} />,
)<TIsCollapsed>(({ isCollapsed }) => ({
  margin: 0,
  transition: '.2s ease',
  ...(isCollapsed && {
    paddingLeft: '12px',
  }),
}));

export const MenuTitleText = styled(
  ({ isCollapsed: _isCollapsed, children, ...props }) => (
    <span {...props}>{children}</span>
  ),
)<TIsCollapsed>(({ isCollapsed }) => ({
  paddingLeft: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  transition: 'padding, opacity, visibility, .2s ease',
  ...(isCollapsed && {
    paddingLeft: 0,
    opacity: 0,
    visibility: 'hidden',
  }),
}));
