import { FC, useEffect, useState } from 'react';

import { useSendVerificationCode } from 'api/auth';
import { TAxiosRequestError } from 'api/types';
import {
  INITIAL_TIMER_VALUE,
  VERIFICATION_CODE_TIMER_DURATION_MS,
} from 'constants/common';
import {
  ResponseType,
  SEND_VERIFICATION_CODE_ERROR_STRING,
} from 'constants/messages';
import { mapUpdateErrorMessage } from 'utils/errors/mapUpdateErrorMessage';

import { getSendVerificationCodeSuccessMessage } from '../utils';
import ResendCode from './ResendCode';
import { IResendCodeContainerProps } from './types';

const ResendCodeContainer: FC<IResendCodeContainerProps> = ({
  email,
  handleChangeResponseMessage,
  responseMessage,
}) => {
  const [timer, setTimer] = useState<number | ReturnType<typeof setTimeout>>(
    INITIAL_TIMER_VALUE,
  );

  const {
    mutate: sendVerificationCodeMutate,
    isLoading: isSendVerificationCodeLoading,
  } = useSendVerificationCode();

  const handleResendCodeSuccess = (email: string) => {
    handleChangeResponseMessage({
      type: ResponseType.SUCCESS,
      message: getSendVerificationCodeSuccessMessage(email),
    });

    restartTimer();
  };

  const handleResendCodeError = (error: TAxiosRequestError) => {
    const errorMessage = error.response?.data.message;

    if (errorMessage === SEND_VERIFICATION_CODE_ERROR_STRING) {
      handleChangeResponseMessage({
        type: ResponseType.ERROR,
        message: mapUpdateErrorMessage(error),
      });
    }

    if (timer) {
      clearTimeout(timer);
    }
  };

  const handleResendCode = () =>
    sendVerificationCodeMutate(email, {
      onSuccess: () => handleResendCodeSuccess(email),
      onError: handleResendCodeError,
    });

  const restartTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setTimer(INITIAL_TIMER_VALUE);
      }, VERIFICATION_CODE_TIMER_DURATION_MS),
    );
  };

  useEffect(() => {
    if (responseMessage) {
      restartTimer();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <ResendCode
      timer={timer}
      isSendVerificationCodeLoading={isSendVerificationCodeLoading}
      handleResendCode={handleResendCode}
    />
  );
};

export default ResendCodeContainer;
