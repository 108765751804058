import { useMutation, UseMutationResult } from 'react-query';

import { ApiRoutes } from 'api/routes';
import { apiClient } from 'api/base';
import { transformRoute } from 'utils/routing';
import { INotification } from 'types/notification';
import { TAxiosRequestError } from 'api/types';

type TOnSuccessFn = (notification: INotification) => void;

const useReadNotificationById = (
  onSuccess?: TOnSuccessFn,
): UseMutationResult<INotification, TAxiosRequestError, string> =>
  useMutation(
    async (id: string) => {
      const { data: notificationData } = await apiClient.patch(
        transformRoute(ApiRoutes.READ_NOTIFICATION_BY_ID, id),
      );

      return notificationData;
    },
    {
      retry: false,
      onSuccess: onSuccess,
    },
  );

export default useReadNotificationById;
