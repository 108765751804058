import { FC, useCallback } from 'react';
import { useFormik } from 'formik';

import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import { formatPhoneNumber } from 'components/Inputs/PhoneNumberInput/utils';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { INITIAL_SHARE_SMS_FORM_VALUES } from './constants';
import ShareSmsModal from './ShareSmsModal';
import { shareSmsValidationSchema } from './validation';
import {
  IShareSmsFormValues,
  ShareSmsFormField,
  IShareSmsModalContainerProps,
} from './types';

const ShareSmsModalContainer: FC<IShareSmsModalContainerProps> = ({
  serialNumber,
}) => {
  const { isShareSmsModalOpen, handleShareSmsModalClose } =
    useSerialNumbersDetailsModalContext();

  const handleOnSubmit = (profileFormValues: IShareSmsFormValues) => {
    const { countryCode, phoneNumber } = profileFormValues;
    // TODO: update later, when Klaviyo SMS will be integrated
    console.log(
      {
        countryCode,
        phoneNumber: formatPhoneNumber({ value: phoneNumber, countryCode }),
      },
      serialNumber,
    );

    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.shareSerialNumberBySms,
    );
    onClose();
  };

  const formik = useFormik({
    initialValues: INITIAL_SHARE_SMS_FORM_VALUES,
    onSubmit: handleOnSubmit,
    validationSchema: shareSmsValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const handleChangePhoneNumberValue = (value: string) =>
    formik.setFieldValue(ShareSmsFormField.PHONE_NUMBER, value);

  const handleChangeCountryCodeValue = (value: unknown) =>
    formik.setFieldValue(ShareSmsFormField.COUNTRY_CODE, value);

  const onClose = useCallback(() => {
    formik.resetForm();
    handleShareSmsModalClose();
  }, [formik, handleShareSmsModalClose]);

  return (
    <ShareSmsModal
      formik={formik}
      isModalOpened={isShareSmsModalOpen}
      handleCloseModal={onClose}
      handleChangePhoneNumberValue={handleChangePhoneNumberValue}
      handleChangeCountryCodeValue={handleChangeCountryCodeValue}
    />
  );
};

export default ShareSmsModalContainer;
