export const ENTRY_STEP_MODAL_WIDTH = 737;

export const ENTRY_STEP_MODAL_CONTENT = {
  title: 'How It Works',
  subtitle: 'Verifying Your Dinar',
  text: "Verifying your dinar is fast and easy! If you're an existing customer please sign in to your account to complete your verification.",
  tip: "If you're a new customer please sign up to complete your verification.",
  stepsTitle: "Here's How It Works",
  steps: [
    {
      title: 'Sign Up',
      text: 'Click the "Sign Up" button.',
    },
    {
      title: 'Enter Your Details',
      text: 'Answer a few simple questions about yourself.',
    },
    {
      title: 'Verified or Not',
      text: "You're done! See if your dinars verified or not, instantly!",
    },
  ],
  footerTitle: 'Have more questions?',
  footerButtonText: 'Get in touch',
};

export const SERIAL_NUMBER_MODAL_WIDTH = 1025;

export const SERIAL_NUMBER_MODAL_CONTENT = {
  title: 'Verify Your Dinar In 4 Simple Steps',
  text: 'Verifying your dinar is fast and easy!',
  steps: [
    {
      title: 'Enter Your Serial Number',
      text: 'Please enter the 16 digit serial number located on the front of your Certificate of Authenticity. Please make sure to enter it exactly as shown.',
    },
    {
      title: 'Click Verify now',
      text: 'Next, click the "Verify now" button.',
    },
    {
      title: 'We’re verifying your dinar',
      text: 'This takes less then 30 seconds.',
    },
    {
      title: 'Login or Sign up',
      text: "If you're an existing customer please login to your account to complete your verification. If you're a new customer please sign up to complete your verification.",
    },
  ],
  footerTitle: 'Have more questions?',
  footerButtonText: 'Get in touch',
};

export const NOT_VERIFIED_MODAL_CONTENT = {
  title: 'Serial Number Not Verified',
  subtitle: 'Verify Your Dinar In 3 Simple Steps',
  text: "The serial number you entered couldn't be verified. Please follow the steps below so that we can verify your dinar.",
  steps: [
    {
      title: 'Enter Your Serial Number',
      text: 'Please enter the 16 digit serial number located on the front of your Certificate of Authenticity. Please make sure to enter it exactly as shown.',
    },
    {
      title: 'Click Verify',
      text: 'Next, click the "Verify" button.',
    },
    {
      title: 'Dinar Verified',
      text: "That's it! We will verify your dinar in real time and tell you if it was verified or not. If your dinar still can't be verified please contact customer support for further assistance.",
    },
  ],
  footerTitle: 'Have more questions?',
  footerButtonText: 'Get in touch',
};
