import dayjs from 'dayjs';

export const PAGE_PATH_REG_EXP = {
  RESET: /reset/,
  FORGOT: /forgot/,
  SIGN_UP: /sign-up/,
};

export const UNAUTHORIZED_LAYOUT_TEXT = {
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  SIGN_UP: 'Sign Up',
  SIGN_IN: 'Sign In', //TODO remove
  WELCOME_MESSAGE: 'Welcome\nto our awesome\ndigital product',
  CALL_MESSAGE: 'Give us a Call 888-888-888',
  HELP: 'Help',
  HAVE_ACCOUNT: 'Already have an account?',
  RIGHTS_MESSAGE: `© ${dayjs().year()} DinarVerify`,
};
