import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { COMMON_DATE_FORMAT } from 'constants/datetime';

dayjs.extend(relativeTime);

export const formatDateString = (
  dateString?: string | Date,
  format = COMMON_DATE_FORMAT,
): string => dayjs(dateString).format(format);

export const formatDateFromNow = (date: string | Date): string =>
  dayjs(date).fromNow();
