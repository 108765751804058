import { TOptionType } from './types';

export const DEALER_DETAILS_TEXT = {
  title: 'Dealer Details',
  edit: 'Edit',
  dealer: 'Dealer',
  USTreasury: 'US Treasury',
  stateLicence: 'State License',
  companyStatus: 'Company Status',
  headquarters: 'Headquarters',
  yearsInBusiness: 'Years in Business',
  website: 'Website',
  phone: 'Phone',
  email: 'Email',
  social: 'Social',
  betterBusinessBureau: 'Better Business Bureau',
  address: 'Address',
};

export const ACCREDITATION_OPTIONS: TOptionType = {
  1: 'Accredited',
  2: 'Unaccredited',
};

export const RATING_OPTIONS: TOptionType = {
  1: 'A',
  2: 'A+',
  3: 'B',
  4: 'B+',
  5: 'C',
  6: 'C+',
  7: 'D',
  8: 'D+',
  9: 'F',
  10: 'F+',
};

export const HEADQUARTERS_OPTIONS: TOptionType = {
  1: 'Alabama',
  2: 'Alaska',
  3: 'Arizona',
  4: 'Arkansas',
  5: 'California',
  6: 'Colorado',
  7: 'Connecticut',
  8: 'Delaware',
  9: 'District of Columbia',
  10: 'Florida',
  11: 'Georgia',
  12: 'Hawaii',
  13: 'Idaho',
  14: 'Illinois',
  15: 'Indiana',
  16: 'Iowa',
  17: 'Kansas',
  18: 'Kentucky',
  19: 'Louisiana',
  20: 'Maine',
  21: 'Maryland',
  22: 'Massachusetts',
  23: 'Michigan',
  24: 'Minnesota',
  25: 'Mississippi',
  26: 'Missouri',
  27: 'Montana',
  28: 'Nebraska',
  29: 'Nevada',
  30: 'New Hampshire',
  31: 'New Jersey',
  32: 'New Mexico',
  33: 'New York',
  34: 'North Carolina',
  35: 'North Dakota',
  36: 'Ohio',
  37: 'Oklahoma',
  38: 'Oregon',
  39: 'Pennsylvania',
  40: 'Rhode Island',
  41: 'South Carolina',
  42: 'South Dakota',
  43: 'Tennessee',
  44: 'Texas',
  45: 'Utah',
  46: 'Vermont',
  47: 'Virginia',
  48: 'Washington',
  49: 'West Virginia',
  50: 'Wisconsin',
  51: 'Wyoming',
};

export const STRING_WITH_VERTICAL = '| ';
