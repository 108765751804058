import { FC } from 'react';
import { Popover } from 'antd';

import { IInformPopoverProps } from './types';
import { IconWrapper, InfoIcon } from './styled';
import { ICON_FONT_SIZE } from './constants';
import InformPopoverTitle from './InformPopoverTitle';
import InformPopoverContent from './InformPopoverContent';

const InformPopover: FC<IInformPopoverProps> = ({
  iconSize = ICON_FONT_SIZE.default,
  placement = 'rightTop',
  informContent,
  children,
}) => {
  const { title, content } = informContent;

  return (
    <Popover
      placement={placement}
      title={<InformPopoverTitle title={title} />}
      content={<InformPopoverContent content={content} />}
    >
      <IconWrapper>{children ?? <InfoIcon iconSize={iconSize} />}</IconWrapper>
    </Popover>
  );
};

export default InformPopover;
