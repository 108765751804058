import { FC } from 'react';

import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';
import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { StyledImg } from './styled';
import { SHARE_QR_MODAL_CONTENT } from './constants';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import {
  CancelButton,
  ShareActionsWrapper,
  ShareButton,
  SharedSubtitle,
  SharePdfModal,
} from '../styled';

const ShareQRModal: FC = () => {
  const {
    uploadedPdf,
    isShareQRModalOpen,
    handleShareQRModalClose,
    handleCopyPdfLink,
  } = useSerialNumbersDetailsModalContext();

  const handleShare = () => {
    handleCopyPdfLink(true)
      .then(() =>
        openNotificationWithIcon(
          NotificationType.success,
          NOTIFICATION_SUCCESS_MESSAGE.shareSerialNumberByQr,
        ),
      )
      .catch(() =>
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.shareSerialNumberByQr,
        ),
      )
      .finally(handleShareQRModalClose);
  };

  return (
    <SharePdfModal
      destroyOnClose
      title={SHARE_QR_MODAL_CONTENT.TITLE}
      isModalOpened={isShareQRModalOpen}
      handleCloseModal={handleShareQRModalClose}
    >
      <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
      <StyledImg alt='QR Code' src={uploadedPdf?.qrCodeUrl} />
      <ShareActionsWrapper>
        <CancelButton type='ghost' onClick={handleShareQRModalClose}>
          {SHARE_QR_MODAL_CONTENT.CANCEL_LABEL}
        </CancelButton>
        <ShareButton
          id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
          type='primary'
          onClick={handleShare}
        >
          {SHARE_QR_MODAL_CONTENT.COPY_QR_LABEL}
        </ShareButton>
      </ShareActionsWrapper>
    </SharePdfModal>
  );
};

export default ShareQRModal;
