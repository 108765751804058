import { APP_ROUTE } from 'constants/appRoutes';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { verifyImage } from 'assets/images';
import { LogoIcon } from 'assets/vectors/menu';

import { VERIFIED_PAGE } from './constants';
import { StyledWrapper, StyledImage, StyledText, StyledButton } from './styled';

const VerifiedPayment: FC = () => {
  const navigateTo = useNavigate();

  const handleClick = useCallback(() => {
    navigateTo(APP_ROUTE.serialNumbers);
  }, [navigateTo]);

  return (
    <StyledWrapper>
      <StyledImage src={verifyImage} alt='verify' />
      <LogoIcon />
      <StyledText>{VERIFIED_PAGE.TEXT}</StyledText>
      <StyledButton onClick={handleClick}>
        {VERIFIED_PAGE.BUTTON_TEXT}
      </StyledButton>
    </StyledWrapper>
  );
};

export default VerifiedPayment;
