import { FC } from 'react';

import HelmetPageTitle from 'components/HelmetPageTitle';
import { NoInternet } from 'assets/images';

import {
  ErrorCode,
  ErrorSubtitle,
  ErrorTitle,
  NotFoundHolder,
  PageWrapper,
  ActionButton,
  StyledIcon,
} from './styled';
import { GO_HOME_BUTTON_TEXT, TRY_AGAIN_BUTTON_TEXT } from './constants';
import { ITechnicalMessageProps } from './types';

const TechnicalMessage: FC<ITechnicalMessageProps> = ({
  pageTitle,
  backLink,
  errorCode,
  title,
  subTitle,
  isNoInternetPage,
  handleActionButtonClick,
}) => (
  <HelmetPageTitle title={pageTitle} backLinkPath={backLink}>
    <PageWrapper>
      <NotFoundHolder isNoInternetPage={isNoInternetPage}>
        {errorCode && <ErrorCode>{errorCode}</ErrorCode>}
        {isNoInternetPage && <StyledIcon component={NoInternet} />}
        <ErrorTitle>{title}</ErrorTitle>
        <ErrorSubtitle isNoInternetPage={isNoInternetPage}>
          {subTitle}
        </ErrorSubtitle>
        <ActionButton onClick={handleActionButtonClick}>
          {isNoInternetPage ? TRY_AGAIN_BUTTON_TEXT : GO_HOME_BUTTON_TEXT}
        </ActionButton>
      </NotFoundHolder>
    </PageWrapper>
  </HelmetPageTitle>
);

export default TechnicalMessage;
