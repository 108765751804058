import { useMutation } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';

import { IShareSerialNumberPayload } from './types';

const useShareSerialNumber = () => {
  return useMutation(async (payload: IShareSerialNumberPayload) => {
    const { data } = await apiClient.post(
      ApiRoutes.SHARE_SERIAL_NUMBER,
      payload,
    );

    return data;
  });
};

export default useShareSerialNumber;
