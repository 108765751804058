import styled from 'styled-components';

import { NotVerifiedCloseIcon } from 'assets/vectors';
import { COLORS } from 'constants/colorPalette';

export const NotVerifiedTitle = styled('h1')({
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: 1.2,
  color: COLORS.red500,
});

export const IconWrapper = styled('div')({
  position: 'relative',
  width: '122px',
  height: '122px',
  borderRadius: '50%',
  border: `1px solid ${COLORS.gray300}`,
  padding: '30px',
  margin: '0 auto 24px',
});

export const NotVerifiedIcon = styled(NotVerifiedCloseIcon)({
  position: 'absolute',
  bottom: 0,
  right: '-12px',
  width: '60px',
  height: '60px',
});
