import { FC, useEffect } from 'react';

import { SECOND_MS } from 'constants/common';

import { IVerificationStepContainerProps } from './types';
import { ANIMATION_VALUES } from './VerificationBlock/constants';
import VerificationStep from './VerificationStep';

const VerificationStepContainer: FC<IVerificationStepContainerProps> = ({
  serialNumber,
  handleNextStep,
}) => {
  useEffect(() => {
    setTimeout(() => {
      handleNextStep();
    }, ANIMATION_VALUES.fullAnimationDuration * SECOND_MS);
  }, []);

  return <VerificationStep serialNumber={serialNumber} />;
};

export default VerificationStepContainer;
