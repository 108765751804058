import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { BLOCK_VALUES } from './VerificationBlock/constants';
import { BLOCKS_COUNT } from './constants';

export const VerificationStepWrapper = styled('div')({
  width: '100%',
});

export const VerificationStepContent = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  margin: '18px auto',
  height: BLOCK_VALUES.offsetHeight * BLOCKS_COUNT,
});

export const VerificationStepWarning = styled('div')({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '60px',
  backgroundColor: COLORS.orange300,
  color: COLORS.orange700,
  textAlign: 'center',
  padding: '12px',
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  '@media (max-width: 1600px)': {
    fontSize: '20px',
    lineHeight: '28px',
    height: '48px',
    padding: '10px',
  },
});
