import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';

import { PageWrapper } from './styled';

const Dashboard: FC = () => {
  return (
    <HelmetPageTitle title={MENU_TITLE.dashboard}>
      <PageWrapper>
        <h1>Dashboard</h1>
      </PageWrapper>
    </HelmetPageTitle>
  );
};

export default Dashboard;
