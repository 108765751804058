export const COUNTRIES_SELECT_MOCK = [
  { option: 'US', value: 'US' },
  { option: 'BLR', value: 'BLR' },
];

export const SERIAL_NUMBER_DETAILS_MOCK = {
  createdAt: 'Oct 3, 2022',
  populatedAt: 'Oct 22, 2022',
  currency: 'Iraqi Dinar',
  identity: 'Verified',
  currencyLTV: 0,
  subscriptionLTV: 0,
  companyStatus: 'Active',
};
