import { useState } from 'react';

import { env } from 'config/env';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import {
  JSON_TYPE,
  PDF_BLOB_TYPE,
} from 'pages/SerialNumberDetails/utils/constants';

import useScript from './useScript';
import { AccessTokenPrompt, GOOGLE, GOOGLE_POPUP_CLOSED_ERROR, UPLOAD_FILE_OPTIONS } from '../constants';

const useGoogle = (serialNumberId: string) => {
  const isGapiLoaded = useScript(GOOGLE.GAPI_SCRIPT, {
    async: true,
    defer: true,
    onload: gapiLoaded,
  });
  const isGsiLoaded = useScript(GOOGLE.GSI_SCRIPT, {
    async: true,
    defer: true,
  });

  const { pdfBytes } = useSerialNumbersDetailsModalContext();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  function gapiLoaded() {
    gapi.load(GOOGLE.CLIENT, initializeGapiClient);
  }

  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: env.REACT_APP_GOOGLE_API_KEY,
      discoveryDocs: GOOGLE.DISCOVERY_DOCS,
    });
  };

  const upload = (accessToken: string) => {
    setIsFileUploading(true);

    const file = new Blob([pdfBytes as Uint8Array], {
      type: PDF_BLOB_TYPE,
    });

    const metadata = {
      name: serialNumberId, // Filename at Google Drive
      mimeType: PDF_BLOB_TYPE, // mimeType at Google Drive
    };

    const form = new FormData();
    form.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], { type: JSON_TYPE }),
    );
    form.append('file', file);

    fetch(UPLOAD_FILE_OPTIONS.URL, {
      method: UPLOAD_FILE_OPTIONS.POST,
      headers: new Headers({
        Authorization: UPLOAD_FILE_OPTIONS.BEARER + accessToken,
      }),
      body: form,
    })
      .then(() =>
        openNotificationWithIcon(
          NotificationType.success,
          NOTIFICATION_SUCCESS_MESSAGE.saveSerialNumberToGoogle,
        ),
      )
      .catch(() =>
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToGoogle,
        ),
      )
      .finally(() => setIsFileUploading(false));
  };

  const authorizeAndUpload = () => {
    const token = window.google.accounts.oauth2.initTokenClient({
      client_id: env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: GOOGLE.SCOPES,
    });

    token.callback = ({ error, access_token }) => {
      if (error !== undefined) {
        setIsFileUploading(false);
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToGoogle,
        );

        return;
      }

      upload(access_token as string);
    };

    token.error_callback = (error) => {
      setIsFileUploading(false);
      if (error.type !== GOOGLE_POPUP_CLOSED_ERROR) {
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToGoogle,
        );
      }
    };

    token.requestAccessToken({
      prompt:
        gapi.client.getToken() === null
          ? AccessTokenPrompt.consent
          : AccessTokenPrompt.empty,
    });
  };

  return {
    isLoading: !isGapiLoaded || !isGsiLoaded || isFileUploading,
    upload: authorizeAndUpload,
  };
};

export default useGoogle;
