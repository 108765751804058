import isString from 'lodash/isString';

import { trimAllExtraSpaces } from '../strings';

export const trimObjectValues = (obj: object) => {
  const trimmedValues = Object.entries(obj).map(([key, value]) => {
    if (!isString(value)) return [key, value];

    return [key, trimAllExtraSpaces(value as string)];
  });

  return Object.fromEntries(trimmedValues);
};
