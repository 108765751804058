import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useToggle } from 'hooks';
import { APP_ROUTE } from 'constants/appRoutes';

import { EntryStepModal } from '../LearnMoreModal';
import { IEntryStepContainerProps } from './types';
import EntryStep from './EntryStep';

const EntryStepContainer: FC<IEntryStepContainerProps> = ({
  handleNextStep,
}) => {
  const navigateTo = useNavigate();
  const navigateToSignIn = () => navigateTo(APP_ROUTE.signIn);

  const [isLearnMoreModalOpened, toggleIsLearnMoreModalOpened] = useToggle();

  const handleLearnMoreModalToggle = () => toggleIsLearnMoreModalOpened();

  return (
    <>
      <EntryStep
        navigateToSignIn={navigateToSignIn}
        handleNextStep={handleNextStep}
        handleEntryStepModalOpen={handleLearnMoreModalToggle}
      />
      <EntryStepModal
        isModalOpened={isLearnMoreModalOpened}
        handleCloseModal={handleLearnMoreModalToggle}
      />
    </>
  );
};

export default EntryStepContainer;
