import { FC, useState } from 'react';

import { useGetCurrenciesForRequest } from 'api/requests';
import { ICurrencyTableContainerProps } from 'types/request';

import {
  DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
  DEFAULT_CURRENCIES_REQUEST_PAGE,
} from './constants';
import CurrencyTable from './CurrencyTable';

const CurrencyTableContainer: FC<ICurrencyTableContainerProps> = ({
  requestId,
}) => {
  const [page, setPage] = useState<number>(DEFAULT_CURRENCIES_REQUEST_PAGE);

  const {
    data: currenciesForRequestResponse,
    isLoading: isCurrenciesForRequestLoading,
    isFetching: isCurrenciesForRequestFetching,
  } = useGetCurrenciesForRequest(requestId, {
    page,
    limit: DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
  });

  const handleChangePage = (pageNumber: number) => setPage(pageNumber);

  return (
    <CurrencyTable
      page={page}
      currencyItems={currenciesForRequestResponse?.currencies}
      totalCurrencies={currenciesForRequestResponse?.totalItems}
      isCurrenciesForRequestLoading={isCurrenciesForRequestLoading}
      isCurrenciesForRequestFetching={isCurrenciesForRequestFetching}
      handleChangePage={handleChangePage}
    />
  );
};

export default CurrencyTableContainer;
