import { BLOCKS_COUNT } from '../constants';

export const FIRST_BLOCK_INDEX = 0;

export const BLOCK_VALUES = {
  height: 90,
  marginBottom: 33,

  get offsetHeight() {
    return this.height + this.marginBottom;
  },
};

export const ANIMATION_VALUES = {
  progressIterationDuration: 1,
  progressIterations: 4,
  pauseDuration: 0.75,
  moveXDuration: 1.25,
  translateX: 420,
  moveYDuration: 0.75,

  get progressDuration() {
    return this.progressIterationDuration * this.progressIterations;
  },

  get blockAnimationDuration() {
    return this.progressDuration + this.pauseDuration + this.moveXDuration;
  },

  get blockCompleteAnimationKeyframe() {
    const oneSecInPercent = 100 / this.blockAnimationDuration;
    const startKeyframe = oneSecInPercent * this.progressDuration;
    const endKeyframe = startKeyframe + oneSecInPercent * this.pauseDuration;

    return `${startKeyframe.toFixed(2)}%, ${endKeyframe.toFixed(2)}%`;
  },

  get fullAnimationDuration() {
    return (
      this.blockAnimationDuration + this.progressDuration * (BLOCKS_COUNT - 1)
    );
  },
};
