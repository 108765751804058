import { FC } from 'react';

import { useAuthenticate } from 'api/auth';
import { EMPTY_STRING } from 'constants/common';
import { useFormik } from 'hooks';

import SignInForm from './SignInForm';
import { formValidationSchema } from './validation/schemas';
import { ISignInFormValues, SignInFormField } from './types';

const SignInFormContainer: FC = () => {
  const {
    mutate: authenticateMutate,
    isLoading: isAuthenticateLoading,
    error,
  } = useAuthenticate();

  const handleAuthenticate = (signInPayload: ISignInFormValues) =>
    authenticateMutate(signInPayload);

  const formik = useFormik({
    initialValues: {
      [SignInFormField.EMAIL]: EMPTY_STRING,
      [SignInFormField.PASSWORD]: EMPTY_STRING,
      [SignInFormField.REMEMBER]: false,
    },
    validationSchema: formValidationSchema,
    onSubmit: handleAuthenticate,
  });

  return (
    <SignInForm
      formik={formik}
      isAuthenticateLoading={isAuthenticateLoading}
      errorMessage={error?.response?.data?.message || error?.message}
    />
  );
};

export default SignInFormContainer;
