import { Badge, Switch } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { TrashIcon, MarkIcon } from 'assets/vectors';

export const NotificationsHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderTitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

export const SectionTitle = styled('h2')({
  margin: 0,
  fontSize: '18px',
  fontWeight: 800,
});

export const NotificationsWrapper = styled('div')({
  display: 'flex',
});

export const BadgeWrapper = styled(
  ({ onClick, children, notificationIconRef, ...props }) => (
    <div ref={notificationIconRef} onClick={onClick}>
      <Badge {...props}>{children}</Badge>
    </div>
  ),
)({
  position: 'relative',
  marginRight: '30px',
  cursor: 'pointer',
});

export const NotificationsInfo = styled(({ children, boxRef, ...props }) => (
  <div {...props} ref={boxRef}>
    {children}
  </div>
))({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '80px',
  right: '200px',
  zIndex: 2,
  width: '514px',
  maxHeight: '762px',
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.gray300}`,
  borderTop: 'none',
  boxShadow: '-8px 8px 40px rgba(0, 0, 0, 0.08)',
  padding: '24px',
  overflow: 'scroll',
  '& > :last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  '@media (max-width: 768px)': {
    right: 0,
  },
});

export const NotificationGroupLabel = styled('p')({
  marginBottom: '16px',
  fontWeight: '500',
  fontSize: '14px',
  letterSpacing: '0.2px',
  color: COLORS.gray500,
  textTransform: 'uppercase',
});

export const ActionsWrapper = styled('div')({
  display: 'flex',
  fontSize: '16px',
  color: COLORS.gray700,
});

export const SubHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const SwitchWrapper = styled(Switch)({
  marginLeft: '10px',
});

export const TrashIconWrapper = styled((props) => (
  <div onClick={props.onClick}>
    <TrashIcon {...props} />
  </div>
))({
  cursor: 'pointer',
  width: '18px',
});

export const Actions = styled(
  ({ isVisible: _isVisible, children, ...props }) => (
    <div {...props}>{children}</div>
  ),
)(({ isVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '60px',
  right: '20px',
  zIndex: 3,
  width: '184px',
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.gray300}`,
  borderTop: 'none',
  borderRadius: '6px',
  boxShadow:
    '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);',
  ...(!isVisible && {
    display: 'none',
  }),
}));

export const KebabMenu = styled('div')({
  cursor: 'pointer',
});

export const Option = styled('div')({
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '14px',
  color: COLORS.gray700,
  ':hover': {
    color: COLORS.gray100,
  },
  ':active': {
    color: COLORS.gray200,
  },
});

export const ReadIcon = styled(MarkIcon)({
  width: '14px',
  marginRight: '9px',
});

export const DeleteIcon = styled(TrashIcon)({
  width: '14px',
  marginRight: '9px',
});

export const MarkButton = styled('div')({
  marginBottom: '16px',
  display: 'flex',
  alignSelf: 'flex-end',
  color: COLORS.blue500,
  fontWeight: 800,
  fontSize: '14px',
  letterSpacing: '0.2px',
  cursor: 'pointer',
  ':hover': {
    color: COLORS.blue600,
  },
  ':active': {
    color: COLORS.blue600,
  },
});
