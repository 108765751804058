import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/transformRoute';
import { IForgotPasswordValues } from 'pages/ForgotPassword/types';
import { UserTypes } from 'types/user';

import { IForgotPasswordProps, IRequestErrorPayload } from './types';

const useForgotPassword = (
  options: IForgotPasswordProps,
): UseMutationResult<
  void,
  AxiosError<IRequestErrorPayload>,
  IForgotPasswordValues
> => {
  return useMutation(async (data: IForgotPasswordValues) => {
    const response = await apiClient.post(
      transformRoute(ApiRoutes.FORGOT_PASSWORD, UserTypes.customer),
      data,
    );
    return response.data;
  }, options);
};

export default useForgotPassword;
