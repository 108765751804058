import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { ApprovedIcon } from 'assets/vectors';
import { PageTitle, PageSubtitle } from 'components/UnauthorizedPages';

import { ENTRY_STEP_TEXT } from './constants';
import { EntryStepWrapper, StyledButton, IconWrapper } from './styled';
import { IEntryStepProps } from './types';
import { StyledTitleWrapper, StyledLearnMoreButton } from '../styled';

const EntryStep: FC<IEntryStepProps> = ({
  handleNextStep,
  navigateToSignIn,
  handleEntryStepModalOpen,
}) => (
  <EntryStepWrapper>
    <IconWrapper>
      <ApprovedIcon />
    </IconWrapper>
    <StyledTitleWrapper>
      <PageTitle>{ENTRY_STEP_TEXT.title}</PageTitle>
      <PageSubtitle>{ENTRY_STEP_TEXT.subtitle}</PageSubtitle>
    </StyledTitleWrapper>
    <StyledLearnMoreButton type='link' onClick={handleEntryStepModalOpen}>
      {ENTRY_STEP_TEXT.learnMoreLink}
    </StyledLearnMoreButton>
    <StyledButton type='default' onClick={navigateToSignIn}>
      {ENTRY_STEP_TEXT.signInButton}
    </StyledButton>
    <StyledButton id={SIGN_UP_ACTIONS_BUTTONS_ID} onClick={handleNextStep}>
      {ENTRY_STEP_TEXT.signUpButton}
    </StyledButton>
  </EntryStepWrapper>
);

export default EntryStep;
