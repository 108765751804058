import { DASH_STRING } from 'constants/common';

import { getCurrencyIcon } from '../getCurrencyIcon';
import { CurrencyTitle, CurrencyWrapper } from './styled';

export const renderCurrency = (currency: string): React.ReactNode =>
  currency ? (
    <CurrencyWrapper>
      {getCurrencyIcon(currency)}
      <CurrencyTitle>{currency}</CurrencyTitle>
    </CurrencyWrapper>
  ) : (
    DASH_STRING
  );
