import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/routing';
import { QUERY_KEYS } from 'constants/queryKeys';
import { generateSnDetailsCanvas } from 'pages/SerialNumberDetails/utils/exportPdf';

import { FORM_DATA_FILENAME, RESPONSE_BLOB_TYPE } from './constants';

const useGenerateExportPdf = (
  requestId: string,
  isEnabled: boolean,
): UseQueryResult<ArrayBuffer, AxiosError> =>
  useQuery(
    [QUERY_KEYS.GENERATE_EXPORT_PDF, requestId],
    async () => {
      const snDetailsCanvas = await generateSnDetailsCanvas();

      const data = await new Promise<ArrayBuffer>((resolve, reject) =>
        snDetailsCanvas.toBlob((blob) => {
          const form = new FormData();
          form.append(FORM_DATA_FILENAME, blob as Blob);

          apiClient
            .post(
              transformRoute(ApiRoutes.GENERATE_EXPORT_PDF, requestId),
              form,
              {
                responseType: RESPONSE_BLOB_TYPE,
              },
            )
            .then(({ data }) => resolve(data))
            .catch((err) => reject(err));
        }),
      );

      return data;
    },
    {
      enabled: isEnabled,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export default useGenerateExportPdf;
