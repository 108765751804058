import { EMPTY_STRING, NULL_INDEX, NUMBERS_REG_EXP } from 'constants/common';

import {
  LENGTH_WITHOUT_CURRENCY_CODE,
  LENGTH_WITH_THREE_PARTS_OF_CODE,
  LENGTH_WITH_TWO_PARTS_OF_CODE,
  SKIP_FIRST_PART_OF_CODE,
  SKIP_LAST_PART_OF_CODE,
  SKIP_SECOND_PART_OF_CODE,
  SKIP_THIRD_PART_OF_CODE,
} from './constants';

const getPartOfSerialNumber = (
  serialNumber: string,
  start: number,
  end: number,
) => serialNumber.slice(start, end);

export const formatSerialNumber = (formatingValue: string) => {
  const replacedSerialNumber = formatingValue.replace(
    NUMBERS_REG_EXP,
    EMPTY_STRING,
  );

  const replacedSerialNumberLength = replacedSerialNumber.length;

  if (replacedSerialNumberLength < LENGTH_WITHOUT_CURRENCY_CODE) {
    return `${replacedSerialNumber}`;
  }

  if (replacedSerialNumberLength < LENGTH_WITH_TWO_PARTS_OF_CODE) {
    return `${getPartOfSerialNumber(
      replacedSerialNumber,
      NULL_INDEX,
      SKIP_FIRST_PART_OF_CODE,
    )}-${getPartOfSerialNumber(
      replacedSerialNumber,
      SKIP_FIRST_PART_OF_CODE,
      SKIP_SECOND_PART_OF_CODE,
    )}`;
  }

  if (replacedSerialNumberLength < LENGTH_WITH_THREE_PARTS_OF_CODE) {
    return `${getPartOfSerialNumber(
      replacedSerialNumber,
      NULL_INDEX,
      SKIP_FIRST_PART_OF_CODE,
    )}-${getPartOfSerialNumber(
      replacedSerialNumber,
      SKIP_FIRST_PART_OF_CODE,
      SKIP_SECOND_PART_OF_CODE,
    )}-${getPartOfSerialNumber(
      replacedSerialNumber,
      SKIP_SECOND_PART_OF_CODE,
      SKIP_THIRD_PART_OF_CODE,
    )}`;
  }

  return `${getPartOfSerialNumber(
    replacedSerialNumber,
    NULL_INDEX,
    SKIP_FIRST_PART_OF_CODE,
  )}-${getPartOfSerialNumber(
    replacedSerialNumber,
    SKIP_FIRST_PART_OF_CODE,
    SKIP_SECOND_PART_OF_CODE,
  )}-${getPartOfSerialNumber(
    replacedSerialNumber,
    SKIP_SECOND_PART_OF_CODE,
    SKIP_THIRD_PART_OF_CODE,
  )}-${getPartOfSerialNumber(
    replacedSerialNumber,
    SKIP_THIRD_PART_OF_CODE,
    SKIP_LAST_PART_OF_CODE,
  )}`;
};
