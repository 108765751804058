import { FC } from 'react';
import { Select as AntdSelect } from 'antd';

import InputBase from 'components/Inputs/components/InputBase';
import { DownOutlinedIcon } from 'assets/vectors';

import { ISelectProps } from './types';
import { StyledSelect } from './styled';

const { Option } = AntdSelect;

const Select: FC<ISelectProps> = ({
  id,
  disabled,
  selectConfig,
  labelText,
  errorMessage,
  className,
  optionLabelProp,
  dropdownMatchSelectWidth,
  value,
  placeholder,
  allowClear = true,
  required = true,
  onChange,
}) => (
  <InputBase
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    labelText={labelText}
    errorMessage={errorMessage}
    value={String(value)}
  >
    <StyledSelect
      id={id}
      disabled={disabled}
      allowClear={allowClear}
      className={className}
      placeholder={placeholder}
      status={errorMessage && 'error'}
      value={value}
      suffixIcon={<DownOutlinedIcon />}
      optionLabelProp={optionLabelProp}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode?.parentNode as HTMLElement
      }
      onChange={onChange}
    >
      {selectConfig.map(({ value, label, disabled, selectedLabel }) => (
        <Option
          key={value}
          value={value}
          disabled={disabled}
          selectedLabel={selectedLabel}
        >
          {label}
        </Option>
      ))}
    </StyledSelect>
  </InputBase>
);

export default Select;
