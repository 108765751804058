import { IResponseMessage } from 'constants/messages';
import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { TFormik } from 'types/formik';

import { IUserFormValues, UserStepperKeys } from './types';
import EmailFields from './EmailFields';
import PasswordFields from './PasswordFields';
import FullNameFields from './FullNameFields';
import PhoneNumberFields from './PhoneNumberFields';
import AddressFields from './AddressFields';
import VerificationCodeFields from './VerificationCodeFields';

export const getUserNextStep = (currentStep: UserStepperKeys) =>
  UserStepperKeys[currentStep + 1] ? currentStep + 1 : currentStep;

export const getUserPreviousStep = (currentStep: UserStepperKeys) =>
  UserStepperKeys[currentStep - 1] ? currentStep - 1 : currentStep;

export const getUserFormFields = (
  currentStep: UserStepperKeys,
  formik: TFormik<IUserFormValues>,
  handleChangeResponseMessage: (message: IResponseMessage | null) => void,
  responseMessage?: IResponseMessage | null,
  isSignUpLoading?: boolean,
): JSX.Element | null => {
  switch (currentStep) {
    case UserStepperKeys.email:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserEmail}>
          <EmailFields
            formik={formik}
            responseMessage={responseMessage}
            handleChangeResponseMessage={handleChangeResponseMessage}
          />
        </HelmetPageTitle>
      );

    case UserStepperKeys.verificationCode:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserVerificationCode}>
          <VerificationCodeFields
            formik={formik}
            responseMessage={responseMessage}
            handleChangeResponseMessage={handleChangeResponseMessage}
          />
        </HelmetPageTitle>
      );

    case UserStepperKeys.password:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserCreatePassword}>
          <PasswordFields formik={formik} />
        </HelmetPageTitle>
      );

    case UserStepperKeys.fullName:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserFullName}>
          <FullNameFields formik={formik} />
        </HelmetPageTitle>
      );

    case UserStepperKeys.phoneNumber:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserPhoneNumber}>
          <PhoneNumberFields formik={formik} />
        </HelmetPageTitle>
      );

    case UserStepperKeys.address:
      return (
        <HelmetPageTitle title={MENU_TITLE.signUpUserAddress}>
          <AddressFields formik={formik} isSignUpLoading={isSignUpLoading} />
        </HelmetPageTitle>
      );

    default:
      return null;
  }
};
