export const SERIAL_NUMBER_CARDS_TITLE = {
  currency: 'Currency',
  currencyCode: 'Currency Code',
  amount: 'Amount',
  numberOfNotes: 'No. of Notes',
  note: 'Note',
  condition: 'Condition',
  series: 'Series',
  authenticity: 'Authenticity',
};

export const CURRENCY_OPTIONS: { [key: string]: string } = {
  IDR: 'Indonesian Rupiah',
  IQD: 'Iraqi Dinar',
  VND: 'Vietnam Dong',
};
