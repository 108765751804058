export const SHARE_MODAL_CONTENT = {
  TITLE: 'Share Your ownership record PDF',
  EMBED_CODE: 'Embed code',
  COPY_LINK_TITLE: 'Copy link to this PDF',
  COPY_LINK_BUTTON: 'Copy',
  SHARE_PDF: 'or share this PDF via',
  SHARE_BY_SMS: 'Share by text message',
  SHARE_BY_EMAIL: 'Share by Email',
  SHARE_BY_QR: 'Share by QR  code',
  EMBED_CODE_INFO:
    'To protect your record with password, please, switch the "Embed Code" toggle on. The password will be shared with a third party by email and by text message if you pick sharing by these options. If you choose sharing by link or by QR code, the password will be embedded and copied with them.',
};
