import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

export const EMPTY_STRING = '';
export const EMPTY_STRING_WITH_WHITESPACE = ' ';
export const COMMA_STRING = ',';
export const DASH_STRING = '-';
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const DOT_STRING = '.';

export const NULL_INDEX = 0;
export const LAST_INDEX = -1;
export const NULL_VALUE = 0;
export const INDEX_INCREMENT = 1;
export const NULL_TYPE_VALUE = null;

export const OBJECT_TYPE = 'object';

export const NUMBERS_REG_EXP = /[^\d]/g;
export const SUBSCRIPTIONS_SEARCH_INPUT_REGEX = /[^a-z0-9_]/gi;

export const DEFAULT_PHONE_COUNTRY_CODE: TPhoneNumberCountryCode = 'US';
export const DEFAULT_PHONE_FORMAT = '(###) ###-####';

export const NOT_AVAILABLE = 'N/A';

export const SUBSCRIPTION_SELECT_OPTIONS = [
  {
    value: 'month',
    label: '$9.99',
  },
  {
    value: 'year',
    label: '$99',
    discount: 'Save 20%',
  },
  {
    value: 'year2',
    label: '$149',
    discount: 'Save 25%',
  },
  {
    value: 'year3',
    label: '$199',
    discount: 'Save 30%',
  },
  {
    value: 'year4',
    label: '$249',
    discount: 'Save 35%',
  },
  {
    value: 'year5',
    label: '$299',
    discount: 'Save 40%',
  },
  {
    value: 'lifetime',
    label: '$500',
    discount: 'Save 50%',
  },
];
export const SUBSCRIPTION_DEFAULT_OPTION =
  SUBSCRIPTION_SELECT_OPTIONS[0]?.value;

export const PARAMS = {
  subscriptionId: 'subscriptionId',
};

export const DEFAULT_PAGE_VALUE = 1;

export const MS_IN_ONE_MINUTE = 60000;

export const SECOND_MS = 1000;
export const INITIAL_TIMER_VALUE = 0;
export const VERIFICATION_CODE_TIMER_DURATION_MS = 120000;
