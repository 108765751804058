import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const EnabledDateText = styled('span')({
  padding: '2px 6px',
  fontSize: '12px',
  borderRadius: '4px',
  color: COLORS.green900,
  backgroundColor: COLORS.green100,
});
