import { idrIcon, iqdIcon, vndIcon } from 'assets/vectors';
import { DASH_STRING } from 'constants/common';
import { CurrencyType } from 'types/currencies';

import { StyledImage } from './styled';

export const getCurrencyIcon = (currency: string) =>
  currency === CurrencyType.DINAR ? (
    <StyledImage src={iqdIcon} />
  ) : currency === CurrencyType.RUPIAH ? (
    <StyledImage src={idrIcon} />
  ) : currency === CurrencyType.DONG ? (
    <StyledImage src={vndIcon} />
  ) : (
    DASH_STRING
  );
