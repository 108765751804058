import { LockIcon, CheckSuccessIcon, LockSuccessIcon } from 'assets/vectors';

import { IVerificationBlock } from './VerificationBlock/types';

export const VERIFICATION_STEP_TEXT = {
  title: "We're verifying your dinar",
  subtitle: 'This takes less then 30 seconds.',
  warning: 'Please do not hit the back button',
};

export const VERIFICATION_BLOCKS: IVerificationBlock[] = [
  {
    index: 0,
    title: 'DinarVerify serial number',
    feature: 'Feature',
    completedFeature: 'IQD',
    icon: <LockIcon />,
    completedIcon: <LockSuccessIcon />,
  },
  {
    index: 1,
    title: 'Iraqi Dinar',
    feature: 'Feature',
    completedFeature: 'Verified',
    icon: <LockIcon />,
    completedIcon: <CheckSuccessIcon />,
  },
  {
    index: 2,
    title: 'Ownership',
    feature: 'Feature',
    completedFeature: 'Verified',
    icon: <LockIcon />,
    completedIcon: <CheckSuccessIcon />,
  },
  {
    index: 3,
    title: 'Dealer',
    feature: 'Feature',
    completedFeature: 'Verified',
    icon: <LockIcon />,
    completedIcon: <CheckSuccessIcon />,
  },
];

export const BLOCKS_COUNT = VERIFICATION_BLOCKS.length;
