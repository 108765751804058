import { FC } from 'react';
import { Table } from 'antd';

import { Loader } from 'components/Loader';
import NoDataMessage from 'components/NoDataMessage';

import { getColumnsConfig, TABLE_SCROLL_CONFIG } from './config';
import {
  DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
  REQUEST_FIELDS,
  NO_DATA_MESSAGE,
} from './constants';
import { ICurrencyTableProps } from './types';

const CurrencyTable: FC<ICurrencyTableProps> = ({
  page,
  currencyItems,
  totalCurrencies,
  isCurrenciesForRequestLoading,
  isCurrenciesForRequestFetching,
  allowPagination = true,
  handleChangePage,
}) => {
  const pagination = allowPagination && {
    hideOnSinglePage: true,
    current: page,
    total: totalCurrencies,
    pageSize: DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
    onChange: handleChangePage,
    showTitle: false,
  };

  const currencyTableContent = totalCurrencies ? (
    <Table
      columns={getColumnsConfig(page)}
      rowKey={REQUEST_FIELDS.SN}
      dataSource={currencyItems}
      loading={isCurrenciesForRequestFetching}
      pagination={pagination}
      scroll={TABLE_SCROLL_CONFIG}
    />
  ) : (
    <NoDataMessage message={NO_DATA_MESSAGE} />
  );

  return isCurrenciesForRequestLoading ? <Loader /> : currencyTableContent;
};

export default CurrencyTable;
