import React, { createContext, useContext, useState } from 'react';

import useUploadEncryptPdf from 'api/serialNumbers/useUploadEncryptPdf';
import { COPY_LINK_WITH_PWD_MESSAGE } from 'pages/SerialNumberDetails/constants';
import { EMPTY_STRING } from 'constants/common';

import {
  ISerialNumbersDetailsModalDialogs,
  ISerialNumbersDetailsModalProviderProps,
} from './types';

const DEFAULT_CONTEXT: ISerialNumbersDetailsModalDialogs = {
  isShareModalOpen: false,
  handleShareModalOpen: () => null,
  handleShareModalClose: () => null,
  isShareEmailModalOpen: false,
  handleShareEmailModalOpen: () => null,
  handleShareEmailModalClose: () => null,
  isShareSmsModalOpen: false,
  handleShareSmsModalOpen: () => null,
  handleShareSmsModalClose: () => null,
  isShareQRModalOpen: false,
  handleShareQRModalOpen: () => null,
  handleShareQRModalClose: () => null,
  uploadedPdf: undefined,
  isPdfUploading: false,
  pdfWithPassword: false,
  handleSetPdfWithPassword: () => null,
  handleCopyPdfLink: () => new Promise(() => null),
  pdfBytes: undefined,
};

const SerialNumbersDetailsModalContext = createContext(DEFAULT_CONTEXT);

export const SerialNumbersDetailsModalContextProvider: React.FC<
  ISerialNumbersDetailsModalProviderProps
> = ({ children, pdfBytes, serialNumberId }) => {
  const [isShareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [isShareEmailModalOpen, setShareEmailModalOpen] =
    useState<boolean>(false);
  const [isShareSmsModalOpen, setShareSmsModalOpen] = useState<boolean>(false);
  const [isShareQRModalOpen, setShareQRModalOpen] = useState<boolean>(false);
  const [pdfWithPassword, setPdfWithPassword] = useState<boolean>(false);

  const isEnabled = Boolean(isShareModalOpen && pdfBytes);

  const { data: uploadedPdf, isFetching: isPdfUploading } = useUploadEncryptPdf(
    {
      pdfBytes,
      pdfWithPassword,
      isEnabled,
      serialNumberId,
    },
  );

  const handleShareModalOpen = () => {
    setPdfWithPassword(false);
    setShareModalOpen(true);
  };
  const handleShareModalClose = () => setShareModalOpen(false);

  const handleShareEmailModalOpen = () => {
    setShareModalOpen(false);
    setShareEmailModalOpen(true);
  };
  const handleShareEmailModalClose = () => setShareEmailModalOpen(false);

  const handleShareSmsModalOpen = () => {
    setShareModalOpen(false);
    setShareSmsModalOpen(true);
  };
  const handleShareSmsModalClose = () => setShareSmsModalOpen(false);

  const handleShareQRModalOpen = () => {
    setShareModalOpen(false);
    setShareQRModalOpen(true);
  };
  const handleShareQRModalClose = () => setShareQRModalOpen(false);

  const handleSetPdfWithPassword = () => setPdfWithPassword((value) => !value);

  const handleCopyPdfLink = (copyQr = false) => {
    const linkToCopy = copyQr ? uploadedPdf?.qrCodeUrl : uploadedPdf?.url;

    const passwordNotice = pdfWithPassword
      ? `${COPY_LINK_WITH_PWD_MESSAGE} ${uploadedPdf?.password}`
      : EMPTY_STRING;

    return navigator.clipboard.writeText(`${linkToCopy} ${passwordNotice}`);
  };

  return (
    <SerialNumbersDetailsModalContext.Provider
      value={{
        pdfBytes,
        uploadedPdf,
        pdfWithPassword,
        isPdfUploading,
        isShareModalOpen,
        isShareQRModalOpen,
        isShareSmsModalOpen,
        isShareEmailModalOpen,
        handleCopyPdfLink,
        handleSetPdfWithPassword,
        handleShareModalOpen,
        handleShareModalClose,
        handleShareQRModalOpen,
        handleShareQRModalClose,
        handleShareSmsModalOpen,
        handleShareSmsModalClose,
        handleShareEmailModalOpen,
        handleShareEmailModalClose,
      }}
    >
      {children}
    </SerialNumbersDetailsModalContext.Provider>
  );
};

export const useSerialNumbersDetailsModalContext =
  (): ISerialNumbersDetailsModalDialogs =>
    useContext(SerialNumbersDetailsModalContext);

export default SerialNumbersDetailsModalContext;
