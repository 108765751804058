import { FC, BaseSyntheticEvent, useState, useEffect } from 'react';

import { EMPTY_STRING } from 'constants/common';

import SerialNumberInput from './SerialNumberInput';
import { ISerialNumberInputContainerProps } from './types';
import { formatSerialNumber } from './utils';

const SerialNumberInputContainer: FC<ISerialNumberInputContainerProps> = ({
  defaultValue = EMPTY_STRING,
  handleChangeFormikState,
  ...props
}) => {
  const [formattedValue, setFormattedValue] = useState<string>(EMPTY_STRING);

  useEffect(() => {
    const formatted: string = formatSerialNumber(defaultValue as string);

    setFormattedValue(formatted);
  }, [defaultValue]);

  const handleChangeValue = ({ target }: BaseSyntheticEvent) => {
    const formattedSerialNumber = formatSerialNumber(target.value);

    handleChangeFormikState(formattedSerialNumber);
    setFormattedValue(formattedSerialNumber);
  };

  return (
    <SerialNumberInput
      formattedValue={formattedValue}
      handleChangeValue={handleChangeValue}
      {...props}
    />
  );
};

export default SerialNumberInputContainer;
