import { useState } from 'react';

import { env } from 'config/env';
import { PDF_EXTENSION } from 'pages/SerialNumberDetails/utils/constants';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import useUploadEncryptPdf from 'api/serialNumbers/useUploadEncryptPdf';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import {
  NOTIFICATION_SUCCESS_MESSAGE,
  NOTIFICATION_ERROR_MESSAGE,
} from 'constants/messages';

import useScript from './useScript';
import { DROPBOX_SCRIPT } from '../constants';

const useDropbox = (serialNumberId: string) => {
  const isDropboxLoaded = useScript(DROPBOX_SCRIPT.SRC, {
    type: DROPBOX_SCRIPT.TYPE,
    id: DROPBOX_SCRIPT.ID,
    'data-app-key': env.REACT_APP_DROPBOX_APP_KEY,
  });

  const { pdfBytes } = useSerialNumbersDetailsModalContext();

  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  const {
    data: uploadedPdf,
    isFetching,
    refetch,
  } = useUploadEncryptPdf({
    pdfBytes,
    pdfWithPassword: false,
    isEnabled: false,
    serialNumberId,
  });

  const options = {
    success: () =>
      openNotificationWithIcon(
        NotificationType.success,
        NOTIFICATION_SUCCESS_MESSAGE.saveSerialNumberToDropbox,
      ),
    progress: (progress: boolean) => {
      setIsLoaded(progress);
    },
    error: () =>
      openNotificationWithIcon(
        NotificationType.error,
        NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToDropbox,
      ),
  };

  const upload = () => {
    const saveToDropBox = (url: string) => {
      window.Dropbox.save(url, serialNumberId + PDF_EXTENSION, options);
    };

    if (uploadedPdf?.url) {
      saveToDropBox(uploadedPdf?.url);
    } else {
      refetch()
        .then(({ data }) => data)
        .then((data) => saveToDropBox(data?.url as string))
        .catch(() =>
          openNotificationWithIcon(
            NotificationType.error,
            NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToDropbox,
          ),
        );
    }
  };

  return {
    isLoading: !isLoaded || !isDropboxLoaded || isFetching,
    upload,
  };
};

export default useDropbox;
