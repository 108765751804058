import { FC } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { IModalDialogProps } from './types';
import { Modal, ModalContentWrapper, ModalSubtitle } from './styled';

const ModalDialog: FC<IModalDialogProps> = ({
  isModalOpened,
  handleCloseModal,
  title,
  subtitle,
  children,
  ...props
}) => (
  <Modal
    title={title}
    visible={isModalOpened}
    centered
    onCancel={handleCloseModal}
    footer={null}
    closeIcon={<PlusOutlined />}
    {...props}
  >
    <ModalContentWrapper>
      {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
      {children}
    </ModalContentWrapper>
  </Modal>
);

export default ModalDialog;
