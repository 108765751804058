import { FC } from 'react';

import { useSignOut } from 'api/auth';
import { EMPTY_ARRAY } from 'constants/common';
import { MENU_CONFIG } from 'constants/menu';
import { Enviroments } from 'types/common';
import { env } from 'config/env';

import SideMenu from './SideMenu';
import { ISideMenuContainerProps } from './types';
import { ANIMATION_DURATION_IN_MS } from './constants';

export const SideMenuContainer: FC<ISideMenuContainerProps> = ({
  userRole,
  isCollapsed,
  userFullName,
  userAvatar,
  isMobileMenuOpened,
  toggleIsCollapsed,
  toggleIsMobileMenuOpened,
}) => {
  const { mutate: signOutMutate } = useSignOut();

  const menuItems =
    MENU_CONFIG[env.REACT_APP_NODE_ENV as Enviroments][userRole] ?? EMPTY_ARRAY;

  const handleClickCollapse = () => {
    toggleIsCollapsed();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, ANIMATION_DURATION_IN_MS);
  };

  const handleSignOut = () => {
    localStorage.setItem('signOut', Date.now().toString());

    signOutMutate();
  };

  return (
    <SideMenu
      menuItems={menuItems}
      isCollapsed={isCollapsed}
      isMobileMenuOpened={isMobileMenuOpened}
      toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
      handleClickSignOut={handleSignOut}
      handleClickCollapse={handleClickCollapse}
      userFullName={userFullName}
      userAvatar={userAvatar}
    />
  );
};

export default SideMenuContainer;
