import { FC } from 'react';

import {
  EXPANDED_MENU_WIDTH,
  COLLAPSED_MENU_WIDTH,
  MENU_TITLE,
} from 'constants/menu';
import Tooltip from 'components/Tooltip';
import { LogoIcon, CollapsedLogoIcon, SignOutIcon } from 'assets/vectors/menu';

import NavigationMenu from './NavigationMenu';
import CollapseButton from './CollapseButton';
import {
  StyledSider,
  OutSideClickHandler,
  SiderContentWrapper,
  LogoWrapper,
  SignOutButton,
  StyledSignOutIcon,
  SignOutText,
} from './styled';
import { ISideMenuProps } from './types';
import SettingsSection from './SettingsSection';

export const SideMenu: FC<ISideMenuProps> = ({
  menuItems,
  isCollapsed,
  userFullName,
  userAvatar,
  isMobileMenuOpened,
  toggleIsMobileMenuOpened,
  handleClickSignOut,
  handleClickCollapse,
}) => (
  <>
    <StyledSider
      collapsed={isCollapsed}
      collapsedWidth={COLLAPSED_MENU_WIDTH}
      width={EXPANDED_MENU_WIDTH}
      isMobileMenuOpened={isMobileMenuOpened}
      isCollapsed={isCollapsed}
    >
      <CollapseButton
        isCollapsed={isCollapsed}
        handleClickCollapse={handleClickCollapse}
      />
      <SiderContentWrapper>
        <div>
          <LogoWrapper>
            {isCollapsed ? <CollapsedLogoIcon /> : <LogoIcon />}
          </LogoWrapper>
          <NavigationMenu
            menuItems={menuItems}
            isCollapsed={isCollapsed}
            toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
          />
          <SettingsSection
            userFullName={userFullName}
            userAvatar={userAvatar}
            isCollapsed={isCollapsed}
            handleClickSignOut={handleClickSignOut}
            toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
          />
        </div>
        <Tooltip placement='right' title={isCollapsed && MENU_TITLE.signOut}>
          <SignOutButton onClick={handleClickSignOut}>
            <StyledSignOutIcon
              component={SignOutIcon}
              isCollapsed={isCollapsed}
            />
            <SignOutText isCollapsed={isCollapsed}>
              {MENU_TITLE.signOut}
            </SignOutText>
          </SignOutButton>
        </Tooltip>
      </SiderContentWrapper>
    </StyledSider>
    <OutSideClickHandler
      isMobileMenuOpened={isMobileMenuOpened}
      onClick={toggleIsMobileMenuOpened}
    />
  </>
);

export default SideMenu;
