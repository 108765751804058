import { DISABLE_EXPORT_SHADOW_CLASSNAME } from './constants';

const disableElementShadow = (element: Element): void => {
  (element as HTMLElement).style.boxShadow = 'unset';
};

const removeShadowsArtifacts = (virtualDocument: Document): void => {
  const shadowedItems = virtualDocument.querySelectorAll(
    `.${DISABLE_EXPORT_SHADOW_CLASSNAME}`,
  );
  shadowedItems.forEach(disableElementShadow);
};

const removeDigitsTabs = (virtualDocument: Document): void => {
  virtualDocument.body.style.fontVariant = 'normal';
  virtualDocument.body.style.fontFeatureSettings = 'normal';
};

export const configureStyles = (virtualDocument: Document): void => {
  removeShadowsArtifacts(virtualDocument);
  removeDigitsTabs(virtualDocument);
};
