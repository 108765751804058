import styled from 'styled-components';
import { Badge } from 'antd';

export const DotBadge = styled(Badge)({
  display: 'flex',
  alignItems: 'center',

  '> .ant-badge-status-dot': {
    width: '10px',
    height: '10px',
  },
});
