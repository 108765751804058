import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { PhoneNumberInput } from 'components/Inputs';
import { FormFieldWrapper, StyledButton } from 'pages/SignUp/styled';

import { UserFormField } from '../types';
import { USER_FORM_TEXT } from '../constants';
import { IPhoneNumberFieldsProps } from './types';

const PhoneNumberFields: FC<IPhoneNumberFieldsProps> = ({
  formik: { values, errors, getFieldProps },
  handleChangePhoneNumberValue,
  handleChangeCountryCodeValue,
}) => {
  const isDisabled =
    !values[UserFormField.PHONE_NUMBER] ||
    Boolean(errors[UserFormField.PHONE_NUMBER]);

  return (
    <>
      <FormFieldWrapper>
        <PhoneNumberInput
          phoneNumberInputProps={{
            id: UserFormField.PHONE_NUMBER,
            labelText: USER_FORM_TEXT.phoneNumberLabel,
            errorMessage: errors.phoneNumber,
            handleChangePhoneNumberFormik: handleChangePhoneNumberValue,
            ...getFieldProps(UserFormField.PHONE_NUMBER),
          }}
          countrySelectProps={{
            id: UserFormField.COUNTRY_CODE,
            errorMessage: errors.countryCode,
            handleChangeCountryCodeFormik: handleChangeCountryCodeValue,
            ...getFieldProps(UserFormField.COUNTRY_CODE),
          }}
        />
      </FormFieldWrapper>
      <StyledButton
        htmlType='submit'
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        disabled={isDisabled}
      >
        {USER_FORM_TEXT.button}
      </StyledButton>
    </>
  );
};
export default PhoneNumberFields;
