import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { UnauthorizedPageWrapper, ProgressBarLayout } from 'components/Layouts';
import {
  IProgressBarConfig,
  ProgressBarType,
} from 'components/Layouts/ProgressBarLayout';

import { ISignUpProps } from './types';
import { NOT_VERIFIED_PROGRESS_WARNING, NOT_VERIFIED_STEP } from './constants';
import { getProgressPercentage } from './utils';

const SignUp: FC<ISignUpProps> = ({ verificationStep, userStep, children }) => {
  const isNotVerifiedStep = verificationStep === NOT_VERIFIED_STEP;

  const progress = getProgressPercentage(verificationStep + userStep);

  const progressBarConfig: IProgressBarConfig = isNotVerifiedStep
    ? {
        progress: 100,
        text: NOT_VERIFIED_PROGRESS_WARNING,
        type: ProgressBarType.warning,
      }
    : { progress };

  return (
    <HelmetPageTitle title={MENU_TITLE.signUp}>
      <ProgressBarLayout {...progressBarConfig}>
        <UnauthorizedPageWrapper>{children}</UnauthorizedPageWrapper>
      </ProgressBarLayout>
    </HelmetPageTitle>
  );
};

export default SignUp;
