import { FC } from 'react';

import { RoutesList } from 'components/Routes';

import { LayoutWrapper, Main } from './styled';
import Header from './Header';

const UnauthorizedLayout: FC<{ isDesktopVersion: boolean }> = ({
  isDesktopVersion,
}) => (
  <LayoutWrapper>
    <Header isDesktopVersion={isDesktopVersion} />
    <Main isDesktopVersion={isDesktopVersion}>
      <RoutesList />
    </Main>
  </LayoutWrapper>
);

export default UnauthorizedLayout;
