import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { STATUS_CODES } from 'constants/errorCodes';
import { APP_ROUTE } from 'constants/appRoutes';
import { MESSAGES } from 'constants/messages';
import { useTriggerNotFound } from 'hooks';
import { transformRoute } from 'utils/routing';
import { TAxiosRequestError } from 'api/types';
import { ISerialNumberWithLatestPayments } from 'types/serialNumber';
import { NotificationType } from 'types/notifications';
import { EMPTY_STRING } from 'constants/common';
import { openNotificationWithIcon } from 'utils/showNotification';

const useGetSerialNumberDetails = (
  serialNumberId: string,
): UseQueryResult<ISerialNumberWithLatestPayments, TAxiosRequestError> => {
  const triggerNotFound = useTriggerNotFound();
  const navigateTo = useNavigate();

  const handleGetSerialNumberDetailsError = (error: TAxiosRequestError) => {
    if (error.response?.data?.statusCode === STATUS_CODES.NOT_FOUND) {
      triggerNotFound(MESSAGES.serialNumberDeletedOrNotExist);
    } else {
      navigateTo(APP_ROUTE.serialNumbers);
      openNotificationWithIcon(
        NotificationType.error,
        error.response?.data.message || EMPTY_STRING,
      );
    }
  };

  return useQuery(
    [QUERY_KEYS.SERIAL_NUMBER_DETAILS, serialNumberId],
    async () => {
      const { data: serialNumberDetailsResponse } = await apiClient.get(
        transformRoute(ApiRoutes.SERIAL_NUMBER_DETAILS, serialNumberId),
      );

      return serialNumberDetailsResponse;
    },
    {
      cacheTime: 1,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: handleGetSerialNumberDetailsError,
    },
  );
};

export default useGetSerialNumberDetails;
