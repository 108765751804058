import { ReactNode } from 'react';

import { UserStepperKeys } from './UserForm/types';

export enum VerificationStepperKeys {
  serialNumber,
  verification,
  entry,
  userSteps,
  notVerified,
}

export interface ISignUpProps {
  verificationStep: VerificationStepperKeys;
  userStep: UserStepperKeys;
  children: ReactNode;
}
