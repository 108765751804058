import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  marginRight: '34px',
});

export const Text = styled('span')({
  fontSize: '16px',
});
