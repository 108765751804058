import { FC } from 'react';
import { Link } from 'react-router-dom';

import { HelpIcon, SignOutIcon } from 'assets/vectors/menu';
import { APP_ROUTE } from 'constants/appRoutes';
import UserAvatar from 'components/Layouts/AuthorizedLayout/components/Header/components/UserAvatar';
import { LAYOUT_FOOTER_TEXT } from 'components/Layouts/AuthorizedLayout/components/Footer/constants';

import {
  HelpText,
  HelpWrapper,
  ProfileText,
  ProfileTextWrapper,
  ProfileWrapper,
  SettingsWrapper,
  SignOut,
  StyledDivider,
  TitleText,
  UserName,
  StyledSignOutIcon,
  InnerWrapper,
  FooterText,
  StyledSignOutButton,
} from './styled';
import { SETTINGS_SECTION_TEXT, USER_AVATAR_SIZE } from './constants';
import { ISettingsSection } from './types';

const SettingsSection: FC<ISettingsSection> = ({
  userFullName,
  userAvatar,
  isCollapsed,
  handleClickSignOut,
  toggleIsMobileMenuOpened,
}) => (
  <SettingsWrapper>
    <StyledDivider />
    <InnerWrapper>
      <TitleText>{SETTINGS_SECTION_TEXT.TITLE}</TitleText>
      <Link to={APP_ROUTE.profile}>
        <ProfileWrapper onClick={toggleIsMobileMenuOpened}>
          <UserAvatar
            userFullName={userFullName}
            userAvatar={userAvatar}
            avatarSize={USER_AVATAR_SIZE}
          />
          <ProfileTextWrapper>
            <ProfileText>{SETTINGS_SECTION_TEXT.PROFILE}</ProfileText>
            <UserName>{userFullName}</UserName>
          </ProfileTextWrapper>
        </ProfileWrapper>
      </Link>
      <HelpWrapper>
        <HelpIcon />
        <HelpText>{SETTINGS_SECTION_TEXT.HELP}</HelpText>
      </HelpWrapper>
      <StyledSignOutButton onClick={handleClickSignOut}>
        <StyledSignOutIcon component={SignOutIcon} isCollapsed={isCollapsed} />
        <SignOut isCollapsed={isCollapsed}>
          {SETTINGS_SECTION_TEXT.SIGN_OUT}
        </SignOut>
      </StyledSignOutButton>
    </InnerWrapper>
    <FooterText>{LAYOUT_FOOTER_TEXT.RIGHTS_MESSAGE}</FooterText>
  </SettingsWrapper>
);

export default SettingsSection;
