import { FC, useState, useEffect, useRef, useCallback } from 'react';

import { SECOND_MS } from 'constants/common';

import { ANIMATION_VALUES, BLOCK_VALUES, FIRST_BLOCK_INDEX } from './constants';
import { IVerificationBlockContainerProps } from './types';
import { asyncTimer } from './utils';
import VerificationBlock from './VerificationBlock';

const VerificationBlockContainer: FC<IVerificationBlockContainerProps> = ({
  block,
  serialNumber,
}) => {
  const blockRef = useRef<HTMLInputElement>(null);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const { index } = block;
  const isFirstBlock = index === FIRST_BLOCK_INDEX;

  const changeTopPosition = (iteration: number) => () => {
    if (blockRef.current) {
      const multiplier = isFirstBlock ? 0 : index - iteration;
      blockRef.current.style.top = `${
        BLOCK_VALUES.offsetHeight * multiplier
      }px`;
    }
  };

  const moveBlockUp = useCallback(async () => {
    for (let i = 1, iterations = index + 1; i <= iterations; i++) {
      const delay =
        i === 1
          ? ANIMATION_VALUES.blockAnimationDuration
          : ANIMATION_VALUES.progressDuration;

      await asyncTimer(changeTopPosition(i), delay * SECOND_MS);
    }
  }, [isFirstBlock, index, changeTopPosition]);

  useEffect(() => {
    moveBlockUp();
  }, [moveBlockUp]);

  useEffect(() => {
    const completeDelayTimer = setTimeout(
      () => setIsCompleted(true),
      ANIMATION_VALUES.progressDuration * (index + 1) * SECOND_MS,
    );

    return () => {
      clearTimeout(completeDelayTimer);
    };
  }, []);

  return (
    <VerificationBlock
      blockRef={blockRef}
      block={block}
      serialNumber={serialNumber}
      isCompleted={isCompleted}
    />
  );
};

export default VerificationBlockContainer;
