export const asyncTimer = async (
  callback: CallableFunction,
  delay: number,
): Promise<void> =>
  new Promise<void>((resolve) =>
    setTimeout(() => {
      callback();
      resolve();
    }, delay),
  );
