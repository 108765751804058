import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

export const EntryStepWrapper = styled('div')({
  width: '100%',
  textAlign: 'center',
  '& h1': {
    color: COLORS.green700,
  },
});

export const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '24px',
});

export const IconWrapper = styled('div')({
  width: '80px',
  height: '80px',
  margin: '4px auto',
});
