import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const EmailInputLabel = styled('p')({
  fontWeight: 500,
  fontSize: '18px',
  color: COLORS.gray600,
  margin: '20px 0',
  paddingRight: '25px',
});
