import { FC } from 'react';

import {
  NoDataWrapper,
  NoNotificationsDescription,
  NoNotification,
} from './styled';
import { INoNotificationsMessageProps } from './types';
import { NO_NOTIFICATIONS_TEXT } from './constants';

const NoNotificationsMessage: FC<INoNotificationsMessageProps> = ({
  type = 'no-any',
}) => {
  const messagePayload = NO_NOTIFICATIONS_TEXT[type];

  return (
    <NoDataWrapper>
      <NoNotification />
      <h2>{messagePayload.title}</h2>
      <NoNotificationsDescription>
        {messagePayload.description}
      </NoNotificationsDescription>
    </NoDataWrapper>
  );
};

export default NoNotificationsMessage;
