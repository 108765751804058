import { FC } from 'react';
import { isEmpty } from 'lodash';

import {
  DefaultInput,
  PasswordInput,
  MAX_EMAIL_LENGTH,
} from 'components/Inputs';
import { APP_ROUTE } from 'constants/appRoutes';
import { ALERT_TYPE } from 'components/Alert';
import { UNAUTHORIZED_LAYOUT_TEXT } from 'components/Layouts/UnauthorizedLayout/constants';

import {
  StyledForm,
  HelpLink,
  SignInButton,
  Checkbox,
  FieldWrapper,
  StyledAlert,
  StyledText,
  StyledLink,
} from './styled';
import { ISignInFormProps, SignInFormField } from './types';
import { SIGN_IN_FORM_TEXT } from './constants';

const SignInForm: FC<ISignInFormProps> = ({
  formik,
  isAuthenticateLoading,
  errorMessage,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        {isErrorAlertShown && (
          <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
        )}
        <FieldWrapper>
          <DefaultInput
            labelText={SIGN_IN_FORM_TEXT.EMAIL_LABEL}
            errorMessage={errors[SignInFormField.EMAIL]}
            maxLength={MAX_EMAIL_LENGTH}
            {...getFieldProps(SignInFormField.EMAIL)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <PasswordInput
            errorMessage={errors[SignInFormField.PASSWORD]}
            labelText={SIGN_IN_FORM_TEXT.PASSWORD_LABEL}
            {...getFieldProps(SignInFormField.PASSWORD)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Checkbox
            {...getFieldProps(SignInFormField.REMEMBER)}
            checked={values[SignInFormField.REMEMBER]}
          >
            {SIGN_IN_FORM_TEXT.REMEMBER_ME}
          </Checkbox>
        </FieldWrapper>
        <SignInButton
          htmlType='submit'
          size='large'
          loading={isAuthenticateLoading}
        >
          {SIGN_IN_FORM_TEXT.SIGN_IN_BUTTON_TEXT}
        </SignInButton>
        <HelpLink to={APP_ROUTE.signInHelp}>
          {SIGN_IN_FORM_TEXT.HELP_LINK}
        </HelpLink>
      </StyledForm>
      <StyledText>
        {UNAUTHORIZED_LAYOUT_TEXT.DONT_HAVE_ACCOUNT}&nbsp;
        <StyledLink to={APP_ROUTE.signUp}>
          {UNAUTHORIZED_LAYOUT_TEXT.SIGN_UP}
        </StyledLink>
      </StyledText>
    </>
  );
};

export default SignInForm;
