import { IInformContent } from 'components/InformPopover/types';

export const CURRENCY_DETAILS_TEXT = {
  pageTitle: 'Currency Details',
  headerTitle: 'Serial Number Details',
};

export const CURRENCIES_INFORM_CONTENT: IInformContent = {
  title: 'Banknotes images',
  content:
    'To view the banknote images, click on its previews in the following columns: Front & Back, Ultraviolet, Watermark.\n\nPlease, note! Because of federal laws we are allowed to show the banknote image, but not the serial number. So the serial number on the notes will be blurred due to federal regulations.',
};
