import { FC } from 'react';

import Tooltip from 'components/Tooltip';

import { List, StyledNavLink, StyledMenuIcon, MenuTitleText } from './styled';
import { TNavigationMenuProps } from '../types';

const NavigationMenu: FC<TNavigationMenuProps> = ({
  menuItems,
  isCollapsed,
  toggleIsMobileMenuOpened,
}) => (
  <nav>
    <List>
      {menuItems.map(({ pathTo, icon, menuTitle }) => (
        <Tooltip
          key={menuTitle}
          placement='right'
          title={isCollapsed && menuTitle}
        >
          <li onClick={toggleIsMobileMenuOpened}>
            <StyledNavLink to={pathTo}>
              <StyledMenuIcon component={icon} isCollapsed={isCollapsed} />
              <MenuTitleText isCollapsed={isCollapsed}>
                {menuTitle}
              </MenuTitleText>
            </StyledNavLink>
          </li>
        </Tooltip>
      ))}
    </List>
  </nav>
);

export default NavigationMenu;
