import { FC, memo } from 'react';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput } from 'components/Inputs';

import { EmailInputLabel } from './styled';
import { IShareEmailModalProps, ShareEmailFormField } from './types';
import { SHARE_EMAIL_FORM_TEXT, SHARE_EMAIL_MODAL_CONTENT } from './constants';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import {
  CancelButton,
  ShareActionsWrapper,
  ShareButton,
  SharedSubtitle,
  SharePdfModal,
} from '../styled';

const ShareEmailModal: FC<IShareEmailModalProps> = ({
  isModalOpened,
  handleCloseModal,
  isLoading,
  formik,
}) => {
  const { errors, getFieldProps, handleSubmit } = formik;

  return (
    <SharePdfModal
      destroyOnClose
      title={SHARE_EMAIL_MODAL_CONTENT.TITLE}
      isModalOpened={isModalOpened}
      handleCloseModal={handleCloseModal}
    >
      <form onSubmit={handleSubmit}>
        <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
        <EmailInputLabel>
          {SHARE_EMAIL_MODAL_CONTENT.EMAIL_INPUT_LABEL}
        </EmailInputLabel>
        <DefaultInput
          id={ShareEmailFormField.EMAIL}
          labelText={SHARE_EMAIL_FORM_TEXT.EMAIL_LABEL}
          errorMessage={errors.email}
          {...getFieldProps(ShareEmailFormField.EMAIL)}
        />
        <ShareActionsWrapper>
          <CancelButton type='ghost' onClick={handleCloseModal}>
            {SHARE_EMAIL_MODAL_CONTENT.CANCEL_LABEL}
          </CancelButton>
          <ShareButton
            htmlType='submit'
            id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
            loading={isLoading}
            type='primary'
          >
            {SHARE_EMAIL_MODAL_CONTENT.SHARE_LABEL}
          </ShareButton>
        </ShareActionsWrapper>
      </form>
    </SharePdfModal>
  );
};

export default memo(ShareEmailModal);
