import { object, string } from 'yup';

import { MIN_PHONE_NUMBER_LENGTH } from 'constants/validations';
import { formatToNumbersOnly } from 'utils/format';

import { ShareSmsFormField } from './types';
import { VALIDATION_ERROR_MESSAGES } from './constants';

export const shareSmsValidationSchema = object().shape({
  [ShareSmsFormField.PHONE_NUMBER]: string()
    .required(VALIDATION_ERROR_MESSAGES.PHONE_FIELD_REQUIRED)
    .transform(formatToNumbersOnly)
    .min(
      MIN_PHONE_NUMBER_LENGTH,
      VALIDATION_ERROR_MESSAGES.PHONE_NUMBER_INVALID,
    ),
  [ShareSmsFormField.COUNTRY_CODE]: string()
    .nullable()
    .required(VALIDATION_ERROR_MESSAGES.COUNTRY_CODE_FIELD_REQUIRED),
});
