export const API_BASE = '/api';

export enum ApiRoutes {
  SIGN_UP = '/auth/sign-up',
  SIGN_IN = '/auth/sign-in/:userType',
  SIGN_OUT = '/auth/sign-out',
  USER_PROFILE = '/auth/user-profile',
  UPDATE_CUSTOMER_PROFILE = '/customers/update-customer-profile',
  CUSTOMER_DETAILS = '/auth/customer-details',
  FORGOT_PASSWORD = 'auth/forgot-password/:userType',
  RESET_PASSWORD = '/auth/reset-password',
  DEALERS = '/dealers',
  GET_DEALERS_SELECT_LIST = '/dealers/dealers-select-list',
  CURRENCY_REQUESTS = '/requests',
  MERGE_REQUESTS = '/requests/merge',
  CURRENCIES_FOR_REQUEST = '/requests/:id/currencies',
  SYNC_CURRENCY_REQUESTS = '/requests/sync',
  DELETE_CURRENCY_REQUEST = '/requests/:id',
  GET_REQUEST_DETAILS = '/requests/:id',
  GET_REQUEST_INVOICE = '/requests/:id/invoice',
  GENERATE_EXPORT_PDF = '/serial-numbers/generate-pdf/:id',
  UPDATE_REQUEST_DETAILS = '/requests/:id/details',
  RESOURCES_DENOMINATIONS = '/resources/denominations',
  RESOURCES_SERIES = '/resources/series',
  GENERATE_CERTIFICATES = '/certificates/generate-certificates',
  CERTIFICATES = '/certificates',
  DELETE_CERTIFICATE = '/certificates/:id',
  SERIAL_NUMBERS = '/serial-numbers',
  SERIAL_NUMBER_DETAILS = '/serial-numbers/:id',
  LINK_SERIAL_NUMBER = '/serial-numbers/link',
  GET_SERIAL_NUMBER_STATUS = '/serial-numbers/:id/status',
  SERIAL_NUMBER_CERTIFICATE = '/serial-numbers/:id/certificate',
  SERIAL_NUMBER_UPLOAD_PDF = '/files/upload-encrypt',
  UPDATE_SUBSCRIPTION = '/subscriptions/:subscriptionId',
  GET_SUBSCRIPTION_PLANS = '/subscriptions/plans',
  GET_SUBSCRIPTION_CHECKOUT = '/subscriptions/checkout',
  GET_SUBSCRIPTIONS = '/subscriptions',
  GET_INVOICES_LIST = '/subscriptions/payments',
  GET_INVOICE_URL = '/subscriptions/invoice/:id',
  GET_PORTAL_SESSION = '/subscriptions/portal',
  ATTACH_SERIAL_NUMBER = '/customers/:id/serial-number',
  GET_NOTIFICATIONS = '/notifications',
  READ_NOTIFICATIONS = '/notifications/read',
  READ_NOTIFICATION_BY_ID = '/notifications/read/:id',
  DELETE_NOTIFICATIONS = '/notifications/delete',
  VERIFY_SERIAL_NUMBER = '/serial-numbers/verify',
  SHARE_SERIAL_NUMBER = '/serial-numbers/share',
  SEND_CUSTOMER_VERIFICATION_CODE = '/customers/send-customer-code',
  VERIFY_CODE = '/auth/verify-code',
  SEND_VERIFICATION_CODE = '/auth/send-verification-code/:userType',
}
