import { FC } from 'react';

import { DefaultInput } from 'components/Inputs';

import { SerialNumberWrapper } from './styled';
import { ISerialNumberInputProps } from './types';

const SerialNumberInput: FC<ISerialNumberInputProps> = ({
  formattedValue,
  handleChangeValue,
  ...props
}) => (
  <SerialNumberWrapper>
    <DefaultInput
      {...props}
      value={formattedValue}
      onChange={handleChangeValue}
    />
  </SerialNumberWrapper>
);

export default SerialNumberInput;
