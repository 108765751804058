import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { TAxiosRequestError } from 'api/types';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/routing';
import { ISerialNumberStatus } from 'types/serialNumber';
import { QUERY_KEYS } from 'constants/queryKeys';
import { STATUS_CODES } from 'constants/errorCodes';
import { MESSAGES } from 'constants/messages';
import { useTriggerNotFound } from 'hooks';

const useGetSerialNumberStatus = (
  serialNumberId: string,
  onSuccess: (status: ISerialNumberStatus) => void,
): UseQueryResult<ISerialNumberStatus, TAxiosRequestError> => {
  const triggerNotFound = useTriggerNotFound();

  const handleSerialNumberStatusError = (error: TAxiosRequestError) => {
    if (error?.code !== STATUS_CODES.ERR_NETWORK_CODE) {
      triggerNotFound(MESSAGES.serialNumberDeletedOrNotExist);
    }
  };

  return useQuery(
    [QUERY_KEYS.SERIAL_NUMBER_STATUS, serialNumberId],
    async () => {
      const { data: serialNumberStatus } = await apiClient.get(
        transformRoute(ApiRoutes.GET_SERIAL_NUMBER_STATUS, serialNumberId),
      );

      return serialNumberStatus;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: handleSerialNumberStatusError,
    },
  );
};

export default useGetSerialNumberStatus;
