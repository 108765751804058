import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';

import { formatModalStepNumber } from './utils';
import {
  LearnMoreModalWrapper,
  LearnMoreModalTitle,
  LearnMoreModalBoldText,
  LearnMoreModalText,
  LearnMoreModalSubtitle,
  LearnMoreModalStepWrapper,
  LearnMoreModalStepNumber,
  LearnMoreModalFooterWrapper,
  LearnMoreModalButton,
} from './styled';
import { ILearnMoreModalProps } from './types';
import { ENTRY_STEP_MODAL_CONTENT, ENTRY_STEP_MODAL_WIDTH } from './constants';

const EntryStepModal: FC<ILearnMoreModalProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <ModalDialog
    width={ENTRY_STEP_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <LearnMoreModalWrapper>
      <LearnMoreModalTitle>
        {ENTRY_STEP_MODAL_CONTENT.title}
      </LearnMoreModalTitle>
      <LearnMoreModalBoldText>
        {ENTRY_STEP_MODAL_CONTENT.subtitle}
      </LearnMoreModalBoldText>
      <LearnMoreModalText>{ENTRY_STEP_MODAL_CONTENT.text}</LearnMoreModalText>
      <LearnMoreModalBoldText>
        {ENTRY_STEP_MODAL_CONTENT.tip}
      </LearnMoreModalBoldText>
      <LearnMoreModalSubtitle>
        {ENTRY_STEP_MODAL_CONTENT.stepsTitle}
      </LearnMoreModalSubtitle>
      {ENTRY_STEP_MODAL_CONTENT.steps.map((step, index) => (
        <LearnMoreModalStepWrapper key={index}>
          <LearnMoreModalStepNumber>
            {formatModalStepNumber(index + 1)}
          </LearnMoreModalStepNumber>
          <div>
            <LearnMoreModalBoldText>{step.title}</LearnMoreModalBoldText>
            <LearnMoreModalText>{step.text}</LearnMoreModalText>
          </div>
        </LearnMoreModalStepWrapper>
      ))}
      <LearnMoreModalFooterWrapper>
        <LearnMoreModalSubtitle>
          {ENTRY_STEP_MODAL_CONTENT.footerTitle}
        </LearnMoreModalSubtitle>
        <LearnMoreModalButton>
          {ENTRY_STEP_MODAL_CONTENT.footerButtonText}
        </LearnMoreModalButton>
      </LearnMoreModalFooterWrapper>
    </LearnMoreModalWrapper>
  </ModalDialog>
);

export default EntryStepModal;
