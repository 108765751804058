import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';
import { LAYOUT_FOOTER_HEIGHT } from 'components/Layouts/AuthorizedLayout/constants';

export const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '150px',
  padding: '24px 22px',
  margin: '32px 0',
  fontWeight: 500,
});

export const FormFieldWrapper = styled('div')({
  marginTop: '20px',
  width: '200px',
});

export const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  margin: '0 20px 20px 0',
  border: `1px solid ${COLORS.gray300}`,
});

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${LAYOUT_FOOTER_HEIGHT})`,
});

export const SerialNumberTitle = styled('p')({
  color: COLORS.gray700,
});

export const SerialNumber = styled('p')({
  color: COLORS.gray900,
});

export const StyledForm = styled('form')({
  display: 'flex',
});
