import { FC } from 'react';

import { formatDateFromNow } from 'utils/format/datetime';

import { INotificationProps } from './types';
import {
  Details,
  EmailIconWrapper,
  NotificationDate,
  NotificationDescription,
  NotificationDetails,
  NotificationTitle,
  NotificationTitleWrapper,
  NotificationWrapper,
} from './styled';
import { ReadNotificationButton } from './components/ReadNotificationButton';

const Notification: FC<INotificationProps> = ({ notification, ...props }) => {
  const { isRead, title, description, createdAt } = notification;

  return (
    <NotificationWrapper>
      <div>
        <EmailIconWrapper />
      </div>
      <Details>
        <NotificationDetails>
          <NotificationTitleWrapper>
            <NotificationTitle isRead={isRead}>{title}</NotificationTitle>
            <ReadNotificationButton notification={notification} {...props} />
          </NotificationTitleWrapper>
          <NotificationDescription>{description}</NotificationDescription>
          <NotificationDate>{formatDateFromNow(createdAt)}</NotificationDate>
        </NotificationDetails>
      </Details>
    </NotificationWrapper>
  );
};

export default Notification;
