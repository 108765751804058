import { FC } from 'react';

import { DATE_WITH_SLASHES_FORMAT } from 'constants/datetime';
import { formatDateString } from 'utils/format';

import { ENABLED_STATE_PREFIX_TEXT } from './constants';
import { EnabledDateText } from './styled';
import { TEnabledDateProps } from './types';

const EnabledDate: FC<TEnabledDateProps> = ({ date }) => {
  const enabledText = `${ENABLED_STATE_PREFIX_TEXT} ${formatDateString(
    date,
    DATE_WITH_SLASHES_FORMAT,
  )}`;

  return <EnabledDateText>{enabledText}</EnabledDateText>;
};

export default EnabledDate;
