import { TFormikConfig } from 'types/formik';
import { ISerialNumber } from 'types/serialNumber';

export interface IShareEmailModalContainerProps {
  serialNumber: ISerialNumber;
}

export interface IShareEmailModalProps {
  formik: TFormikConfig<IShareEmailFormValues>;
  isModalOpened: boolean;
  isLoading: boolean;
  handleCloseModal: () => void;
}

export enum ShareEmailFormField {
  EMAIL = 'email',
}

export interface IShareEmailFormValues {
  [ShareEmailFormField.EMAIL]: string;
}
