import { FC } from 'react';

import { ContentText } from './styled';
import { IInformPopoverContentProps } from './types';

const InformPopoverContent: FC<IInformPopoverContentProps> = ({ content }) => (
  <ContentText>{content}</ContentText>
);

export default InformPopoverContent;
