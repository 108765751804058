import { TNoNotificationsMessageType } from './types';

type TNoNotificationsMessagePayload = {
  title: string;
  description?: string;
};

export const NO_NOTIFICATIONS_TEXT: Record<
  TNoNotificationsMessageType,
  TNoNotificationsMessagePayload
> = {
  'no-unread': {
    title: 'You’ve read all your notifications',
    description: '',
  },
  'no-any': {
    title: 'No notifications yet',
    description: "Don't worry, there will be a notification for you later",
  },
};
