import { FC } from 'react';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { PhoneNumberInput } from 'components/Inputs';

import { SmsInputLabel } from './styled';
import { IShareSmsModalProps, ShareSmsFormField } from './types';
import { SHARE_EMAIL_FORM_TEXT, SHARE_EMAIL_MODAL_CONTENT } from './constants';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import {
  CancelButton,
  ShareActionsWrapper,
  ShareButton,
  SharedSubtitle,
  SharePdfModal,
} from '../styled';

const ShareSmsModal: FC<IShareSmsModalProps> = ({
  formik,
  isModalOpened,
  handleCloseModal,
  handleChangePhoneNumberValue,
  handleChangeCountryCodeValue,
}) => {
  const { errors, getFieldProps, handleSubmit } = formik;

  return (
    <SharePdfModal
      destroyOnClose
      title={SHARE_EMAIL_MODAL_CONTENT.TITLE}
      isModalOpened={isModalOpened}
      handleCloseModal={handleCloseModal}
    >
      <form onSubmit={handleSubmit}>
        <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
        <SmsInputLabel>
          {SHARE_EMAIL_MODAL_CONTENT.EMAIL_INPUT_LABEL}
        </SmsInputLabel>
        <PhoneNumberInput
          phoneNumberInputProps={{
            id: ShareSmsFormField.PHONE_NUMBER,
            labelText: SHARE_EMAIL_FORM_TEXT.PHONE_NUMBER_LABEL,
            errorMessage: errors.phoneNumber,
            handleChangePhoneNumberFormik: handleChangePhoneNumberValue,
            ...getFieldProps(ShareSmsFormField.PHONE_NUMBER),
          }}
          countrySelectProps={{
            id: ShareSmsFormField.COUNTRY_CODE,
            errorMessage: errors.countryCode,
            handleChangeCountryCodeFormik: handleChangeCountryCodeValue,
            ...getFieldProps(ShareSmsFormField.COUNTRY_CODE),
          }}
        />
        <ShareActionsWrapper>
          <CancelButton type='ghost' onClick={handleCloseModal}>
            {SHARE_EMAIL_MODAL_CONTENT.CANCEL_LABEL}
          </CancelButton>
          <ShareButton
            htmlType='submit'
            id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
            type='primary'
          >
            {SHARE_EMAIL_MODAL_CONTENT.SHARE_LABEL}
          </ShareButton>
        </ShareActionsWrapper>
      </form>
    </SharePdfModal>
  );
};

export default ShareSmsModal;
