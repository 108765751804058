export const OWNERSHIP_DETAILS_TEXT = {
  title: 'Ownership Details',
  name: 'Name',
  phone: 'Phone',
  address: 'Address',
  email: 'Email',
};

export const ORDER_DETAILS_TEXT = {
  title: 'Order Details ',
  dealer: 'Dealer',
  orderNumber: 'Order Number',
  orderDate: 'Order Date',
  payment: 'Payment',
  paymentValue: 'Card',
  price: 'Price',
  invoice: 'Invoice',
};

export const CURRENCY_DETAILS_TEXT = {
  title: 'Currency Details',
  buttonText: 'View all',
};

export const REQUEST_DETAILS_TEXT = {
  title: 'Request & Invoice Details',
  machine: 'Machine',
  invoice: 'Order Invoice PDF',
  viewAll: 'View all',
  edit: 'Edit',
  ownershipDetails: 'Ownership Details',
  orderDetails: 'Order Details',
  dealer: 'Dealer',
  orderNumber: 'Order Number',
  orderDate: 'Order Date',
  amountPaid: 'Amount Paid',
  currencyDetails: 'Currency Details',
};
