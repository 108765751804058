import { FC } from 'react';

import { ResponseType } from 'constants/messages';
import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput, MAX_VERIFICATION_CODE_LENGTH } from 'components/Inputs';
import { StyledButton } from 'pages/SignUp/styled';

import { USER_FORM_TEXT } from '../constants';
import { UserFormField } from '../types';
import ResendCode from './ResendCode';
import { EmailSentText, FormFieldWrapper } from './styled';
import { IVerificationCodeFieldsProps } from './types';

const VerificationCodeFields: FC<IVerificationCodeFieldsProps> = ({
  formik: { errors, getFieldProps, values },
  responseMessage,
  isVerifyCodeLoading,
  handleChangeNumericInputValue,
  handleChangeResponseMessage,
  handleSubmit,
}) => {
  const isSuccessMessageShown =
    Boolean(responseMessage) && responseMessage?.type === ResponseType.SUCCESS;

  const isSubmitButtonDisabled =
    values[UserFormField.VERIFICATION_CODE].length <
    MAX_VERIFICATION_CODE_LENGTH;

  return (
    <>
      {isSuccessMessageShown && (
        <EmailSentText>{responseMessage?.message}</EmailSentText>
      )}
      <FormFieldWrapper>
        <DefaultInput
          {...getFieldProps(UserFormField.VERIFICATION_CODE)}
          labelText={USER_FORM_TEXT.verificationCodeLabel}
          errorMessage={errors[UserFormField.VERIFICATION_CODE]}
          maxLength={MAX_VERIFICATION_CODE_LENGTH}
          onChange={handleChangeNumericInputValue}
        />
      </FormFieldWrapper>
      <ResendCode
        email={values.email}
        responseMessage={responseMessage}
        handleChangeResponseMessage={handleChangeResponseMessage}
      />
      <StyledButton
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        loading={isVerifyCodeLoading}
        disabled={isSubmitButtonDisabled}
        onClick={() => handleSubmit(values)}
      >
        {USER_FORM_TEXT.button}
      </StyledButton>
    </>
  );
};

export default VerificationCodeFields;
