import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useSignUp } from 'api/auth';
import { useVerifySerialNumber } from 'api/serialNumbers';
import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { TAxiosRequestError } from 'api/types';
import { ISerialNumber } from 'types/serialNumber';
import { NotificationType } from 'types/notifications';
import { EMPTY_STRING, NUMBERS_REG_EXP } from 'constants/common';
import { openNotificationWithIcon } from 'utils/showNotification';
import { trimObjectValues } from 'utils/trimObjectValues';
import { formatPhoneNumber } from 'components/Inputs/PhoneNumberInput/utils';

import { IUserFormValues, UserStepperKeys } from './UserForm/types';
import { USER_LAST_STEP, USER_FIRST_STEP } from './UserForm/constants';
import { FIRST_STEP, NOT_VERIFIED_STEP } from './constants';
import { VerificationStepperKeys } from './types';
import { getVerificationNextStep } from './utils';
import SerialNumberForm from './SerialNumberForm';
import VerificationStep from './VerificationStep';
import EntryStep from './EntryStep';
import UserForm from './UserForm';
import SignUp from './SignUp';

const SignUpContainer: FC = () => {
  const navigateTo = useNavigate();

  const [serialNumber, setSerialNumber] = useState<string>(EMPTY_STRING);

  const [verificationStep, setVerificationStep] =
    useState<VerificationStepperKeys>(FIRST_STEP);
  const [userStep, setUserStep] = useState<UserStepperKeys>(USER_FIRST_STEP);

  const [userFormValues, setUserFormValues] = useState<IUserFormValues | null>(
    null,
  );

  const [isFinishSignUp, setIsFinishSignUp] = useState<boolean>(false);

  const { mutate: signUpMutate, isLoading: isSignUpLoading } = useSignUp();

  const handleSignUpSuccess = (/* customer: ICustomerInfo */) => {
    //TODO go to select chargebee subscription page
    navigateTo('');
  };

  const handleSignUpError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data.message || EMPTY_STRING,
    );
  };

  const handleSignUp = (
    payload: IUserFormValues & { serialNumber: string },
  ) => {
    const { countryCode, phoneNumber, ...valuesToTrim } = payload;

    const trimmedValues = trimObjectValues(valuesToTrim);

    signUpMutate(
      {
        countryCode,
        phoneNumber: formatPhoneNumber({ value: phoneNumber, countryCode }),
        ...trimmedValues,
      },
      {
        onSuccess: handleSignUpSuccess,
        onError: handleSignUpError,
      },
    );
  };

  const {
    mutate: verifySerialNumberMutate,
    isLoading: isVerifySerialNumberLoading,
  } = useVerifySerialNumber();

  const handleVerifySerialNumberSuccess = (serialNumber: string) => {
    if (userFormValues && serialNumber) {
      handleSignUp({ ...userFormValues, serialNumber });
    }
  };

  const handleVerifySerialNumberError = () => {
    setVerificationStep(VerificationStepperKeys.notVerified);
    setSerialNumber(EMPTY_STRING);
  };

  const handleVerifySerialNumber = (serialNumber: string) => {
    const payload = serialNumber.replace(NUMBERS_REG_EXP, EMPTY_STRING);

    verifySerialNumberMutate(payload, {
      onSuccess: ({ serialNumber: verifiedSerialNumber }: ISerialNumber) =>
        handleVerifySerialNumberSuccess(verifiedSerialNumber),
      onError: handleVerifySerialNumberError,
    });
  };

  useEffect(() => {
    if (isFinishSignUp && userFormValues && serialNumber) {
      handleVerifySerialNumber(serialNumber);
      setIsFinishSignUp(false);
    }
  }, [isFinishSignUp, userFormValues, serialNumber]);

  const handleUserFormValues = (formValues: IUserFormValues) => {
    setUserFormValues(formValues);

    if (userStep === USER_LAST_STEP) {
      setIsFinishSignUp(true);
    }
  };

  const handleUserStep = (key: UserStepperKeys) => {
    setUserStep(key);
  };

  const handleVerificationNextStep = () => {
    const nextStep = getVerificationNextStep(verificationStep);
    setVerificationStep(nextStep);
  };

  const handleSubmitSerialNumber = (serialNumber: string) => {
    setSerialNumber(serialNumber);

    if (verificationStep === NOT_VERIFIED_STEP) {
      handleVerifySerialNumber(serialNumber);
    } else {
      handleVerificationNextStep();
    }
  };

  const getSignUpContent = () => {
    switch (verificationStep) {
      case FIRST_STEP:
        return (
          <HelmetPageTitle title={MENU_TITLE.signUpVerifySN}>
            <SerialNumberForm
              serialNumber={serialNumber}
              handleSubmit={handleSubmitSerialNumber}
            />
          </HelmetPageTitle>
        );

      case VerificationStepperKeys.verification:
        return (
          <HelmetPageTitle title={MENU_TITLE.signUpVerification}>
            <VerificationStep
              serialNumber={serialNumber}
              handleNextStep={handleVerificationNextStep}
            />
          </HelmetPageTitle>
        );

      case VerificationStepperKeys.entry:
        return (
          <HelmetPageTitle title={MENU_TITLE.signUpVerificationCompleted}>
            <EntryStep handleNextStep={handleVerificationNextStep} />
          </HelmetPageTitle>
        );

      case VerificationStepperKeys.userSteps:
        return (
          <UserForm
            currentStep={userStep}
            formValues={userFormValues}
            isSignUpLoading={isSignUpLoading || isVerifySerialNumberLoading}
            handleFormValues={handleUserFormValues}
            handleChangeStep={handleUserStep}
          />
        );

      case VerificationStepperKeys.notVerified:
        return (
          <HelmetPageTitle title={MENU_TITLE.signUpVerificationFailed}>
            <SerialNumberForm
              isNotVerified
              isVerifyLoading={isVerifySerialNumberLoading}
              serialNumber={serialNumber}
              handleSubmit={handleSubmitSerialNumber}
            />
          </HelmetPageTitle>
        );

      default:
        return null;
    }
  };

  const SignUpStepComponent = getSignUpContent();

  return (
    <SignUp verificationStep={verificationStep} userStep={userStep}>
      {SignUpStepComponent}
    </SignUp>
  );
};

export default SignUpContainer;
