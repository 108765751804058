import { forwardRef, ForwardRefRenderFunction, Suspense } from 'react';

import HeaderTitleProvider from 'components/HeaderTitleProvider';
import { Loader } from 'components/Loader';
import { RoutesList } from 'components/Routes';

import { StyledLayout, StyledContent, StyledMainLayout } from './styled';
import { IAuthorizedLayoutProps } from './types';
import { SideMenu, Header, Footer } from './components';

enum FormScrollNavigationId {
  scrollContainer = 'scrollContainer',
  details = 'details',
  contacts = 'contacts',
  licensing = 'licensing',
}

const AuthorizedLayout: ForwardRefRenderFunction<
  HTMLElement,
  IAuthorizedLayoutProps
> = ({ userProfile, isCollapsed, ...props }, ref) => {
  const { role, firstName, lastName, avatar } = userProfile;

  const userFullName = `${firstName} ${lastName}`;

  return (
    <HeaderTitleProvider>
      <StyledMainLayout>
        <SideMenu
          userRole={role}
          isCollapsed={isCollapsed}
          userFullName={userFullName}
          userAvatar={avatar?.url}
          {...props}
        />
        <StyledLayout>
          <Header
            userRole={role}
            userFullName={userFullName}
            userAvatar={avatar?.url}
            isCollapsed={isCollapsed}
            {...props}
          />
          <StyledContent id={FormScrollNavigationId.scrollContainer} ref={ref}>
            <Suspense fallback={<Loader />}>
              <RoutesList />
            </Suspense>
            <Footer />
          </StyledContent>
        </StyledLayout>
      </StyledMainLayout>
    </HeaderTitleProvider>
  );
};

export default forwardRef(AuthorizedLayout);
