import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useGetSerialNumberDetails } from 'api/serialNumbers';
import { SerialNumbersDetailsModalContextProvider } from 'contexts/SerialNumberDetailsModalContext';
import { CurrencyType } from 'types/currencies';
import { useGenerateExportPdf } from 'api/requests';

import { TSerialNumberDetailsParams } from './types';
import SerialNumberDetails from './SerialNumberDetails';
import { downloadPdf } from './utils/exportPdf';

const SerialNumberDetailsContainer: FC = () => {
  const { serialNumberId } = useParams() as TSerialNumberDetailsParams;

  const {
    data: serialNumberDetailsResponse,
    isFetching: isSerialNumberDetailsFetching,
  } = useGetSerialNumberDetails(serialNumberId);

  const isReadyToExport = useMemo(() => {
    const isSaveAndShareEnabled =
      serialNumberDetailsResponse?.saveAndShareAccess?.[
        serialNumberDetailsResponse?.currency as unknown as keyof typeof CurrencyType
      ]?.isActive;

    return Boolean(
      isSaveAndShareEnabled && serialNumberDetailsResponse?.request?._id,
    );
  }, [serialNumberDetailsResponse]);

  const { data: generatedPdf, isFetching: isPdfGenerating } =
    useGenerateExportPdf(
      serialNumberDetailsResponse?.request?._id as string,
      isReadyToExport,
    );

  const exportPdf = useCallback(
    () => downloadPdf(generatedPdf as ArrayBuffer),
    [downloadPdf, generatedPdf],
  );

  return (
    <SerialNumbersDetailsModalContextProvider
      pdfBytes={generatedPdf}
      serialNumberId={serialNumberDetailsResponse?._id}
    >
      <SerialNumberDetails
        serialNumber={serialNumberDetailsResponse}
        isPdfGenerating={isPdfGenerating}
        isSerialNumberDetailsFetching={isSerialNumberDetailsFetching}
        handleExportPdf={exportPdf}
      />
    </SerialNumbersDetailsModalContextProvider>
  );
};

export default SerialNumberDetailsContainer;
