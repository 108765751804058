import { FC } from 'react';

import { SERIAL_NUMBER_LENGTH } from 'constants/validations';
import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { IdrLargeIcon } from 'assets/vectors';
import SerialNumberInput from 'components/SerialNumberInput';
import { PageTitle } from 'components/UnauthorizedPages';
import {
  FormWrapper,
  StyledTitleWrapper,
  FormFieldWrapper,
  StyledButton,
  StyledLearnMoreButton,
} from 'pages/SignUp/styled';

import { ISerialNumberFormProps, SerialNumberFormField } from './types';
import { NOT_VERIFIED_FORM_TEXT, SERIAL_NUMBER_FORM_TEXT } from './constants';
import { IconWrapper, NotVerifiedTitle, NotVerifiedIcon } from './styled';

const SerialNumberForm: FC<ISerialNumberFormProps> = ({
  formik: { handleSubmit, values, errors, getFieldProps },
  isNotVerified,
  isVerifyLoading,
  handleChangeSerialNumber,
  handleLearnMoreModalOpen,
}) => {
  const isSubmitButtonDisabled =
    values[SerialNumberFormField.SERIAL_NUMBER].length < SERIAL_NUMBER_LENGTH;

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <IconWrapper>
        <IdrLargeIcon />
        {isNotVerified && <NotVerifiedIcon />}
      </IconWrapper>
      <StyledTitleWrapper>
        {isNotVerified ? (
          <NotVerifiedTitle>{NOT_VERIFIED_FORM_TEXT.title}</NotVerifiedTitle>
        ) : (
          <PageTitle>{SERIAL_NUMBER_FORM_TEXT.title}</PageTitle>
        )}
      </StyledTitleWrapper>
      <StyledLearnMoreButton type='link' onClick={handleLearnMoreModalOpen}>
        {SERIAL_NUMBER_FORM_TEXT.leanMoreLink}
      </StyledLearnMoreButton>
      <FormFieldWrapper>
        <SerialNumberInput
          id={SerialNumberFormField.SERIAL_NUMBER}
          maxLength={SERIAL_NUMBER_LENGTH}
          labelText={SERIAL_NUMBER_FORM_TEXT.serialNumberLabel}
          errorMessage={errors[SerialNumberFormField.SERIAL_NUMBER]}
          defaultValue={values[SerialNumberFormField.SERIAL_NUMBER]}
          handleChangeFormikState={handleChangeSerialNumber}
          {...getFieldProps(SerialNumberFormField.SERIAL_NUMBER)}
        />
      </FormFieldWrapper>
      <StyledButton
        htmlType='submit'
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        loading={isVerifyLoading}
        disabled={isSubmitButtonDisabled}
      >
        {isNotVerified
          ? NOT_VERIFIED_FORM_TEXT.submitButton
          : SERIAL_NUMBER_FORM_TEXT.submitButton}
      </StyledButton>
    </FormWrapper>
  );
};

export default SerialNumberForm;
