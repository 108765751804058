import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';
import BackToLink from 'components/BackToLink';
import CustomAlert from 'components/CustomAlert';

export const FormWrapper = styled('form')({
  width: '100%',
  textAlign: 'center',
});

export const FormFieldWrapper = styled('div')({
  marginBottom: '20px',
});

export const StyledTitleWrapper = styled('div')({
  width: '100%',
  textAlign: 'center',
  marginBottom: '16px',
  '>h1, >h2': {
    marginBottom: '20px',
  },
});

export const StyledHint = styled('p')({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '500',
  color: COLORS.gray600,
  whiteSpace: 'pre-line',
  margin: '-20px auto 32px',
});

export const StyledButton = styled(Button)({
  width: '100%',
  margin: '20px 0 36px',
});

export const StyledBackToLink = styled(BackToLink)({
  margin: '0 auto 24px 0',
  fontSize: '16px',
  fontWeight: 600,
});

export const StyledLearnMoreButton = styled(Button)({
  margin: '0 auto 32px',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 500,
  '> span': {
    textDecoration: 'underline',
  },
});

export const AlertWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '32px',
});

export const StyledAlert = styled(CustomAlert)({
  textAlign: 'left',
  borderRadius: '2px',
});
