import { FC } from 'react';
import {
  Tooltip as AntdTooltip,
  TooltipProps as TAntdTooltipProps,
} from 'antd';

import { DefaultTooltipOverlayStyles } from './styled';

const Tooltip: FC<TAntdTooltipProps> = ({
  children,
  overlayStyle,
  ...props
}) => (
  <AntdTooltip
    {...props}
    overlayInnerStyle={{ ...overlayStyle, ...DefaultTooltipOverlayStyles }}
  >
    {children}
  </AntdTooltip>
);

export default Tooltip;
