import { FC, useState } from 'react';

import { HeaderTitleContext } from 'contexts';
import { EMPTY_STRING, EMPTY_OBJECT } from 'constants/common';

import { IHeaderTitleProvider } from './types';

const HeaderTitleProvider: FC<IHeaderTitleProvider> = ({ children }) => {
  const [title, setTitle] = useState(EMPTY_STRING);
  const [subTitle, setSubTitle] = useState(EMPTY_STRING);
  const [backLinkPath, setBackLinkPath] = useState(EMPTY_STRING);
  const [backLinkState, setBackLinkState] = useState(EMPTY_OBJECT);

  return (
    <HeaderTitleContext.Provider
      value={{
        title,
        subTitle,
        backLinkPath,
        backLinkState,
        setTitle,
        setSubTitle,
        setBackLinkPath,
        setBackLinkState,
      }}
    >
      {children}
    </HeaderTitleContext.Provider>
  );
};

export default HeaderTitleProvider;
