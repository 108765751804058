import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { ISerialNumber } from 'types/serialNumber';

import { IRequestErrorPayload } from './types';

const useVerifySerialNumber = (
  onSuccess?: () => void,
  onError?: (error: TAxiosRequestError) => void,
): UseMutationResult<ISerialNumber, TAxiosRequestError, string> => {
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleError = (error: AxiosError<IRequestErrorPayload>) => {
    if (onError) {
      onError(error);
    }
  };

  return useMutation(
    async (serialNumber) => {
      const { data } = await apiClient.get(ApiRoutes.VERIFY_SERIAL_NUMBER, {
        params: { serialNumber },
      });

      return data;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );
};

export default useVerifySerialNumber;
