import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { TColorProp } from 'types/common';

import { ProgressBarType } from './types';
import { getGradient, getProgressColorName } from './utils';

const PROGRESS_BAR_HEIGHT = 40;
const PROGRESS_BAR_HEIGHT_MOBILE = 25;

export const StyledProgressBarLayout = styled('div')({
  width: '100%',
  height: '100%',
});

export const ProgressBar = styled('div')<{
  type: ProgressBarType;
  progress: number;
}>(({ type, progress }) => {
  const colorName = getProgressColorName(type);
  const gradient = getGradient(colorName);

  return {
    position: 'relative',
    width: '100%',
    height: PROGRESS_BAR_HEIGHT,
    backgroundColor: COLORS.gray200,
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      height: PROGRESS_BAR_HEIGHT,
      left: 0,
      width: `${progress.toFixed(2)}%`,
      transition: `width .55s cubic-bezier(0.4, 0, 1, 1)`,
      background: gradient,
    },
    '@media (max-width: 768px)': {
      height: PROGRESS_BAR_HEIGHT_MOBILE,
      '&:before': {
        height: PROGRESS_BAR_HEIGHT_MOBILE,
      },
    },
  };
});

export const ProgressBarText = styled('p')<{ type: ProgressBarType }>(
  ({ type }) => {
    const colorName = getProgressColorName(type);
    const color = COLORS[`${colorName}700` as TColorProp];

    return {
      position: 'relative',
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: `${PROGRESS_BAR_HEIGHT}px`,
      fontWeight: 600,
      color,
      marginBottom: 0,
      zIndex: 1,
      '@media (max-width: 768px)': {
        fontSize: '18px',
        lineHeight: `${PROGRESS_BAR_HEIGHT_MOBILE}px`,
      },
    };
  },
);

export const ProgressBarContentWrapper = styled('div')({
  width: '100%',
  minHeight: 'calc(100% - 40px)',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    minHeight: 'calc(100% - 25px)',
  },
});
