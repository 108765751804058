import styled from 'styled-components';

export const ArabicNumbersWrapper = styled('p')({
  margin: 0,
  fontFamily: 'NotoSansArabic, sans-serif',
});

export const BanknotesWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
});
