import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  padding: '4px 0',
  '@media (max-width: 1024px)': {
    padding: '4px 2px',
  },
});

export const CurrenciesTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '18px',
  marginBottom: '24px',
});

export const CurrenciesTitle = styled('h3')({
  maxWidth: '100%',
  marginBottom: 0,
  fontSize: '20px',
  fontWeight: 600,
  color: COLORS.gray700,
});
