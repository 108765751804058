import { Divider } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import {
  SignOutButton,
  SignOutText,
  StyledSignOutIcon as SignOutIcon,
} from '../styled';

export const SettingsWrapper = styled('div')({
  padding: '0 16px',
  '@media (min-width:768px)': {
    display: 'none',
  },
});

export const StyledDivider = styled(Divider)({
  margin: '37px 0 16px',
  color: COLORS.gray100,
});

export const TitleText = styled('p')({
  fontWeight: 800,
  color: COLORS.gray600,
});

export const ProfileWrapper = styled('div')({
  display: 'flex',
  columnGap: '16px',
  margin: '28px 34px 34px 0',
});

export const ProfileTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '200px',
  justifyContent: 'flex-start',
});

export const ProfileText = styled('p')({
  margin: 0,
  fontWeight: 600,
  fontSize: '18px',
  letterSpacing: '0.2px',
  color: COLORS.gray800,
});

export const UserName = styled(ProfileText)({
  fontWeight: 500,
  fontSize: '14px',
  color: COLORS.gray600,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100px',
});

export const HelpWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '19px',
  marginLeft: '3px',
  marginBottom: '14px',
});

export const HelpText = styled('p')({
  margin: 0,
  fontWeight: 500,
  fontSize: '16px',
  color: COLORS.gray700,
});

export const SignOut = styled(SignOutText)({
  fontSize: '16px',
  paddingLeft: '19px',
});

export const StyledSignOutIcon = styled(SignOutIcon)({
  '& svg': {
    width: '24px',
    height: '24px',
  },
});

export const InnerWrapper = styled('div')({
  marginBottom: '92px',
});

export const FooterText = styled('span')({
  position: 'absolute',
  bottom: 0,
  fontSize: '12px',
  color: COLORS.gray500,
});

export const StyledSignOutButton = styled(SignOutButton)({
  '@media (max-width:768px)': {
    display: 'flex',
    padding: 0,
    marginLeft: '3px',
  },
});
