import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput, MAX_DEFAULT_LENGTH } from 'components/Inputs';
import { FormFieldWrapper, StyledButton } from 'pages/SignUp/styled';

import { IUserFieldsProps, UserFormField } from '../types';
import { USER_FORM_TEXT } from '../constants';

const FullNameFields: FC<IUserFieldsProps> = ({
  formik: { errors, values, getFieldProps },
}) => {
  const isDisabled = !(
    values[UserFormField.FIRST_NAME] && values[UserFormField.LAST_NAME]
  );

  return (
    <>
      <FormFieldWrapper>
        <DefaultInput
          labelText={USER_FORM_TEXT.firstNameLabel}
          errorMessage={errors[UserFormField.FIRST_NAME]}
          maxLength={MAX_DEFAULT_LENGTH}
          {...getFieldProps(UserFormField.FIRST_NAME)}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <DefaultInput
          labelText={USER_FORM_TEXT.lastNameLabel}
          errorMessage={errors[UserFormField.LAST_NAME]}
          maxLength={MAX_DEFAULT_LENGTH}
          {...getFieldProps(UserFormField.LAST_NAME)}
        />
      </FormFieldWrapper>
      <StyledButton
        htmlType='submit'
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        disabled={isDisabled}
      >
        {USER_FORM_TEXT.button}
      </StyledButton>
    </>
  );
};

export default FullNameFields;
