import { useMemo, useCallback } from 'react';

import { NOTIFICATION_ERROR_MESSAGE } from 'constants/messages';
import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';

import useDropbox from './useDropbox';
import useGoogle from './useGoogle';
import { SAVE_OPTIONS } from '../constants';
import { IUseSaveActions } from '../types';

const useSaveActions = ({
  serialNumberId,
  handleExportPdf,
}: IUseSaveActions) => {
  const { upload: uploadToGoogle, isLoading: isGoogleLoading } =
    useGoogle(serialNumberId);
  const { upload: uploadToDropbox, isLoading: isDropboxLoading } =
    useDropbox(serialNumberId);

  const saveToDevice = useCallback(
    () =>
      handleExportPdf().catch(() =>
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.saveSerialNumberToDevice,
        ),
      ),
    [handleExportPdf],
  );

  const actions = useMemo(
    () => [
      {
        key: '1',
        id: SAVE_AND_SHARE_ACTIONS_BUTTONS_ID,
        label: SAVE_OPTIONS.saveDevice,
        onClick: saveToDevice,
      },
      {
        key: '2',
        id: SAVE_AND_SHARE_ACTIONS_BUTTONS_ID,
        label: SAVE_OPTIONS.saveGoogle,
        onClick: uploadToGoogle,
      },
      {
        key: '3',
        id: SAVE_AND_SHARE_ACTIONS_BUTTONS_ID,
        label: SAVE_OPTIONS.saveDropbox,
        onClick: uploadToDropbox,
      },
    ],
    [saveToDevice, uploadToGoogle, uploadToDropbox],
  );

  return { actions, isLoading: isGoogleLoading || isDropboxLoading };
};

export default useSaveActions;
