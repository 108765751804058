import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { EmailIcon } from 'assets/vectors';

export const NotificationWrapper = styled('div')({
  display: 'flex',
  padding: '24px 0 16px',
  fontSize: '16px',
  borderTop: `1px solid ${COLORS.gray200}`,
});

export const EmailIconWrapper = styled(EmailIcon)({
  marginRight: '30px',
});

export const Details = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const NotificationDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const NotificationTitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '6px',
});

export const NotificationTitle = styled(
  ({ isRead: _isRead, children, ...props }) => <h3 {...props}>{children}</h3>,
)(({ isRead }) => ({
  fontSize: '16px',
  fontWeight: 800,
  color: isRead ? COLORS.gray900 : COLORS.blue500,
}));

export const NotificationDate = styled('h4')({
  display: 'flex',
  color: COLORS.gray400,
  fontSize: '16px',
});

export const NotificationDescription = styled('h3')({
  color: COLORS.gray500,
  fontSize: '16px',
  fontWeight: 600,
});
