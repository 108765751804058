import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const Tooltip = styled('div')({
  position: 'absolute',
  top: '50%',
  right: '-16px',
  padding: '4px 8px',
  fontWeight: 500,
  lineHeight: 1.4,
  color: COLORS.white,
  backgroundColor: COLORS.gray900,
  borderRadius: '2px',
  transform: 'translate(100%, -50%)',
  opacity: 0,
  visibility: 'hidden',
  transition: '.2s ease',
  pointerEvents: 'none',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '8px',
    height: '8px',
    backgroundColor: COLORS.gray900,
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
});
