import { FC } from 'react';

import { LAYOUT_FOOTER_TEXT } from './constants';
import { FooterText, FooterWrapper } from './styled';

const Footer: FC = () => (
  <FooterWrapper>
    <FooterText>{LAYOUT_FOOTER_TEXT.RIGHTS_MESSAGE}</FooterText>
  </FooterWrapper>
);

export default Footer;
