import { FC, ReactNode } from 'react';

import {
  StyledUnauthorizedPageContent,
  StyledUnauthorizedPageWrapper,
} from './styled';

const UnauthorizedPageWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledUnauthorizedPageWrapper>
    <StyledUnauthorizedPageContent>{children}</StyledUnauthorizedPageContent>
  </StyledUnauthorizedPageWrapper>
);

export default UnauthorizedPageWrapper;
