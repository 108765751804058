import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { QUERY_KEYS } from 'constants/queryKeys';
import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/routing';

import { TGetRequestInvoiceResponse } from './types';

const useGetRequestInvoice = (
  requestId: string,
): UseQueryResult<TGetRequestInvoiceResponse, AxiosError> =>
  useQuery(
    [QUERY_KEYS.REQUEST_INVOICE, requestId],
    async () => {
      const { data: requestInvoiceResponse } = await apiClient.get(
        transformRoute(ApiRoutes.GET_REQUEST_INVOICE, requestId),
      );

      return requestInvoiceResponse;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(requestId),
    },
  );

export default useGetRequestInvoice;
