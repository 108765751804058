export const SERIAL_NUMBER_DETAILS_TEXT = {
  pageTitle: 'Serial Number Details',
  confirmNavigate: 'Generating the PDF process will be cancelled.',
};

export const SHARE_MODAL_SUBTITLE =
  'DinarVerify gives the customers verifiable ownership records that are easily shareable with 3rd parties. The information below are the ways customers can share their ownership records.';

export const COPY_LINK_WITH_PWD_MESSAGE =
  'Please, use this password to view the record:';

export const DOWNLOAD_LINK_ATTRIBUTES = {
  href: 'href',
  download: 'download',
  name: 'ownership_record.pdf',
  displayNone: 'none',
};
