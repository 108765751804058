import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

export const EmbedCodeWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  fontWeight: 500,
  fontSize: '14px',
  color: COLORS.gray900,
  marginBottom: '20px',
  '.anticon': {
    position: 'relative',
    top: '1px',
  },
});

export const StyledInfoCircleIcon = styled(InfoCircleOutlined)({
  color: COLORS.gray700,
  marginLeft: '13px',
  svg: {
    width: '17px',
    height: '17px',
  },
});

export const CopyLinkWrapper = styled('div')({
  width: '100%',
  p: {
    fontSize: '14px',
    fontWeight: 800,
    color: COLORS.gray900,
    marginBottom: '4px',
  },
  div: {
    height: '48px',
    padding: '13px 0 12px 13px',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    background: COLORS.gray100,
    borderRadius: '4px',
    marginBottom: '20px',
    '&>span': {
      fontWeight: 500,
      fontSize: '16px',
      color: COLORS.gray500,
      maxWidth: '100%',
      overflow: 'hidden',
    },
  },
});

export const CopyLinkButton = styled(Button)({
  padding: '5.2px 10px 4px',
});

export const ShareModalWrapper = styled('div')({
    height: '406px',
});

export const SharePdfWrapper = styled('div')({
  fontSize: '14px',
  fontWeight: 800,
  color: COLORS.gray900,
  width: '100%',
  textAlign: 'center',
  p: {
    marginBottom: '22px',
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const ButtonIcon = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  cursor: 'pointer',
  fontWeight: 500,
  fontSize: '14px',
  maxWidth: '94px',
  color: COLORS.gray900,
});

export const StyledEmbedInfoNotice = styled('div')({
  maxWidth: '300px',
  fontWeight: 500,
  fontSize: '14px',
  color: COLORS.gray900,
});
