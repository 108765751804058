import { useMutation, UseMutationResult } from 'react-query';

import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { apiClient, queryClient } from 'api/base';

const useReadNotifications = (): UseMutationResult => {
  const handleReadSuccess: () => void = () => {
    queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATIONS, { page: 1 }]);
  };
  return useMutation(
    async () => {
      await apiClient.patch(ApiRoutes.READ_NOTIFICATIONS);
    },
    {
      retry: false,
      onSuccess: handleReadSuccess,
    },
  );
};

export default useReadNotifications;
