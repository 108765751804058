import styled from 'styled-components';
import Icon from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

export const NotFoundWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  flex: '1 1 auto',
});

export const StyledIcon = styled(Icon)({
  '& svg': {
    width: '230px',
    height: '190px',
  },
  boxShadow: '0px 15px 10px -15px rgb(0, 0, 0, 0.08)',
});

export const Text = styled('p')({
  marginTop: '16px',
  marginBottom: '24px',
  color: COLORS.gray700,
  fontSize: '20px',
  fontWeight: 600,
});
