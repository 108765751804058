import { FC } from 'react';

import { BADGE_DOT_COLOR_LOOKUP } from './constants';
import { DotBadge } from './styled';

const UnlockedSerialNumberBadge: FC = () => (
  <DotBadge
    status='success'
    text='Unlocked'
    color={BADGE_DOT_COLOR_LOOKUP.success}
  />
);

export default UnlockedSerialNumberBadge;
