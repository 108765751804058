import { APP_ROUTE } from 'constants/appRoutes';
import { EMPTY_STRING } from 'constants/common';

const EXPECTED_ROOT_POSITION = 0;
const ROOT_PATH_LEVELS = 1;

const extractValuablePathLevels = (path: string): string[] =>
  path.split('/').filter((level) => Boolean(level));

const extractRouteRoot = (path: string): string =>
  extractValuablePathLevels(path)[EXPECTED_ROOT_POSITION] || EMPTY_STRING;

const HEADER_TITLE_LOOKUP = {
  [extractRouteRoot(APP_ROUTE.serialNumbers)]: 'Your Iraqi Dinars',
  [extractRouteRoot(APP_ROUTE.serialNumberCurrencyDetails)]:
    'Serial Number Details',
};

const resolvePathRootToTitle = (
  root: string,
  defaultPageTitle: string,
): string => HEADER_TITLE_LOOKUP[root] || defaultPageTitle;

const checkIsMultiLevelPath = (path: string): boolean =>
  extractValuablePathLevels(path).length > ROOT_PATH_LEVELS;

export const getRouteRootHeaderTitle = (
  path: string,
  defaultPageTitle: string,
): string => {
  const root = extractRouteRoot(path);
  const title = resolvePathRootToTitle(root, defaultPageTitle);
  return title;
};

export const getRouteBacklinkPath = (
  path: string,
  defaultPageTitle: string,
): string => {
  const isMultiLevelPath = checkIsMultiLevelPath(path);
  if (!isMultiLevelPath) {
    return EMPTY_STRING;
  }

  const root = extractRouteRoot(path);
  const hasBackLink =
    resolvePathRootToTitle(root, defaultPageTitle) !== defaultPageTitle;

  return hasBackLink ? root : EMPTY_STRING;
};
