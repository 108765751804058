import { TAxiosRequestError } from 'api/types';
import { STATUS_CODES } from 'constants/errorCodes';

export const mapUpdateErrorMessage = (
  error?: TAxiosRequestError | null,
): string | null => {
  const code = error?.response?.data?.statusCode;
  const message = error?.response?.data?.message || null;

  if (code === STATUS_CODES.INTERNAL_SERVER_ERROR) {
    return null;
  }

  return message;
};
