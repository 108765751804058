import { ICustomerInfo } from './customer';
import { SerialNumberStatus } from './serialNumber';

export interface ISubscription {
  _id: string;
  chargebeeId: string;
  status: TSubscriptionStatus;
  user: ICustomerInfo;
  createdAt: Date;
  updatedAt: Date;
  SNref: TSNRef;
  serialNumber: string;
  currency: string;
  denom: number;
  amount: number;
  currentTermStart: Date;
  currentTermEnd: Date;
  price: number;
  planId: string;
  planName: string;
  payments: IPaymentRecord[];
  actions?: string;
  nextBillingAt?: Date;
  cancelReason?: TCancelReason;
}

export interface IPaymentRecord {
  invoiceId: string;
  invoiceName: string;
  generatedAt: Date;
  status: TPaymentStatus;
  planId: string;
  planName: string;
  planPrice: number;
  paid: number;
}

export type TSubscriptionStatus =
  | 'future'
  | 'in_trial'
  | 'active'
  | 'non_renewing'
  | 'paused'
  | 'cancelled';

export type TCancelReason =
  | 'not_paid'
  | 'no_card'
  | 'fraud_review_failed'
  | 'non_compliant_eu_customer'
  | 'tax_calculation_failed'
  | 'currency_incompatible_with_gateway'
  | 'non_compliant_customer';

export type TPaymentStatus =
  | 'paid'
  | 'posted'
  | 'payment_due'
  | 'not_paid'
  | 'voided'
  | 'pending';

export type TPaymentStatusName =
  | 'Paid'
  | 'Posted'
  | 'Payment due'
  | 'Not paid'
  | 'Voided'
  | 'Pending';

export type TSNRef = {
  _id: string;
  status: SerialNumberStatus;
  subscriptionStatus: TSubscriptionStatus;
  isArchived: boolean;
};

export interface ISubscriptionPlan {
  id: string;
  name: string;
  discount: number | null;
  price: number;
  charge: number;
  isBestOffer: boolean;
}

export interface IPaymentCheckout {
  id: string;
  url: string;
}

export interface ISubscriptionPayments {
  chargebeeId: string;
  subscriptionStatus: TSubscriptionStatus;
  payments: IPaymentView[];
}

export interface IPaymentView extends IPaymentRecord {
  statusName: TPaymentStatusName;
}

export enum SubscriptionStatus {
  active = 'active',
  cancelled = 'cancelled',
}
