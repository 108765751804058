import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { USER_AVATAR_SIZE } from 'constants/menu';
import { TAvatarSize } from './types';

export const NoAvatarWrapper = styled('div')<TAvatarSize>(({ avatarSize }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: avatarSize ? `${avatarSize}px` : `${USER_AVATAR_SIZE}px`,
  height: avatarSize ? `${avatarSize}px` : `${USER_AVATAR_SIZE}px`,
  background: COLORS.blue600,
}));

export const UserInitials = styled('span')<TAvatarSize>(({ avatarSize }) => ({
  fontFamily: 'Manrope, sans-serif',
  fontSize: avatarSize ? '20px' : '12px',
  fontWeight: avatarSize ? 600 : 800,
  lineHeight: 1.5,
  letterSpacing: avatarSize ? '-2%' : '0.4px',
}));
