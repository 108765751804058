import { FC } from 'react';

import { BADGE_DOT_COLOR_LOOKUP } from './constants';
import { DotBadge } from './styled';

const LockedSerialNumberBadge: FC = () => (
  <DotBadge status='error' text='Locked' color={BADGE_DOT_COLOR_LOOKUP.error} />
);

export default LockedSerialNumberBadge;
