import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

export const IconWrapper = styled('div')({
  cursor: 'pointer',
  width: 'fit-content',
});

export const InfoIcon = styled(InfoCircleOutlined)<{ iconSize: string }>(
  ({ iconSize }) => ({
    display: 'flex',
    fontSize: iconSize || '20px',
    color: COLORS.gray700,
  }),
);
