import { FC } from 'react';

import { EMPTY_STRING } from 'constants/common';
import { ResponseType } from 'constants/messages';
import { PageSubtitle, PageTitle } from 'components/UnauthorizedPages';

import {
  FormWrapper,
  StyledTitleWrapper,
  StyledBackToLink,
  StyledHint,
  AlertWrapper,
  StyledAlert,
} from '../styled';
import { USER_STEP_TEXT, STEP_BACK_TEXT } from './constants';
import { getUserFormFields } from './utils';
import { TUserFormProps, UserStepperKeys } from './types';

const UserForm: FC<TUserFormProps> = ({
  formik,
  currentStep,
  isSignUpLoading,
  responseMessage,
  handleChangeResponseMessage,
  handleBack,
}) => {
  const userFormFields = getUserFormFields(
    currentStep,
    formik,
    handleChangeResponseMessage,
    responseMessage,
    isSignUpLoading,
  );
  const { title, subtitle, hint } = USER_STEP_TEXT[currentStep];

  const withBackButton = [
    UserStepperKeys.verificationCode,
    UserStepperKeys.fullName,
    UserStepperKeys.phoneNumber,
    UserStepperKeys.address,
  ].includes(currentStep);

  const isErrorAlertShown =
    Boolean(responseMessage) && responseMessage?.type === ResponseType.ERROR;

  return (
    <>
      {withBackButton && (
        <StyledBackToLink
          onClick={handleBack}
          allowBack={false}
          to={EMPTY_STRING}
        >
          {STEP_BACK_TEXT}
        </StyledBackToLink>
      )}
      <StyledTitleWrapper>
        {title && <PageTitle>{title}</PageTitle>}
        {subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
      </StyledTitleWrapper>
      <FormWrapper onSubmit={formik.handleSubmit}>
        {hint && <StyledHint>{hint}</StyledHint>}
        {isErrorAlertShown && (
          <AlertWrapper>
            <StyledAlert
              type={responseMessage?.type}
              message={responseMessage?.message || EMPTY_STRING}
            />
          </AlertWrapper>
        )}
        {userFormFields}
      </FormWrapper>
    </>
  );
};

export default UserForm;
