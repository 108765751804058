import { EMPTY_STRING, DEFAULT_PHONE_COUNTRY_CODE } from 'constants/common';

import { IShareSmsFormValues, ShareSmsFormField } from './types';

export const SHARE_EMAIL_MODAL_CONTENT = {
  TITLE: 'Share record by text message',
  EMAIL_INPUT_LABEL: 'Please, enter a Phone to share your ownership record',
  CANCEL_LABEL: 'Cancel',
  SHARE_LABEL: 'Share by message',
};

export const SHARE_EMAIL_FORM_TEXT = {
  PHONE_NUMBER_LABEL: 'Phone',
};

export const VALIDATION_ERROR_MESSAGES = {
  PHONE_FIELD_REQUIRED: 'Please enter your phone',
  COUNTRY_CODE_FIELD_REQUIRED: 'Please enter your code',
  PHONE_NUMBER_INVALID: 'Please enter a valid phone number',
};

export const INITIAL_SHARE_SMS_FORM_VALUES: IShareSmsFormValues = {
  [ShareSmsFormField.PHONE_NUMBER]: EMPTY_STRING,
  [ShareSmsFormField.COUNTRY_CODE]: DEFAULT_PHONE_COUNTRY_CODE,
};
