import { useMemo } from 'react';
import dayjs from 'dayjs';

import { INotification } from 'types/notification';

import { TNotificationsGrouped } from './types';

export const isInLast21Hours = (dateString: string): boolean => {
  const twentyOneHourAgo = dayjs().subtract(21, 'hour');
  const targetDate = dayjs(dateString);

  return targetDate.isAfter(twentyOneHourAgo);
};

export const splitNotifications = (
  notifications: INotification[],
): TNotificationsGrouped => {
  const memoizedNotifications = useMemo(
    () =>
      notifications.reduce(
        (acc: TNotificationsGrouped, notification: INotification) => {
          const notificationGroup = isInLast21Hours(notification.createdAt)
            ? 'todayNotifications'
            : 'olderNotifications';

          return {
            ...acc,
            [notificationGroup]: [...acc[notificationGroup], notification],
          };
        },
        { todayNotifications: [], olderNotifications: [] },
      ),
    [notifications],
  );

  return memoizedNotifications;
};
