import { cleanEnv, url, str } from 'envalid';

import { TCurrencyType } from 'types/currencies';

export const env = cleanEnv(process.env, {
  REACT_APP_API_URL: url(),
  REACT_APP_NODE_ENV: str({
    choices: ['development', 'test', 'staging', 'production'],
  }),
  REACT_APP_TYPE: str({
    choices: ['customer', 'admin'],
  }),
  REACT_APP_CURRENCY_TYPE: str<TCurrencyType>({
    choices: ['IQD', 'VND', 'IDR'],
  }),
  REACT_APP_GOOGLE_API_KEY: str(),
  REACT_APP_GOOGLE_CLIENT_ID: str(),
  REACT_APP_DROPBOX_APP_KEY: str(),
});
