import styled from 'styled-components';

export const StyledUnauthorizedPageWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const StyledUnauthorizedPageContent = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '480px',
  width: '100%',
  padding: '30px',
  margin: '0 auto',
  '@media (max-width: 768px)': {
    marginTop: 0,
    padding: '20px',
  },
});
