import { FC } from 'react';
import { Link } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import CroppedTooltip from 'components/CroppedTooltip';
import HelpLink from 'components/HelpLink';
import Tooltip from 'components/Tooltip';

import { BACK_LINK_TEXT, PROFILE_DETAILS_TEXT } from './constants';
import UserAvatar from './components/UserAvatar';
import Notifications from './components/Notifications';
import {
  HeaderTitle,
  HeaderTitleWrapper,
  HeaderWrapper,
  InfoWrapper,
  UserInfoWrapper,
  UserName,
  BurgerButton,
  BurgerButtonShape,
  StyledBackToLink,
} from './styled';
import { IHeaderProps } from './types';
import { generateHelloMessage } from './utils';

const Header: FC<IHeaderProps> = ({
  headerTitle,
  headerSubTitle,
  backLinkPath,
  userFullName,
  userAvatar,
  isCollapsed,
  toggleIsMobileMenuOpened,
}) => {
  const croppedTitleValue = `${headerTitle} ${headerSubTitle}`;

  return (
    <HeaderWrapper>
      <HeaderTitleWrapper>
        <BurgerButton onClick={toggleIsMobileMenuOpened}>
          <BurgerButtonShape />
        </BurgerButton>
        {backLinkPath && (
          <StyledBackToLink to={backLinkPath}>
            {BACK_LINK_TEXT}
          </StyledBackToLink>
        )}
        <CroppedTooltip title={croppedTitleValue} placement='bottomLeft'>
          <HeaderTitle isCollapsed={isCollapsed}>
            {headerTitle}
            {headerSubTitle && <span>&nbsp;{headerSubTitle}</span>}
          </HeaderTitle>
        </CroppedTooltip>
      </HeaderTitleWrapper>
      <InfoWrapper>
        <HelpLink />
        <Notifications />
        <UserInfoWrapper>
          <CroppedTooltip title={userFullName} placement='bottomLeft'>
            <UserName>{generateHelloMessage(userFullName)}</UserName>
          </CroppedTooltip>
          <Tooltip title={PROFILE_DETAILS_TEXT} placement='bottomLeft'>
            <Link to={APP_ROUTE.profile}>
              <UserAvatar userFullName={userFullName} userAvatar={userAvatar} />
            </Link>
          </Tooltip>
        </UserInfoWrapper>
      </InfoWrapper>
    </HeaderWrapper>
  );
};

export default Header;
