import { EMPTY_STRING } from 'constants/common';

import { IShareEmailFormValues, ShareEmailFormField } from './types';

export const SHARE_EMAIL_MODAL_CONTENT = {
  TITLE: 'Share record by Email',
  EMAIL_INPUT_LABEL: 'Please, enter an Email to share your ownership record',
  CANCEL_LABEL: 'Cancel',
  SHARE_LABEL: 'Share by email',
};

export const SHARE_EMAIL_FORM_TEXT = {
  EMAIL_LABEL: 'Email',
};

export const VALIDATION_ERROR_MESSAGES = {
  EMAIL_FIELD_REQUIRED: 'Please enter an email',
  EMAIL_INVALID: 'Please enter a valid email',
};

export const INITIAL_SHARE_EMAIL_FORM_VALUES: IShareEmailFormValues = {
  [ShareEmailFormField.EMAIL]: EMPTY_STRING,
};
