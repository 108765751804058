import styled from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { LogoLarge } from 'assets/vectors';
import { COLORS } from 'constants/colorPalette';

export const StyledHeader = styled(
  ({ children, isDesktopVersion: _isDesktopVersion, ...props }) => (
    <div {...props}>{children}</div>
  ),
)(({ isDesktopVersion }) => ({
  width: '100%',
  height: '50px',
  padding: '12px 24px',
  borderBottom: `1px solid ${COLORS.gray300}`,
  background: COLORS.white,
  ...(isDesktopVersion && {
    height: '80px',
    padding: '20px 24px',
  }),
}));

export const StyledInnerHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '1170px',
  margin: 'auto',
});

export const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledText = styled('span')({
  marginLeft: '10px',
  fontSize: '16px',
  color: COLORS.gray900,
});

export const StyledQuestionIcon = styled(QuestionCircleOutlined)({
  fontSize: '18px',
  color: COLORS.gray900,
});

export const StyledLogo = styled(
  ({ isDesktopVersion: _isDesktopVersion, ...props }) => (
    <LogoLarge {...props} />
  ),
)(({ isDesktopVersion }) => ({
  height: 20,
  ...(isDesktopVersion && {
    height: 35,
  }),
}));
