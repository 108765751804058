import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { SectionWrapper } from '../styled';

export const DealerWrapper = styled(SectionWrapper)({
  borderBottom: 'none',
});

export const SocialLink = styled('a')({
  color: COLORS.gray400,
  fontSize: '22px',
  '&:not(:last-child)': {
    marginRight: '16px',
  },
  '&:hover': {
    color: COLORS.gray700,
  },
});

export const StyledDealerLogo = styled('div')<{ logo?: string }>(
  ({ logo }) => ({
    width: '24px',
    height: '24px',
    border: `1px solid ${COLORS.gray200}`,
    borderRadius: '8px',
    background: `url(${logo}) no-repeat center ${COLORS.white}`,
    backgroundSize: 'contain',
    marginRight: '8px',
    minWidth: '24px',
  }),
);

export const FieldValueWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  width: '70%',
});
