import { TFormik } from 'types/formik';

export enum SerialNumberFormField {
  SERIAL_NUMBER = 'serialNumber',
}

export interface ISerialNumberFormValues {
  [SerialNumberFormField.SERIAL_NUMBER]: string;
}

export interface ISerialNumberFormContainerProps {
  serialNumber: string;
  handleSubmit: (serialNumber: string) => void;
  isNotVerified?: boolean;
  isVerifyLoading?: boolean;
}

export interface ISerialNumberFormProps {
  formik: TFormik<ISerialNumberFormValues>;
  isNotVerified: boolean;
  isVerifyLoading: boolean;
  handleChangeSerialNumber: (value: string) => void;
  handleLearnMoreModalOpen: () => void;
}
