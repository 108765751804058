export const SERIAL_NUMBER_HEADER = {
  serialNumber: 'Serial # ',
  shareButton: 'Share',
  saveButton: 'Save',
};

export const SAVE_OPTIONS = {
  saveDevice: 'Save to Device',
  saveGoogle: 'Save to Google Drive',
  saveDropbox: 'Save to DropBox',
};

export const GOOGLE = {
  SCOPES: 'https://www.googleapis.com/auth/drive.file',
  DISCOVERY_DOCS: [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  ],
  GAPI_SCRIPT: 'https://apis.google.com/js/api.js',
  GSI_SCRIPT: 'https://accounts.google.com/gsi/client',
  CLIENT: 'client',
};

export const DROPBOX_SCRIPT = {
  SRC: 'https://www.dropbox.com/static/api/2/dropins.js',
  ID: 'dropboxjs',
  TYPE: 'text/javascript',
};

export const UPLOAD_FILE_OPTIONS = {
  URL: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id',
  POST: 'POST',
  BEARER: 'Bearer ',
};

export enum AccessTokenPrompt {
  consent = 'consent',
  empty = '',
}

export const GOOGLE_POPUP_CLOSED_ERROR = 'popup_closed';
