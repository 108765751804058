import { FC } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';
import { IPasswordInputLayoutProps } from 'components/Inputs/types';
import { MAX_PASSWORD_LENGTH } from 'components/Inputs/constants';
import { StyledInput } from 'components/Inputs/styled';
import Tooltip from 'components/Tooltip';

import { PASSWORD_ICON_TOOLTIP } from './constants';

const PasswordInputLayout: FC<IPasswordInputLayoutProps> = ({
  value,
  errorMessage,
  ...props
}) => (
  <StyledInput.Password
    maxLength={MAX_PASSWORD_LENGTH}
    size='large'
    value={value}
    iconRender={(visible) => (
      <Tooltip
        title={
          visible ? PASSWORD_ICON_TOOLTIP.hide : PASSWORD_ICON_TOOLTIP.show
        }
      >
        {visible ? (
          <EyeTwoTone
            twoToneColor={errorMessage ? COLORS.red500 : COLORS.blue500}
          />
        ) : (
          <EyeInvisibleOutlined />
        )}
      </Tooltip>
    )}
    status={errorMessage && 'error'}
    {...props}
  />
);

export default PasswordInputLayout;
