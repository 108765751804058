import { FC } from 'react';

import { NotVerifiedIcon } from 'assets/vectors';
import ModalDialog from 'components/ModalDialog';

import { formatModalStepNumber } from './utils';
import {
  LearnMoreModalWrapper,
  LearnMoreModalTitleWrapper,
  LearnMoreModalTitle,
  LearnMoreModalBoldText,
  LearnMoreModalText,
  LearnMoreModalSubtitle,
  LearnMoreModalStepWrapper,
  LearnMoreModalStepNumber,
  LearnMoreModalStepTextWrapper,
  LearnMoreModalStepImg,
  LearnMoreModalFooterWrapper,
  LearnMoreModalButton,
  NotVerifiedModalTitleWrapper,
  NotVerifiedModalDescription,
} from './styled';
import { ILearnMoreModalProps } from './types';
import {
  NOT_VERIFIED_MODAL_CONTENT,
  SERIAL_NUMBER_MODAL_WIDTH,
} from './constants';

const NotVerifiedModal: FC<ILearnMoreModalProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <ModalDialog
    width={SERIAL_NUMBER_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <LearnMoreModalWrapper>
      <NotVerifiedModalTitleWrapper>
        <NotVerifiedIcon />
        <LearnMoreModalTitle>
          {NOT_VERIFIED_MODAL_CONTENT.title}
        </LearnMoreModalTitle>
      </NotVerifiedModalTitleWrapper>
      <LearnMoreModalTitleWrapper>
        <LearnMoreModalTitle>
          {NOT_VERIFIED_MODAL_CONTENT.subtitle}
        </LearnMoreModalTitle>
        <NotVerifiedModalDescription>
          {NOT_VERIFIED_MODAL_CONTENT.text}
        </NotVerifiedModalDescription>
      </LearnMoreModalTitleWrapper>
      {NOT_VERIFIED_MODAL_CONTENT.steps.map((step, index) => (
        <LearnMoreModalStepWrapper key={index}>
          <LearnMoreModalStepNumber>
            {formatModalStepNumber(index + 1)}
          </LearnMoreModalStepNumber>
          <LearnMoreModalStepTextWrapper>
            <LearnMoreModalBoldText>{step.title}</LearnMoreModalBoldText>
            <LearnMoreModalText>{step.text}</LearnMoreModalText>
          </LearnMoreModalStepTextWrapper>
          <LearnMoreModalStepImg />
        </LearnMoreModalStepWrapper>
      ))}
      <LearnMoreModalFooterWrapper>
        <LearnMoreModalSubtitle>
          {NOT_VERIFIED_MODAL_CONTENT.footerTitle}
        </LearnMoreModalSubtitle>
        <LearnMoreModalButton>
          {NOT_VERIFIED_MODAL_CONTENT.footerButtonText}
        </LearnMoreModalButton>
      </LearnMoreModalFooterWrapper>
    </LearnMoreModalWrapper>
  </ModalDialog>
);

export default NotVerifiedModal;
