import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const FooterWrapper = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '12px 0 17px',
  height: '49px',
  alignSelf: 'flex-end',
  '@media (max-width: 768px)': {
    display: 'none',
  },
});

export const FooterText = styled('span')({
  fontFamily: 'Manrope, sans-serif',
  fontWeight: 500,
  color: COLORS.gray500,
});
