import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import {
  AuthRedirectRoute,
  RedirectFromBaseRoute,
  UnregisteredRouteRedirect,
} from 'components/Routes';
import {
  Dashboard,
  SerialNumbers,
  Subscriptions,
  Profile,
  SignIn,
  SignInHelp,
  ForgotPassword,
  ResetPassword,
  SignUp,
  SerialNumbersDetails,
  VerifiedPayment,
  CurrencyDetails,
  Help,
} from 'pages';

const RoutesList: FC = () => {
  return (
    <Routes>
      <Route index element={<RedirectFromBaseRoute />} />
      <Route
        element={
          <AuthRedirectRoute
            authCheckType='unauthorized'
            redirectPath={APP_ROUTE.dashboard}
          />
        }
      >
        <Route path={APP_ROUTE.signIn} element={<SignIn />} />
        <Route path={APP_ROUTE.signInHelp} element={<SignInHelp />} />
        <Route path={APP_ROUTE.signUp} element={<SignUp />} />
        <Route path={APP_ROUTE.forgotPassword} element={<ForgotPassword />} />
        <Route path={APP_ROUTE.resetPassword} element={<ResetPassword />} />
      </Route>
      <Route
        element={
          <AuthRedirectRoute
            authCheckType='authorized'
            redirectPath={APP_ROUTE.signIn}
          />
        }
      >
        <Route path={APP_ROUTE.dashboard} element={<Dashboard />} />
        <Route path={APP_ROUTE.profile} element={<Profile />} />
        <Route path={APP_ROUTE.subscriptions} element={<Subscriptions />} />
        <Route path={APP_ROUTE.serialNumbers} element={<SerialNumbers />} />
        <Route
          path={APP_ROUTE.serialNumberDetails}
          element={<SerialNumbersDetails />}
        />
        <Route path={APP_ROUTE.veryfiedPayment} element={<VerifiedPayment />} />
        <Route
          path={APP_ROUTE.serialNumberCurrencyDetails}
          element={<CurrencyDetails />}
        />
      </Route>
      <Route
        path={APP_ROUTE.unRegistered}
        element={<UnregisteredRouteRedirect />}
      />
      <Route path={APP_ROUTE.help} element={<Help />} />
    </Routes>
  );
};

export default RoutesList;
