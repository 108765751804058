import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/routing';
import { TAxiosRequestError } from 'api/types';

import { IRequestErrorPayload, IUseAttachSerialNumberResponse } from './types';

const useAttachSerialNumber = (
  customerId: string,
  onSuccess?: () => void,
  onError?: (error: TAxiosRequestError) => void,
): UseMutationResult<
  IUseAttachSerialNumberResponse,
  TAxiosRequestError,
  string
> => {
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleError = (error: AxiosError<IRequestErrorPayload>) => {
    if (onError) {
      onError(error);
    }
  };

  return useMutation(
    async (serialNumber) => {
      const { data: createdCustomerResponse } = await apiClient.put(
        transformRoute(ApiRoutes.ATTACH_SERIAL_NUMBER, customerId),
        { serialNumber },
      );

      return createdCustomerResponse;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );
};

export default useAttachSerialNumber;
