import { FC } from 'react';

import { useGetCurrenciesForRequest } from 'api/requests';
import CurrencyTable from 'pages/CurrencyDetails/CurrencyTable/CurrencyTable';

import {
  DEFAULT_CURRENCIES_REQUEST_PAGE,
  DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
} from './constants';
import { ICurrencyTableContainer } from './types';

const CurrencyTableContainer: FC<ICurrencyTableContainer> = ({ request }) => {
  const { _id: requestId } = request;

  const {
    data: currenciesForRequestResponse,
    isLoading: isCurrenciesForRequestLoading,
    isFetching: isCurrenciesForRequestFetching,
  } = useGetCurrenciesForRequest(requestId, {
    page: DEFAULT_CURRENCIES_REQUEST_PAGE,
    limit: DEFAULT_CURRENCIES_REQUEST_PER_PAGE,
  });

  return (
    <CurrencyTable
      currencyItems={currenciesForRequestResponse?.currencies}
      isCurrenciesForRequestLoading={isCurrenciesForRequestLoading}
      isCurrenciesForRequestFetching={isCurrenciesForRequestFetching}
      totalCurrencies={currenciesForRequestResponse?.currencies?.length}
    />
  );
};

export default CurrencyTableContainer;
