import { DOWNLOAD_LINK_ATTRIBUTES } from '../constants';
import { transformToCanvas } from './canvas';
import { PDF_BLOB_TYPE, SN_DETAILS_CONTENT_CLASSNAME } from './constants';

export const generateSnDetailsCanvas = async (): Promise<HTMLCanvasElement> => {
  const detailsContent = document.querySelector(
    `.${SN_DETAILS_CONTENT_CLASSNAME}`,
  );

  return transformToCanvas(detailsContent);
};

export const downloadPdf = async (bytes: ArrayBuffer): Promise<void> => {
  const blob = new Blob([bytes], { type: PDF_BLOB_TYPE });
  const fileUrl = URL.createObjectURL(blob);

  const anchor = document.createElement('a');

  anchor.setAttribute(DOWNLOAD_LINK_ATTRIBUTES.href, fileUrl);
  anchor.setAttribute(
    DOWNLOAD_LINK_ATTRIBUTES.download,
    DOWNLOAD_LINK_ATTRIBUTES.name,
  );
  anchor.style.display = DOWNLOAD_LINK_ATTRIBUTES.displayNone;

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileUrl);
};
