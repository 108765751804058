import { useState, useEffect } from 'react';

import { IUseScriptOptions } from '../types';

const cachedScripts: string[] = [];

const useScript = (src: string, options: IUseScriptOptions) => {
  const { 'data-app-key': appKey, ...otherOptions } = options;

  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });

      return;
    } else {
      cachedScripts.push(src);

      const script = document.createElement('script');
      script.src = src;

      Object.assign(script, otherOptions);

      if (appKey) {
        script.setAttribute('data-app-key', appKey);
      }

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return state.loaded && !state.error;
};

export default useScript;
