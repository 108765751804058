import styled from 'styled-components';

import { LogoLarge } from 'assets/vectors';
import BackToLink from 'components/BackToLink';
import Button from 'components/Button';
import { Link } from 'components/Link';
import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';

export const StyledFormWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    maxWidth: '480px',
    alignItems: 'inherit',
  },
});

export const StyledForm = styled('form')({
  width: '100%',
});

export const PageWrapper = styled('div')({
  width: '100%',
  '@media (max-width: 768px)': {
    maxWidth: '480px',
  },
});

export const SendLinkButton = styled(Button)({
  width: '100%',
  margin: '40px 0 130px',
  '@media (max-width: 768px)': {
    margin: '40px 0',
  },
});

export const StyledText = styled('p')({
  marginBottom: 0,
  fontSize: '16px',
  color: COLORS.gray900,
  textAlign: 'center',
  '@media (max-width: 768px)': {
    textAlign: 'inherit',
  },
});

export const ResendDescription = styled('p')({
  marginBottom: '178px',
  fontSize: '18px',
  color: COLORS.gray600,
  textAlign: 'center',
  '@media (max-width: 768px)': {
    marginBottom: '40px',
    textAlign: 'inherit',
  },
});

export const StyledTextBig = styled(StyledText)({
  fontSize: '18px',
});

export const ResendTextWrapper = styled('div')({
  marginTop: '20px',
});

export const ResendButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '178px',
  width: '100%',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    marginBottom: '40px',
  },
});

export const ResendButton = styled(Button)({
  width: '108px',
  height: '38px',
  marginLeft: 'auto',
});

export const StyledAlert = styled(CustomAlert)({
  margin: '32px 0 40px',
  whiteSpace: 'pre-line',
});

export const StyledBackToLink = styled(BackToLink)({
  marginBottom: '24px',
  fontWeight: 600,
  fontSize: '16px',
});

export const StyledLink = styled(Link)({
  fontSize: '16px',
});

export const ResendWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    alignItems: 'baseline',
  },
});

export const StyledLogo = styled(LogoLarge)({
  height: '35px',
  marginBottom: '24px',
});
