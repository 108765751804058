import { FC } from 'react';

import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { PasswordInput } from 'components/Inputs';
import { FormFieldWrapper, StyledButton } from 'pages/SignUp/styled';

import { IUserFieldsProps, UserFormField } from '../types';
import { USER_FORM_TEXT } from '../constants';

const PasswordFields: FC<IUserFieldsProps> = ({
  formik: { errors, values, getFieldProps },
}) => {
  const isDisabled =
    !values[UserFormField.PASSWORD] || Boolean(errors[UserFormField.PASSWORD]);

  return (
    <>
      <FormFieldWrapper>
        <PasswordInput
          withPopover
          errorMessage={errors[UserFormField.PASSWORD]}
          labelText={USER_FORM_TEXT.passwordLabel}
          {...getFieldProps(UserFormField.PASSWORD)}
        />
      </FormFieldWrapper>
      <StyledButton
        htmlType='submit'
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        disabled={isDisabled}
      >
        {USER_FORM_TEXT.button}
      </StyledButton>
    </>
  );
};

export default PasswordFields;
