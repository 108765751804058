import styled from 'styled-components';
import { Alert } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

import { TAlertColorConfig } from './types';

const IconsStyles = {
  marginRight: '10px',
  fontSize: '14px',
};

export const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined,
)({
  ...IconsStyles,
});

export const StyledCheckOutlined = styled(CheckCircleOutlined)({
  ...IconsStyles,
});

export const StyledAlert = styled(Alert)<TAlertColorConfig>(
  ({ textColor }) => ({
    alignItems: 'center',
    width: '100%',
    maxWidth: '812px',
    padding: '16px',
    fontSize: '16px',
    borderRadius: '8px',
    '& .ant-alert-message': {
      color: textColor,
      '& span': {
        fontWeight: 800,
      },
    },
    '& .highlighted': {
      color: COLORS.blue500,
    },
    '@media (max-width: 1024px)': {
      maxWidth: '512px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '384px',
    },
  }),
);
