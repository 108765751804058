import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

export const LearnMoreModalWrapper = styled('div')({
  width: '100%',
  marginTop: '40px',
  '@media (max-height: 768px)': {
    maxHeight: '630px',
    overflowY: 'auto',
    paddingRight: '12px',
  },
});

export const LearnMoreModalTitleWrapper = styled('div')({
  textAlign: 'center',
  marginBottom: '44px',
});

export const LearnMoreModalTitle = styled('h2')({
  fontSize: '32px',
  lineHeight: '44px',
  fontWeight: 600,
  marginBottom: '16px',
});

export const LearnMoreModalBoldText = styled('p')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 800,
  marginBottom: '16px',
  color: COLORS.gray900,
});

export const LearnMoreModalText = styled('p')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  marginBottom: '20px',
  color: COLORS.gray600,
});

export const LearnMoreModalSubtitle = styled('h4')({
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  marginBottom: '32px',
  marginTop: '40px',
  color: COLORS.gray900,
});

export const LearnMoreModalStepWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  columnGap: 32,
  marginBottom: '40px',
  alignItems: 'center',
  '> div > p': {
    marginBottom: 0,
  },
});

export const LearnMoreModalStepNumber = styled('span')({
  display: 'block',
  borderRadius: '50%',
  backgroundColor: COLORS.blue50,
  color: COLORS.blue500,
  width: '40px',
  height: '40px',
  lineHeight: '40px',
  fontSize: '16px',
  fontWeight: 500,
  textAlign: 'center',
});

export const LearnMoreModalStepTextWrapper = styled('div')({
  width: '50%',
  marginRight: '32px',
  '@media (max-width: 768px)': {
    width: 'calc(100% - 74px)',
    marginRight: 0,
  },
});

export const LearnMoreModalStepImg = styled('div')({
  display: 'block',
  backgroundColor: COLORS.gray300,
  width: '220px',
  height: '100px',
  marginLeft: 'auto',
  '@media (max-width: 768px)': {
    margin: 0,
  },
});

export const LearnMoreModalFooterWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  columnGap: 70,
  marginTop: '54px',
  marginBottom: '40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: COLORS.gray50,
  padding: '26px 32px 26px 90px',
  overflow: 'hidden',
  '> h4': {
    margin: 0,
  },
  ':before': {
    content: "''",
    display: 'block',
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    position: 'absolute',
    top: '25px',
    left: '-60px',
    zIndex: 1,
    backgroundColor: COLORS.gray200,
  },
  ':after': {
    content: "''",
    display: 'block',
    width: '135px',
    height: '135px',
    borderRadius: '50%',
    position: 'absolute',
    top: '-35px',
    left: '-60px',
    backgroundColor: COLORS.gray100,
  },
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
    columnGap: 24,
    ':after, :before': {
      display: 'none',
    },
  },
});

export const LearnMoreModalButton = styled(Button)({
  width: '169px',
  margin: 0,
});

export const NotVerifiedModalTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: COLORS.gray50,
  padding: '20px',
  marginBottom: '32px',
  '> h2': {
    color: COLORS.red600,
    marginBottom: 0,
  },
});

export const NotVerifiedModalDescription = styled('p')({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
  marginBottom: '46px',
  color: COLORS.gray600,
});
