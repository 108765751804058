import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useGetRequestInvoice } from 'api/requests';
import { APP_ROUTE } from 'constants/appRoutes';
import { transformRoute } from 'utils/routing';

import { ISerialNumberSection } from '../types';
import RequestCurrencyDetails from './RequestCurrencyDetails';

const RequestCurrencyDetailsContainer: FC<ISerialNumberSection> = ({
  serialNumber,
}) => {
  const navigateTo = useNavigate();
  const {
    request: { _id: requestId },
    _id: serialNumberId,
  } = serialNumber;

  const navigateToCurrencyDetails = () => {
    navigateTo(
      transformRoute(APP_ROUTE.serialNumberCurrencyDetails, serialNumberId),
      { state: { requestId } },
    );
  };

  const { data: invoiceResponse } = useGetRequestInvoice(requestId);

  return (
    <RequestCurrencyDetails
      serialNumber={serialNumber}
      invoiceResponse={invoiceResponse}
      navigateToCurrencyDetails={navigateToCurrencyDetails}
    />
  );
};

export default RequestCurrencyDetailsContainer;
