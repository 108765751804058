import { FC } from 'react';

import { IUserFieldsProps, UserFormField } from '../types';
import PhoneNumberFields from './PhoneNumberFields';

const PhoneNumberFieldsContainer: FC<IUserFieldsProps> = ({ formik }) => {
  const handleChangePhoneNumberValue = (value: string) =>
    formik.setFieldValue(UserFormField.PHONE_NUMBER, value);

  const handleChangeCountryCodeValue = (value: unknown) =>
    formik.setFieldValue(UserFormField.COUNTRY_CODE, value);

  return (
    <PhoneNumberFields
      formik={formik}
      handleChangePhoneNumberValue={handleChangePhoneNumberValue}
      handleChangeCountryCodeValue={handleChangeCountryCodeValue}
    />
  );
};

export default PhoneNumberFieldsContainer;
