import { FC } from 'react';

import { StyledTitle } from './styled';
import { IInformPopoverTitleProps } from './types';

const InformPopoverTitle: FC<IInformPopoverTitleProps> = ({ title }) => (
  <StyledTitle>{title}</StyledTitle>
);

export default InformPopoverTitle;
