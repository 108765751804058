import { FC, useCallback } from 'react';
import { useFormik } from 'formik';

import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import useShareSerialNumber from 'api/serialNumbers/shareSerialNumber';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';

import ShareEmailModal from './ShareEmailModal';
import { shareEmalValidationSchema } from './validation';
import { INITIAL_SHARE_EMAIL_FORM_VALUES } from './constants';
import { IShareEmailFormValues, IShareEmailModalContainerProps } from './types';

const ShareEmailModalContainer: FC<IShareEmailModalContainerProps> = ({
  serialNumber,
}) => {
  const { isShareEmailModalOpen, uploadedPdf, handleShareEmailModalClose } =
    useSerialNumbersDetailsModalContext();

  const { mutate: shareSerialNumber, isLoading } = useShareSerialNumber();

  const handleOnSubmit = useCallback(
    ({ email }: IShareEmailFormValues) => {
      shareSerialNumber(
        {
          email,
          link: uploadedPdf?.url as string,
          password: uploadedPdf?.password,
          serialNumberId: serialNumber._id,
        },
        {
          onSuccess: () =>
            openNotificationWithIcon(
              NotificationType.success,
              NOTIFICATION_SUCCESS_MESSAGE.shareSerialNumberByEmail,
            ),
          onError: () =>
            openNotificationWithIcon(
              NotificationType.error,
              NOTIFICATION_ERROR_MESSAGE.shareSerialNumberByEmail,
            ),
          onSettled: () => {
            handleShareEmailModalClose();
            formik.resetForm();
          },
        },
      );
    },
    [
      shareSerialNumber,
      openNotificationWithIcon,
      handleShareEmailModalClose,
      uploadedPdf,
    ],
  );

  const formik = useFormik({
    initialValues: INITIAL_SHARE_EMAIL_FORM_VALUES,
    onSubmit: handleOnSubmit,
    validationSchema: shareEmalValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const onClose = useCallback(() => {
    formik.resetForm();
    handleShareEmailModalClose();
  }, [formik, handleShareEmailModalClose]);

  return (
    <ShareEmailModal
      formik={formik}
      isModalOpened={isShareEmailModalOpen}
      handleCloseModal={onClose}
      isLoading={isLoading}
    />
  );
};

export default ShareEmailModalContainer;
