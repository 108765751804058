import { FC } from 'react';

import { ButtonContentWrapper, StyledButton } from './styled';
import { TActionButtonProps } from './types';

const ActionButton: FC<TActionButtonProps> = ({ children, ...rest }) => (
  <StyledButton type='ghost' {...rest}>
    <ButtonContentWrapper>{children}</ButtonContentWrapper>
  </StyledButton>
);

export default ActionButton;
