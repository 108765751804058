import styled from 'styled-components';

import { LogoLarge } from 'assets/vectors';
import BackToLink from 'components/BackToLink';

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '130px',
  '@media (max-width: 768px)': {
    alignItems: 'inherit',
    marginBottom: 0,
  },
});

export const StyledBackToLink = styled(BackToLink)({
  marginBottom: '10px',
});

export const StyledLogo = styled(LogoLarge)({
  height: '35px',
  marginBottom: '24px',
});
