import { FC } from 'react';

import {
  VerificationBlockWrapper,
  AnimatedVerificationBlock,
  VerificationBlockTitle,
  VerificationBlockFeature,
  VerificationIconWrapper,
} from './styled';
import { FIRST_BLOCK_INDEX } from './constants';
import { IVerificationBlockProps } from './types';

const VerificationBlock: FC<IVerificationBlockProps> = ({
  blockRef,
  block: { index, title, feature, completedFeature, icon, completedIcon },
  serialNumber,
  isCompleted,
}) => {
  const isFirstBlock = index === FIRST_BLOCK_INDEX;

  const formattedCompletedFeature = isFirstBlock
    ? `${completedFeature} ${serialNumber}`
    : completedFeature;

  return (
    <VerificationBlockWrapper blockIndex={index} blockRef={blockRef}>
      <AnimatedVerificationBlock blockIndex={index}>
        <div>
          <VerificationBlockTitle>{title}</VerificationBlockTitle>
          <VerificationBlockFeature isCompleted={!isFirstBlock && isCompleted}>
            {isCompleted ? formattedCompletedFeature : feature}
          </VerificationBlockFeature>
        </div>
        <VerificationIconWrapper>
          {isCompleted ? completedIcon : icon}
        </VerificationIconWrapper>
      </AnimatedVerificationBlock>
    </VerificationBlockWrapper>
  );
};

export default VerificationBlock;
