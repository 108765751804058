import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';
import { LAYOUT_FOOTER_HEIGHT } from 'components/Layouts/AuthorizedLayout/constants';

export const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flex: '1 1 auto',
  height: `calc(100% - ${LAYOUT_FOOTER_HEIGHT})`,
  '@media (max-width: 768px)': {
    height: '100%',
  },
});

export const StyledImage = styled('img')({
  display: 'block',
  maxWidth: '176px',
  maxHeight: '176px',
  margin: '0 auto 54px',
});

export const StyledText = styled('p')({
  marginTop: '8px',
  marginBottom: '40px',
  color: COLORS.gray600,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
});

export const StyledButton = styled(Button)({
  minWidth: '335px',
});
