export const STATUS_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  ERR_NETWORK_CODE: 'ERR_NETWORK',
};

export const ERROR_TYPES = {
  USER_ARCHIVED: 'UserArchivedException',
};
