import { CurrencyType } from 'types/currencies';
import { ICustomerInfoWithSerialNumbers } from 'types/customer';
import { IDealer } from 'types/dealers';
import { IRequestWithDetailedDealer } from 'types/request';
import { TSaveAndShareByCurrencies } from 'types/saveAndShare';
import {
  ISubscription,
  IPaymentRecord,
  TSubscriptionStatus,
} from 'types/subscriptions';

export interface ISerialNumber {
  _id: string;
  serialNumber: string;
  status: SerialNumberStatus;
  subscriptionStatus: TSubscriptionStatus;
  dealer: IDealer;
  user: ICustomerInfoWithSerialNumbers;
  subscriptions: ISubscription[];
  request: IRequestWithDetailedDealer;
  currency: CurrencyType;
  amount: number;
  createdAt: Date;
  populatedAt: Date | string;
  customerIdentity: boolean;
  saveAndShareAccess: TSaveAndShareByCurrencies;
  denom?: string;
  deletedAt?: string;
  isLocked?: boolean; //* the flag means a customer access to this SN is restricted/allowed
}

export interface ISerialNumberWithLatestPayments extends ISerialNumber {
  payments: IPaymentRecord[];
}

export enum SerialNumberStatus {
  allocated = 'allocated',
  unallocated = 'unallocated',
  deleted = 'deleted',
}

export interface ISerialNumberWithActions extends ISerialNumber {
  handleView: () => void;
  handleUnlock: () => void;
}

export interface ISerialNumberStatus {
  isLocked: boolean;
  isRestricted: boolean;
}
