import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const StyledList = styled('ul')({
  margin: 0,
  paddingLeft: '20px',
  fontWeight: 500,
  color: COLORS.gray900,
});
