import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const InvoiceName = styled('span')({
  display: 'inline-block',
  maxWidth: '200px',
  verticalAlign: 'bottom',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  color: COLORS.blue500,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const CurrencyWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  '@media (max-width: 1024px)': {
    marginBottom: '32px',
  },
});
