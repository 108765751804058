import { FC } from 'react';

import { useReadNotificationById } from 'api/notifications';

import ReadNotificationButton from './ReadNotificationButton';
import { IReadNotificationButtonContainerProps } from './types';

const ReadNotificationButtonContainer: FC<
  IReadNotificationButtonContainerProps
> = ({ notification, handleReadOne }) => {
  const handleReadNotification = () => {
    readNotificationMutate(notification._id);
  };

  const { mutate: readNotificationMutate, isLoading: isNotificationReading } =
    useReadNotificationById(handleReadOne);

  return (
    <ReadNotificationButton
      notification={notification}
      isNotificationReading={isNotificationReading}
      handleRead={handleReadNotification}
    />
  );
};

export default ReadNotificationButtonContainer;
