import { FC } from 'react';

import { APP_ROUTE } from 'constants/appRoutes';
import { HelpIcon } from 'assets/vectors/help';

import { HELP_LINK_TEXT } from './constants';
import { StyledLink, Text } from './styled';
import { THelpLinkProps } from './types';

const HelpLink: FC<THelpLinkProps> = ({ children, ...props }) => (
  <StyledLink to={APP_ROUTE.help} {...props}>
    <HelpIcon />
    <Text>{children || HELP_LINK_TEXT}</Text>
  </StyledLink>
);

export default HelpLink;
