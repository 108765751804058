import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiRoutes } from 'api/routes';
import { apiClient } from 'api/base';
import { QUERY_KEYS } from 'constants/queryKeys';
import { MS_IN_ONE_MINUTE } from 'constants/common';

import {
  IErrorPayload,
  IGetNotificationsParams,
  INotificationsResponse,
} from './types';

const useGetNotifications = (
  params: IGetNotificationsParams,
): UseQueryResult<INotificationsResponse, AxiosError<IErrorPayload>> => {
  const { page, filters, limit, currency } = params;
  const { isRead } = filters;

  return useQuery(
    [QUERY_KEYS.NOTIFICATIONS, { isRead, page }],
    async () => {
      const { data: notificationsResponse } = await apiClient.get(
        ApiRoutes.GET_NOTIFICATIONS,
        {
          params: {
            page,
            isRead,
            limit,
            currency,
          },
        },
      );

      return notificationsResponse;
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: MS_IN_ONE_MINUTE,
    },
  );
};

export default useGetNotifications;
