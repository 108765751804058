import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { NULL_TYPE_VALUE } from 'constants/common';
import { USER_EMAIL_LS_KEY } from 'constants/localStorage';
import { QUERY_KEYS } from 'constants/queryKeys';

import { TUseGetUserProfileResponse } from './types';

const useGetUserProfile = (): UseQueryResult<
  TUseGetUserProfileResponse,
  AxiosError
> => {
  const handleGetUserProfileError = () => {
    queryClient.setQueryData(QUERY_KEYS.USER_PROFILE, NULL_TYPE_VALUE);
  };

  const query = useQuery<TUseGetUserProfileResponse, AxiosError>({
    queryKey: [QUERY_KEYS.USER_PROFILE],
    queryFn: async () => {
      const { data: userResponse } = await apiClient.get(
        ApiRoutes.USER_PROFILE,
      );

      return userResponse;
    },
    keepPreviousData: false,
    retry: false,
    refetchOnWindowFocus: false,
    onError: handleGetUserProfileError,
  });

  useQuery({
    queryKey: [QUERY_KEYS.GET_USER_FROM_LOCAL_STORAGE],
    queryFn: async () => {
      const userEmail = localStorage.getItem(USER_EMAIL_LS_KEY);
      const signedInEmail = userEmail && userEmail.toLowerCase();
      const currentEmail = query.data?.email && query.data?.email.toLowerCase();

      if (currentEmail && signedInEmail && currentEmail !== signedInEmail) {
        query.refetch();
      }
    },
    enabled: Boolean(query.data),
    refetchOnWindowFocus: true,
  });

  return query;
};

export default useGetUserProfile;
