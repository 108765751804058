import { object, string } from 'yup';

import { SERIAL_NUMBER_LENGTH } from 'constants/validations';

import { VALIDATION_ERROR_MESSAGES } from './constants';
import { SerialNumberFormField } from './types';

export const SERIAL_NUMBER_VALIDATION_SCHEME = object({
  [SerialNumberFormField.SERIAL_NUMBER]: string()
    .required(VALIDATION_ERROR_MESSAGES.serialNumberRequired)
    .length(
      SERIAL_NUMBER_LENGTH,
      VALIDATION_ERROR_MESSAGES.serialNumberInvalid,
    ),
});
