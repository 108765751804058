import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import isString from 'lodash/isString';

import {
  NOTIFICATION_CLASS_NAME,
  NOTIFICATION_VISIBILITY_DURATION_IN_SECONDS,
  PLACEMENT_TOP_PADDING,
} from 'constants/notifications';
import { Placement } from 'types/common';
import { TNotificationType } from 'types/notifications';

import { addDotToEndOfString } from './format/addDotToEndOfString';

export const openNotificationWithIcon = (
  type: TNotificationType,
  message: string,
) => {
  notification[type]({
    message: isString(message) ? addDotToEndOfString(message) : message,
    icon: type === 'error' && <ExclamationCircleOutlined />,
    className: `${NOTIFICATION_CLASS_NAME} ${type}`,
    top: PLACEMENT_TOP_PADDING,
    placement: Placement.top,
    duration: NOTIFICATION_VISIBILITY_DURATION_IN_SECONDS,
    closeIcon: <></>,
  });
};
