import { COLORS } from 'constants/colorPalette';

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum Placement {
  top = 'top',
  right = 'right',
}

export enum Enviroments {
  development = 'development',
  test = 'test',
  staging = 'staging',
}

export type TTypeFields<Entity, T = keyof Entity> = Record<keyof Entity, T>;
export type TTypeFieldsPartial<Entity, T = keyof Entity> = Partial<
  Record<keyof Entity, T>
>;

export type TFilterChangeHandler = (
  field: string,
  labelPrefix?: string,
) => (value: unknown) => void;

export type TColorProp = keyof typeof COLORS;
