import { FC } from 'react';

import { APP_ROUTE } from 'constants/appRoutes';
import { EMPTY_STRING } from 'constants/common';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { UNAUTHORIZED_LAYOUT_TEXT } from 'components/Layouts/UnauthorizedLayout/constants';
import UnauthorizedPageWrapper from 'components/Layouts/UnauthorizedLayout/UnauthorizedPageWrapper';

import { SIGN_IN_HELP_TEXT } from './constants';
import {
  StyledBackToLink,
  StyledLogo,
  HelpLink,
  StyledText,
  StyledLink,
} from './styled';

const SignInHelp: FC = () => (
  <HelmetPageTitle title={SIGN_IN_HELP_TEXT.TITLE}>
    <UnauthorizedPageWrapper>
      <StyledBackToLink to={APP_ROUTE.signIn}>
        {SIGN_IN_HELP_TEXT.BACK_LINK}
      </StyledBackToLink>
      <StyledLogo />
      <HelpLink to={APP_ROUTE.forgotPassword}>
        {SIGN_IN_HELP_TEXT.FORGOT_PASSWORD_LINK}
      </HelpLink>
      <HelpLink to={EMPTY_STRING}>
        {SIGN_IN_HELP_TEXT.FORGOT_EMAIL_LINK}
      </HelpLink>
      <HelpLink to={EMPTY_STRING}>{SIGN_IN_HELP_TEXT.SMTH_ELSE_LINK}</HelpLink>
      <StyledText>
        {UNAUTHORIZED_LAYOUT_TEXT.HAVE_ACCOUNT}&nbsp;
        <StyledLink to={APP_ROUTE.signIn}>
          {UNAUTHORIZED_LAYOUT_TEXT.SIGN_IN}
        </StyledLink>
      </StyledText>
    </UnauthorizedPageWrapper>
  </HelmetPageTitle>
);

export default SignInHelp;
