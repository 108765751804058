import { Options as IHtmlToCanvasOptions } from 'html2canvas';

import { COLORS } from 'constants/colorPalette';

export const TRANSFORM_TO_CANVAS_SCALE = 2;
export const CANVAS_TRANSFORM_CONFIG: Partial<IHtmlToCanvasOptions> = {
  width: 1500,
  windowWidth: 1920,
  windowHeight: 1870,
  scale: TRANSFORM_TO_CANVAS_SCALE,
  backgroundColor: COLORS.white,
  logging: false,
};
