export const SIGN_IN_FORM_TEXT = {
  EMAIL_LABEL: 'Email',
  PASSWORD_LABEL: 'Password',
  REMEMBER_ME: 'Remember me',
  HELP_LINK: 'I need help',
  SIGN_IN_BUTTON_TEXT: 'Sign In',
};

export const VALIDATION_ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'Please enter your email',
  EMAIL_INVALID: 'Please enter a valid email',
  PASSWORD_REQUIRED: 'Please enter your password',
  PASSWORD_SHORT: 'At least 10 characters',
};
