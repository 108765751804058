import styled from 'styled-components';

import { NoNotificationIcon } from 'assets/vectors';
import { COLORS } from 'constants/colorPalette';

export const NoNotification = styled(NoNotificationIcon)({
  width: '136px',
});

export const NoNotificationsDescription = styled('h3')({
  color: COLORS.gray500,
  fontSize: '16px',
  fontWeight: 600,
});

export const NoDataWrapper = styled('div')({
  padding: '24px 80px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
});
