import { calculateEnumLength } from 'utils/numbers';

import { UserStepperKeys } from './UserForm/types';
import { VerificationStepperKeys } from './types';
import { INTERSECTING_STEPS_COUNT } from './constants';

const PERCENTAGE_DIVISOR = 100;

export const getVerificationNextStep = (currentStep: VerificationStepperKeys) =>
  VerificationStepperKeys[currentStep + 1] ? currentStep + 1 : currentStep;

export const getProgressPercentage = (currentStep: number): number =>
  (currentStep /
    (calculateEnumLength(VerificationStepperKeys, UserStepperKeys) -
      INTERSECTING_STEPS_COUNT)) *
  PERCENTAGE_DIVISOR;
