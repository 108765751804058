import { ReactNode } from 'react';

export const getSendVerificationCodeSuccessMessage = (
  email: string,
): ReactNode => (
  <>
    An email with a verification code has been sent to
    <span className='highlighted'> {email}</span>.<br />
    Please check your mailbox.
  </>
);
