import { ReactNode } from 'react';

export const MESSAGES = {
  linkSented:
    'We`ve sent a link to your email address: youremail@gmail.com \nPlease check your mailbox',
  linkExpired:
    'Looks like your reset link has expired. Please request the link again',
  currencyDetailsDeletedOrNotExist:
    "Currency details can't be accessed as it was deleted or doesn't exist.",
  serialNumberDeletedOrNotExist:
    "Serial number can't be accessed as it was deleted or doesn't exist.",
};

export const PASSWORD_VALIDATION_MESSAGES = {
  createPassword: 'Please create your password',
  confirmPassword: 'Please confirm your password',
  passwordNotMatch: 'Passwords do not match',
  numbersAndLettersAllowedError: 'Only Latin letters and numbers are allowed',
  passwordLengthError: 'Must contain at least 10 characters',
  oneLatinLetterContainError: 'Must contain at least one Latin letter',
  oneNumberContainError: 'Must contain at least one number',
  oneUppercaseLetterContainError: 'Must contain at least one uppercase letter',
  oneLowerCaseLetterContainError: 'Must contain at least one lowercase letter',
};

export const EMAIL_VALIDATION_MESSAGES = {
  requiredEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
};

export const NOTIFICATION_SUCCESS_MESSAGE = {
  passwordUpdated: 'Your password was successfully updated.',
  changesSaved: 'Your changes were successfully saved.',
  deletedFile: 'The file was successfully deleted.',
  deletedDealer: 'The dealer was successfully deleted.',
  createdDealer: 'The dealer was successfully created.',
  deletedRequest: 'You have successfully deleted Request.',
  updateRequest:
    'Serial number and Invoice data successfully allocated to the currency.',
  deletedCertificate: 'You have successfully deleted certificates PDF.',
  requestsMerged: 'The selected Requests were successfully merged.',
  updateProfileDetails: 'Your Profile Details were successfully updated.',
  updateProfileDetailsPassword: 'Your Password was successfully updated.',
  shareSerialNumberByEmail: 'Ownership record Email successfully shared.',
  shareSerialNumberByQr: 'Ownership record QR code successfully shared.',
  shareSerialNumberBySms: 'Ownership record text message successfully shared.',
  saveSerialNumberToGoogle:
    'Ownership record successfully saved to your Google Drive.',
  saveSerialNumberToDropbox:
    'Ownership record successfully saved to your DropBox.',
  deleteProfilePhoto: 'You have successfully deleted your Profile photo.',
};

export const NOTIFICATION_ERROR_MESSAGE = {
  notDeletedFile: 'The file could not be deleted. Please try again.',
  notDeletedDealer: 'The dealer could not be deleted. Please try again.',
  notCreatedDealer: 'We could not save your changes. Please try again.',
  notSyncedRequests: "Couldn't sync new requests. Please, try again.",
  notMergedRequests:
    'Could not merge the selected requests: there are notes of different currencies or denominations, from different money machines or with the same note serial number.',
  unhandledMergeError:
    "Sorry, we couldn't merge the selected requests. Please, try again.",
  notDeletedRequest: 'The Request was not deleted. Please try again.',
  failedUpdateRequest: 'The changes cannot be saved. Please try again.',
  failedGeneration: 'Failed to generate. Please try again',
  notDeletedCertificate:
    'The certificates PDF was not deleted. Please try again.',
  serialNumberIsRestricted:
    'Access to this serial number was restricted due to security reasons. You can not unlock it. Please, contact our support team for further assistance.',
  snCurrencyIsAlreadyLock:
    'Currency details could not be opened as this serial number was locked. Please, refresh the page to see the up-to-date data. You can also contact customer support for further assistance.',
  shareSerialNumberByEmail:
    'Serial number details could not be shared as this serial number was locked or archived, or because Save & Share feature for your serial number(s) was just disabled. Please, proceed to your serial numbers list or serial number details page and refresh the page to see the up-to-date data. You can also contact customer support for further assistance.',
  shareSerialNumberByQr:
    'Ownership record QR code could not be shared. Please, try again.',
  shareSerialNumberBySms:
    'Ownership record text message could not be shared. Please, try again.',
  saveSerialNumberToDevice:
    'Ownership record could not be saved to your device. Please, try again.',
  saveSerialNumberToGoogle:
    'Ownership record could not be saved to your Google Drive. Please, try again.',
  saveSerialNumberToDropbox:
    'Ownership record could not be saved to your DropBox. Please, try again.',
  noInternetConnection:
    'Unable to connect. Please check your internet connection or try again later. Please note that the data might be reflected incorrectly until the connection is restored.',
};

export const ALERT_SUCCESS_MESSAGE = {
  successResetLinkMessage: (email: string) => (
    <>
      We&apos;ve sent a link to your email address: <span>{email}.</span>
      <br />
      Please check your mailbox.
    </>
  ),
};

export const SERVER_ERROR_STRING = 'Something went wrong. Please try again';

export const SEND_VERIFICATION_CODE_ERROR_STRING =
  'Failed to send verification code. Please try again.';

export enum ResponseType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type TResponseMessage = string | null | ReactNode;

export interface IResponseMessage {
  type: ResponseType;
  message: TResponseMessage;
}
